import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import line from "../../assests/Group 1870 line2.png";
import { Link } from "react-router-dom";
import share from "../../assests/Vector.png";
import img_1 from "../../assests/eventsimage_1.png";
import img_2 from "../../assests/eventsimage_2.png";
import img_3 from "../../assests/eventspeople.png";
import { useEffect } from "react";

function Events() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      {/* <section className="Events mt-5"></section> */}
      <section style={{ minHeight: "90vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            {/* <div className="col-md-12 text-center">
              <h4 class=" text-dark fw-bolder  mb-0 mt-3">CURRENT EVENT</h4>
              <img className=" img-fluid" src={line} />
            </div> */}
          </div>
          <div className="row d-flex justify-content-center align-items-center ">
            {/* <div className='col-md-12 mt-2 text-center'>
              <p className='india-text text-center mt-3'>The laughter of children is a beautiful part of the Christmas spirit.  Bring your child to <span className="text-danger">Smile with Santa</span>  with Chattanooga’s very own Santa Miles.  Join with the Love Without Reason family on Saturday, December 9, 2023, from 12 -2pm at the Eastgate Town Center, Entrance B.  Children may take their picture with Santa, decorate their own cookie, write a letter to Santa with the entry fee of $15.  Proceeds from this event bring a new smile to a child waiting for their cleft lip surgery! </p>
              <Link to='https://allevents.in/chattanooga/smile-with-santa/80002148929659' target='_blank' className="btn btn-danger btn-lg">Register</Link>
              <p className='india-text text-center mt-5 rounded bg-dark p-5 text-white '>While you are at the event, be sure to get your meaningful Christmas gifts from The Butterfly Shop.  Check out the artisan crafted jewelry from Kenya, the comfortable dresses and scarves from Zimbabwe and the totes and makeup bags that were stitched at Love Without Reason’s Project Butterfly Center in India.  Young women who come to the Project Butterfly Center are either at-risk to human trafficking or are survivors of human trafficking.  Your purchase supports their efforts in sustainable business and education and breaks the cycle of slavery!  <br />
                <Link to='https://allevents.in/chattanooga/smile-with-santa/80002148929659' target='_blank' className='text-danger text-decoration-none'>Click here...</Link> to register for the event today! </p>
            </div> */}
          </div>

          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 text-center mt-5">
              <h4 class=" text-dark fw-bolder  mb-0 mt-5">EVENTS</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <p className="text-center mt-3">
                For the expansion of Love Without Reason, fundraising is
                critical. Several events may be held simultaneously in different
                countries. For example, the 5K Run4Love is always held the
                second Saturday in September. Teams in the USA, South Africa,
                Kenya, Zimbabwe, and India have participated in the event in
                their time zones.
              </p>
              <p className="text-center">
                Do you want to start a fundraiser to benefit Love Without
                Reason? Email us at <span className="text-danger fw-bolder">
                  info@lovewithoutreason.org
                </span>{" "}
                to learn more.
              </p>
              <p className="text-center">
                Do you want to volunteer for an upcoming fundraising event?
                Learn more and email us at{" "}
                <span className="text-danger fw-bolder">
                  info@lovewithoutreason.org
                </span>{" "}
              </p>
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center mt-3 mb-5">
            <div className="col-md-3 mt-3">
              <div className="card border-0">
                <Link to='/5kRun4Love'> <img src={img_1} className='card-img-top' alt="Image 1" /></Link>
                <div className="card-body bg-light text-center rounded border-0">
                  <div className="row">
                    <div className="col-9">
                      <Link to='/5kRun4Love' className="text-decoration-none text-dark"><h6 className="fw-bold p-0 card-text">
                        5kRun4Love
                      </h6>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link to='/5kRun4Love'><img className="img-fluid" src={share} /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="card border-0">
                <Link to='/SmileWithSanta'> <img src={img_1} className='card-img-top' alt="Image 1" /></Link>
                <div className="card-body bg-light text-center rounded border-0">
                  <div className="row">
                    <div className="col-9">
                      <Link to='/SmileWithSanta' className="text-decoration-none text-dark"><h6 className="fw-bold p-0 card-text">
                        Smile With Santa
                      </h6>
                      </Link>
                    </div>
                    <div className="col-3">
                      <Link to='/SmileWithSanta'><img className="img-fluid" src={share} /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-md-3 mt-3">
              <div className="card border-0">
                <Link to='/FatherDaughterDance'> <img src={img_2} className='img-fluid card-img-top mb-0' alt="Image 1" /></Link>
                <div className="card-body bg-light text-center rounded border-0">
                  <div className="row">
                    <div className="col-9">
                      <Link to='/FatherDaughterDance' className="text-decoration-none text-dark"><h6 className="fw-bold p-0 card-text">
                        Father Daughter Dance
                      </h6></Link>
                    </div>
                    <div className="col-3">
                      <Link to='/FatherDaughterDance'><img className="img-fluid" src={share} /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card border-0">
                <Link to='/PeopleNotProducts'><img src={img_3} className='img-fluid card-img-top mb-0' alt="Image 1" /></Link>
                <div className="card-body bg-light text-center rounded border-0">
                  <div className="row">
                    <div className="col-9">
                      <Link to='/PeopleNotProducts' className="text-decoration-none text-dark">
                        <h6 className="fw-bold p-0 card-text">
                          People Not Products
                        </h6>
                      </Link>

                    </div>
                    <div className="col-3">
                      <Link to='/PeopleNotProducts'><img className="img-fluid" src={share} /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <Footer />
    </>
  );
}

export { Events };
