import React from "react";
import { useState } from 'react';
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import axios from 'axios';
import { useEffect } from "react";

function Student() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)

    // }).catch((err) => {
    //   alert('Failed to Submit')
    //   console.log(err);
    // })
  }
  return (
    <>
      <section className="Student mt-5 " style={{ minHeight: "100vh" }}></section>
      <section style={{ minHeight: "100vh" }}>
        <div className="container  px-5 mt-5">
          <div className="row mt-3">
            <div className="col-md-2">
              <h4 className='border-bottom border-danger border-3 fw-bold'>VOLUNTEER</h4>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-12 text-justify">
              <p style={{ fontSize: "18px" }}>Are you interested in  <span className="text-danger font-weight-bold "> NONPROFIT MANAGEMENT?</span> ?</p>
              <h6>Marketing Intern experience requirements:</h6>
              <p style={{ fontSize: "18px" }}>Intern candidates should be enrolled in and on track to graduate from a four-year college or university with a degree. Assisting at Love Without Reason through an internship is designed to help candidates obtain a year of experience in a related role, often required by larger companies.</p>
              <p style={{ fontSize: "18px" }}>Because this is an entry-level position, intern candidates gain most of their experience while earning their degree or completing other internships.</p>
              <p style={{ fontSize: "18px" }}>Selected candidates will receive an official letter verifying the internship. Printed on company letterhead as required by the college for Proof of Internship. Letters of reference will validate and document your hard work as an intern with Love Without Reason.</p>
              <h6 style={{ fontSize: "18px" }} className="text-danger fw-bold mt-5 mb-3">Essential skills for this position include the following:</h6>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> &nbsp;Strong verbal and written communication skills  </p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> &nbsp;Ability to translate complex research into common language  </p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> &nbsp;Proficiency with word processing, spreadsheet, and presentation software  </p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> &nbsp;Aptitude with various social media platforms as well as the data tracking tools associated with them  </p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> &nbsp;Ability to quickly learn and process information  </p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> &nbsp;Organization and time management skills  </p>
              {/* <h6 className="mb-5 fw-bold">-Unpaid and non-benefited internship(Part-Time)</h6> */}
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "80vh" }}>
        <div className="container  mt-5">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mb-2 text-center">
              <h5 class=" text-dark fw-bold ">STUDENT INTERN APPLICATION</h5>
              <img className=" img-fluid " src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <form onSubmit={sendEmail} className="text-center">
                    <div className="row mb-2 ">
                      <div className="col-md-6">
                        <input class="form-control" placeholder="FirstName" name="firstname" type="text" onChange={(e) => { userDetails(e) }} />
                      </div>
                      <div className="col-md-6">
                        <input class="form-control" placeholder="Lastname" type="text" name="lastname" onChange={(e) => { userDetails(e) }} />
                      </div>
                    </div>
                    <div className="row  mb-3">
                      <div className="col-md-6" >
                        <input class="form-control" placeholder="Phone number" type="number" name="number" onChange={(e) => { userDetails(e) }} />
                      </div>
                      <div className="col-md-6" >
                        <input class="form-control" placeholder="Email" type="email" name="email" onChange={(e) => { userDetails(e) }} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Message" onChange={(e) => { userDetails(e) }}
                          type="text" name="message"></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-12 text-center">
                        <div className="d-flex">
                          {/* <p className="text-dark m-1 text-start" >
                            Thank you for submitting your application,one of our Love
                            Without Reason staff will contact you in the next few
                            days.
                          </p> */}
                        </div>
                        <button className="btn btn-danger mt-3 fw-bold align-items-center px-3 mb-3" data-bs-toggle="modal" data-bs-target="#exampleModalstudent" type="submit">Submit</button>
                        <div class="modal fade" id="exampleModalstudent" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                              <div class="modal-body">
                                <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                <hr />

                                <div className="row d-flex justify-content-center">
                                  <div className="col-md-12 fs-5">
                                    Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer border-0">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Header />
      <Footer />
    </>
  );
}

export { Student };
