import React from "react";
import line from "../../assests/Group 1870 line2.png";

import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from "react";

function Video() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section style={{ minHeight: "80vh" }}>
        <div className="container mt-5">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-12 text-center">
              <h4 class="text-center text-dark fw-bold mt-5 mb-0">
                LOVE WITHOUT REASON VIDEOS
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>

          <div className="row d-flex justify-content-center ">
            <div className="col-md-6 text-center mt-5">
              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "50vh", minWidth: "40vw" }}
                src="https://www.youtube.com/embed/videoseries?si=7VEGsNTPlJ6RXguZ&amp;list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center fw-bold mt-4">LOVE WITHOUT REASON</h5>
            </div>
            <div className="col-md-6 text-center mt-5">
              {/* <iframe
                className="rounded shadow-sm "
                style={{minHeight:'50vh',minWidth:'40vw'}}
                // src="https://www.youtube.com/embed/2jWIxbOMwrk"
                src="https://www.youtube.com/embed/7VEGsNTPlJ6RXguZ"
                title="Love Without Reason - The Journey"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}

              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "50vh", minWidth: "40vw" }}
                src="https://www.youtube.com/embed/HXZasGmY2jg?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="Susan Sharing the story of Love Without Reason and Philip Mathews"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center fw-bold mt-4">
                Susan Sharing the story of Love Without Reason and Philip
                Mathews
              </h5>
              {/* <p className="text-center mt-3" style={{fontSize:"20px"}}>
                The journey started with Philip and has continued over the years
                through Love Without Reason. This video is a tribute to Philip
                and what he has achieved over the years and how Love Without
                Reason is continuing what he started.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh", backgroundColor: " #1A1E27" }}>
        <div className="container ">

          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-12 text-center">
              <h4 class="text-center text-white fw-bold mt-5 mb-0">
                STRAIGHT FROM THE HEART
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
            <div className="col-md-4 text-center mt-5">
              {/* <iframe
                className="rounded shadow-sm"
               style={{minHeight:'50vh',minWidth:'40vw'}}
                src="https://www.youtube.com/embed/2D-G46CPvNo"
                title="Philip Mathews speaking at PCNAK Miami 2019"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}

              <iframe
                className="rounded shadow-sm"
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/pUXXnkTNdK8?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="Straight From The Heart - Jason"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-white fw-bold mt-4">
                {" "}
                Straight From The Heart - Jason
              </h5>
              {/* <p className="text-center mt-3" style={{fontSize:"20px"}}>
                Philip has traveled around the world motivating the young people
                to rise above themselves and to Never Give Up. Here is a speech
                he did in 2019, just before he passed away.
              </p> */}
            </div>
            <div className="col-md-4 text-center  mt-5">
              {/* <iframe
                className="rounded shadow-sm"
                style={{minHeight:'50vh',minWidth:'40vw'}}

                src="https://www.youtube.com/embed/EtLC_yKHVu0"
                title="Philips Dream"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
              <iframe
                className="rounded shadow-sm"
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/CelgpLKrCjQ"
                title="Sharon Raman"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-white mt-3 fw-bolder">
                {" "}
                Sharon Raman
              </h5>
            </div>
            <div className="col-md-4 text-center mt-5">
              <iframe
                className="rounded shadow-sm"
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/_ooRMUiZWyE"
                title="Dr. Benny Prasad on Love Without Reason"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-white fw-bold mt-3">
                {" "}
                Dr. Benny Prasad on Love Without Reason
              </h5>
              {/* https://youtu.be/pUXXnkTNdK8?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2 */}
              {/* <p className="text-center mt-3" style={{fontSize:"20px"}}>
                The journey started with Philip and has continued over the years
                through Love Without Reason. This video is a tribute to Philip
                and what he has achieved over the years and how Love Without
                Reason is continuing what he started.
              </p> */}
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-5 ">
            <div className="col-md-4 text-center">
              <iframe
                className="rounded shadow-sm"
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/m2SQGqqMZWo"
                title="Pramod Mathews about Love Without Reason"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-white fw-bold mt-3">
                {" "}
                Pramod Mathews about Love Without Reason
              </h5>
            </div>
            <div className="col-md-4 text-center">
              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/MKwlQuZjT_Q?si=F0eTqCUFUYHKRKDB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-white fw-bold mt-3">
                {" "}
                Love Without Reason in Zimbabwe
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container mb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 text-center">
              <h4 class="text-center text-dark fw-bold mt-5 mb-0">
                LWR DOCUMENTARIES
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
            <div className="col-md-4 text-center mt-5 mb-4">
              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/xlXyVikzf9o?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="Love Without Reason Documentary - 2014"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                Love Without Reason Documentary - 2014
              </h5>
            </div>
            <div className="col-md-4  text-center mt-5 mb-4">
              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/2jWIxbOMwrk?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="500th Story - From Hopeless to Healed - Documentary 2018"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                500th Story - From Hopeless to Healed - Documentary 2018
              </h5>
            </div>
            <div className="col-md-4  text-center text-center mt-5 mb-4">
              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/BlYVY6cyLL8?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="Shivamurthy - A story of Transformation  - Documentary 2013"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                Shivamurthy - A story of Transformation - Documentary 2013
              </h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            {/* <div className="col-md-4 text-center mb-0">
              <iframe
              className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/BlYVY6cyLL8?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="Shivamurthy - A story of Transformation  - Documentary 2013"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                Shivamurthy - A story of Transformation - Documentary 2013
              </h5>
            </div> */}
            <div className="col-md-4 text-center mb-0">
              <iframe
                className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }}
                src="https://www.youtube.com/embed/wo_3sb-XNQQ?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2"
                title="Devika&#39;s Journey  - Documentary 2014"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                Devika's Journey - Documentary 2014
              </h5>
            </div>
            <div className="col-md-4 text-center mb-0">
              <iframe className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }} src="https://www.youtube.com/embed/3ejzaElODs8?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2" title="Sanjay&#39;s Destiny" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                Sanjay's Destiny
              </h5>
            </div>
            <div className="col-md-4 text-center mb-0">
              <iframe className="rounded shadow-sm "
                style={{ minHeight: "40vh", minWidth: "25vw" }} src="https://www.youtube.com/embed/oA3AES7onCs?list=PLBZIyUuyNxr8VXEeJgw2cX59Ahp9fA2B2" title="Story of Sasha - Love Without Reason in Zimbabwe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3">
                {" "}
                Story of Sasha - Love Without Reason in Zimbabwe
              </h5>
            </div>
          </div>
        </div>
      </section>

      {/* CAMPAIGN VIDEOS */}

      {/* <img className=" img-fluid" src={line} /> */}

      {/* <iframe
                className="rounded shadow-sm"
                style={{minHeight:'50vh',minWidth:'40vw'}}

                src="https://www.youtube.com/embed/czUAgPGPrmg"
                title="Philip&#39;s Dream in helping cleft patients"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}

      {/* <div className="row d-flex justify-content-center mt-5 ">
            <div className="col-md-6 mb-5 text-center">
              <iframe
                className="rounded shadow-sm"
                style={{minHeight:'50vh',minWidth:'40vw'}}

                src="https://www.youtube.com/embed/dP2Qkw8N3QI"
                title="Philip&#39;s Dream - honoring his mom"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h5 className=" text-center text-white mt-3 fw-bold">
                {" "}
                PHILIPS MATHEWS - TESTIMONIAL
              </h5>
            </div>
            <div className="col-md-6 text-center">
              <iframe
                className="rounded shadow-sm"
                style={{minHeight:'50vh',minWidth:'40vw'}}

                src="https://www.youtube.com/embed/CdfONiOWq4Q"
                title="Philip&#39;s Dream Campaign Closing Soon"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h5 className=" text-center text-white mt-3 fw-bold"> PHILIP’S JOURNEY</h5>
            </div>
          </div> */}
      {/* </div>
      </section>
      <section style={{ minHeight: "90vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-6 mt-5 text-center"> */}
      {/* <iframe
                className="rounded shadow-sm"
                src="https://player.vimeo.com/video/164022369"
                style={{minHeight:'50vh',minWidth:'40vw'}}
               
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
               */}
      {/* <iframe
                className="rounded shadow-sm"
                style={{ minHeight: "50vh", minWidth: "40vw" }}
                src="https://www.youtube.com/embed/ZvfSQRqUi_I"
                title="LWR Human Trafficking Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h5 className=" text-center text-dark mt-3  fw-bolder">
                {" "}
                LWR Human Trafficking
              </h5> */}
      {/* <p className=" text-center text-dark mt-3 " style={{fontSize:"20px"}}>
                Philip, Susan and Santhosh were invited to speak at The Wayside
                Bible Chapel, at Sedona, AZ led by Pastor Paul Wallace. The
                spoke from the word of God that Nothing is Impossible with God.
                Enjoy this video
              </p> */}
      {/* <div className="col-md-6 text-center mt-5"> */}
      {/* <iframe
                className="rounded shadow-sm"
                style={{minHeight:'50vh',minWidth:'40vw'}}
              
                src="https://www.youtube.com/embed/PjEiHb49Djs"
                title="Philip - Never Give Up"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}

      {/* <p className=" text-center mt-3 " style={{fontSize:"20px"}}>
                Philip was invited to speak at the Tennessee Conference of
                Social Welfare’s Globalization of Tennessee Conference. Philip
                was his usual self, full of fun, energy and smiles. Philip Spoke
                about Never Giving up.
              </p> */}
      {/* </div>
          </div>
        </div>
      </section> */}
      {/* <section style={{ minHeight: "80vh", backgroundColor: "#1A1E27" }}>
         <div className="container">
           <div className="row d-flex justify-content-center">
             <div className="col-md-8 pt-5  text-center"> */}
      {/* <iframe
                className="rounded shadow-sm"
              style={{minHeight:"60vh",minWidth:'50vw'}}
                
                src="https://www.youtube.com/embed/Te-dWyuc3mI"
                title="Love Without Reason, Philip Mathews - Motivational speaker"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
      {/* <iframe
                className="rounded shadow-sm"
                style={{ minHeight: "60vh", minWidth: "50vw" }}
                src="https://www.youtube.com/embed/MZt3xVITxWI"
                title="Love Without Reason"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h5 className="text-center text-white mt-2 fw-bold">
                Love Without Reason
              </h5>
            </div>
          </div>

          <div className="row  d-flex justify-content-center ">
            <div className="col-md-10 text-center ">
              <p className="text-white mt-3" style={{ fontSize: "20px" }}>
                Santhosh and Philip Mathews talking about their passion for
                children with craniofacial deformities.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* // <section style={{ minHeight: "70vh" }}>
      //   <div className="container ">
      //     <div className="row d-flex justify-content-center mt-5 ">
      //       <div className="col-md-6 text-center"> */}
      {/* <iframe
                className="rounded shadow-sm"
                style={{minHeight:'40vh',minWidth:'28vw'}}
                src="https://www.youtube.com/embed/syywI_aLxb0"
                title="Love Without Reason Ad - Philip Mathews"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
      {/* </div> */}

      {/* <div className="col-md-4 text-center">
              <iframe
                className="rounded shadow-sm"
                style={{minHeight:'40vh',minWidth:'28vw'}}

                src="https://www.youtube.com/embed/58DF_DwCeac"
                title="Love Without Reason - Smile Creators - Featuring Philip Mathews"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <h5 className=" text-center text-dark fw-bold mt-3"> PHILIP’S JOURNEY</h5>
            </div> */}
      {/* </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
}

export { Video };
