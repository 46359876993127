import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from "react";

function Vehicle() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="Vehicle"></section>

      <section style={{ minHeight: "68vh" }}>
        <div className="container  ">
          <div className="row  d-flex justify-content-between mt-3">
            <div className="col-md-3 text-center mt-3">
              <h4 className="border-bottom w-100 border-3 fw-bold border-danger">
                DONATE YOUR VEHICLE
              </h4>
            </div>

            <div className="col-md-3 text-center mt-3 mb-5">
              <a target="_blank" rel="noopener noreferrer" href="https://www.donateforcharity.com/nonprofit/love-without-reason/" className="btn btn-danger">DONATE YOUR VEHICLE</a>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12 india-text mb-5">
              {/* <p className="text-danger">donateforcharity.com https://www.donateforcharity.com/nonprofit/love-without-reason/</p> */}
              <p>Donate For Charity will work with you to distribute funds from
                the sale of your vehicle donation to Love Without Reason. The
                process is simple and effective and the impact is immeasurable.
              </p>
              <h6 className="mb-5" style={{ fontSize: "18px" }}>
                <a
                  class=" text-danger"
                  href="https://www.irs.gov/pub/irs-pdf/p4303.pdf"
                >
                  Publication 4303 (Rev. 1-2015) (irs.gov)
                </a>
              </h6>
              <h6 style={{ fontSize: "18px" }} className="mt-4 mb-3 fw-bold">Why Donate Your Car To Charity?</h6>
              <p style={{ fontSize: "18px" }}>
                Donate For Charity distributes funds from the sale of your
                vehicle donation to the nonprofit of your choice. Helping them
                maintain and expand charity efforts in their communities.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Vehicle };
