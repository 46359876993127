import React from "react";
// import { useRef } from 'react';
// import emailjs from '@emailjs/browser';
import { useState } from 'react';
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import axios from 'axios';
import { useEffect } from "react";

function Volunteers() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)

    // }).catch((err) => {
    //   alert('Failed to Submit')
    //   console.log(err);
    // })

  };
  return (
    <>
      <section className=" Volunteers" style={{ minHeight: "100vh" }}></section>
      <section style={{ minHeight: "80vh" }}>
        <div className="container  ">
          <div className="row d-flex justify-content-center mt-3 ">
            <div className="col-md-12 text-justify mt-4 px-5">
              <p style={{ fontSize: "17px" }}>
                You can select volunteer tasks that interest you, that you want
                to learn more about, and that you enjoy. Our staff will train
                you and provide support to help you be successful. This
                volunteer opportunity also has flexible scheduling to help you
                out during the week when it works for your schedule. It is a
                great way to learn and develop new job skills while helping
                others. Please, note that most of the volunteer tasks are during
                regular business hours. For the event day volunteering, you will
                be provided the hours ahead of time.
              </p>
              <h5 className="  fw-bold mt-5">Additional Requirements</h5>
              <p className="mt-3">
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;A background
                check may be required for specific tasks and opportunities.
              </p>
              <p>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Volunteers should
                be comfortable interacting with others and able to follow simple
                directions.
              </p>
              <p>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;A Volunteer
                Service Acknowledgment must be signed.
              </p>
            </div>
          </div>

          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 mt-5 px-5  ">
              <div className="row d-flex  ">
                <div className="col-md-2 ">
                  <h4 className='border-bottom border-danger border-3 fw-bold mb-3'>5K Run4Love</h4>
                </div>
              </div>
              <p style={{ fontSize: "17px" }}>
                Grab your running shoes to celebrate Craniofacial Acceptance
                Month through our annual 5K Run4Love. You can join the Love
                Without Reason team in Chattanooga at the Tennessee Riverpark or
                participate with your run virtually wherever you are. Our own
                Philip Mathews inspired Love Without Reason, and the run
                continues in his honor. Get warmed up and start the run to make
                Philip's dream come true "to see the world cleft-free!"
              </p>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "50vh" }}>
        <div className="container  ">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 text-center ">
              <h5 class=" text-dark fw-bold mb-0">VOLUNTEER APPLICATION</h5>
              <img className=" img-fluid " src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <form onSubmit={sendEmail} className="text-center">
                    <div className="row mb-2 ">
                      <div className="col-md-6">
                        <input class="form-control" placeholder="First Name" name="firstname" type="text" onChange={(e) => { userDetails(e) }} />
                      </div>
                      <div className="col-md-6">
                        <input class="form-control" placeholder="Last Name" type="text" name="lastname" onChange={(e) => { userDetails(e) }} />
                      </div>
                    </div>
                    <div className="row  mb-3">
                      <div className="col-md-6" >
                        <input class="form-control" placeholder="Phone number" type="number" name="number" onChange={(e) => { userDetails(e) }} />
                      </div>
                      <div className="col-md-6" >
                        <input class="form-control" placeholder="Email" type="email" name="email" onChange={(e) => { userDetails(e) }} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Message" onChange={(e) => { userDetails(e) }}
                          type="text" name="message"></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-12 text-center">
                        <div className="d-flex">
                          {/* <p className="text-dark m-1 text-start" >
                            Thank you for submitting your application,one of our Love
                            Without Reason staff will contact you in the next few
                            days.
                          </p> */}
                        </div>
                        <button className="btn btn-danger mt-3 fw-bold align-items-center px-3 mb-5"
                          data-bs-toggle="modal" data-bs-target="#exampleModalvolunteers"
                          type="submit">Submit</button>
                        <div class="modal fade" id="exampleModalvolunteers" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                              <div class="modal-body">
                                <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                <hr />

                                <div className="row d-flex justify-content-center">
                                  <div className="col-md-12 fs-5">
                                    Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer border-0">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Header />
      <Footer />
    </>
  );
}

export { Volunteers };
