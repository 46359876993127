import React from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'
import line from "../../assests/Group 1870 line2.png";
import innovative1 from "../../assests/innovativeimg1.png";
import innovative2 from "../../assests/innovativeimg2.png";
import innovatives1 from "../../assests/innovatives1.png";
import innovatives2 from "../../assests/innovatives2.png";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
} from "react-share";

import CopyToClipboard from "react-copy-to-clipboard";


function Innovative() {
    const [urlToCopy, setURLToCopy] = useState("");

    const handleCopy = () => {
        setURLToCopy("Copied");
    };
    console.log("urlToCopy", urlToCopy);
    useEffect(() => {
        // Set the scroll position when the component mounts
        window.scrollTo(0, 0); // Adjust the '500' value as needed
    }, []);
    return (
        <>
            <Header />
            <section className='innovativebg' style={{ minHeight: '100vh' }}>

            </section>


            <section className='' id='waterwell' style={{ minHeight: '80vh' }}>
                <div className='container '>

                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-7 mt-5  text-center'>
                            <h4 className='fw-bold mb-0 mt-5'>WATER WELLS</h4>
                            <img className="img-fluid" src={line} />
                            <p className='india-text'>
                                In rural India, villagers travel long distances for water, risking contamination and deadly diseases. Water wells can transform village health, but require full maintenance by a family, creating a sustainable income. This not only saves lives but also empowers communities for a healthier, more prosperous future.
                            </p>
                            <div className='d-flex justify-content-between'>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropinnovative1"

                                >
                                    Share <i class="fa-solid fa-share"></i>
                                </button>

                                <div className="row ">
                                    <div
                                        class="modal fade"
                                        id="staticBackdropinnovative1"
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabindex="-1"
                                        aria-labelledby="staticBackdropLabel"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog modal-dialog-centered modal-sm">
                                            <div class="modal-content">
                                                <div class="modal-header p-3">
                                                    <h5
                                                        class="modal-title fw-bolder"
                                                        id="staticBackdropLabel"
                                                    >
                                                        WATER WELLS
                                                    </h5>
                                                    <button
                                                        class="btn-close border"
                                                        data-bs-dismiss="modal"
                                                        type="button"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body p-4">
                                                    <div className="row d-flex justify-content-center">
                                                        <div className="col-md-12">
                                                            <a href="https://www.facebook.com/login/">
                                                                <FacebookShareButton
                                                                    url={
                                                                        "https://lovewithoutreason.org/InnovativeSolutions"
                                                                    }
                                                                    quote={"LWR"}
                                                                    hashtag="#lovewithoutreason"
                                                                >
                                                                    <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                                                </FacebookShareButton>
                                                            </a>
                                                            <a href="https://www.whatsapp.com/">
                                                                <WhatsappShareButton
                                                                    url={
                                                                        "https://lovewithoutreason.org/InnovativeSolutions"
                                                                    }
                                                                    quote={"LWR"}
                                                                    hashtag="#lovewithoutreason"
                                                                >
                                                                    <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                                                </WhatsappShareButton>
                                                            </a>
                                                            <a href="https://twitter.com/">
                                                                <TwitterShareButton
                                                                    url={
                                                                        "https://lovewithoutreason.org/InnovativeSolutions"
                                                                    }
                                                                    quote={"LWR"}
                                                                    hashtag="#lovewithoutreason"
                                                                >
                                                                    <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-info"></i>

                                                                </TwitterShareButton>
                                                            </a>
                                                            <CopyToClipboard
                                                                text={"https://lovewithoutreason.org/InnovativeSolutions"}
                                                                onCopy={handleCopy}
                                                            >
                                                                <i
                                                                    className="fa fa-clone fa-3x m-1"
                                                                    style={{ color: "#C3C4C4" }}
                                                                ></i>
                                                            </CopyToClipboard>
                                                            <p
                                                                style={{
                                                                    fontSize: "8px",
                                                                    textAlign: "end",
                                                                }}
                                                            >
                                                                {urlToCopy !== "" ? urlToCopy : ""}
                                                            </p>
                                                            {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/DonateIndia"><button className="btn btn-danger ms-3">Donate&nbsp;<i className="fas fa-duotone fa-hand-holding-heart"></i></button></Link>
                            </div>
                        </div>
                        <div className='col-md-5 mt-5'>
                            <img className="img-fluid mt-5" src={innovative1} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center align-items-center mt-5'>
                        <div className='col-md-5 mt-5'>
                            <img className="img-fluid" src={innovative2} />

                        </div>
                        <div className='col-md-7  text-center mt-5'>
                            <h4 className='fw-bold mb-0'>SET FREE PADS</h4>
                            <img className="img-fluid" src={line} />
                            <p className='india-text'>
                                Comfortable, reusable sanitary pads empower women's health, enabling them to care for their families and secure their children's education. Set Free Pads, made by women in vulnerable situations, offer a sustainable income, potentially shielding their children from human trafficking by enabling their education.
                            </p>
                            <div className='d-flex justify-content-between'>
                                <button
                                    type="button"
                                    class="btn btn-danger mb-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdropinnovative2"

                                >
                                    Share <i class="fa-solid fa-share"></i>
                                </button>

                                <div className="row ">
                                    <div
                                        class="modal fade"
                                        id="staticBackdropinnovative2"
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabindex="-1"
                                        aria-labelledby="staticBackdropLabel"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog modal-dialog-centered modal-sm">
                                            <div class="modal-content">
                                                <div class="modal-header p-3">
                                                    <h5
                                                        class="modal-title fw-bolder"
                                                        id="staticBackdropLabel"
                                                    >
                                                        SET FREE PADS
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close border"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body p-4">
                                                    <div className="row d-flex justify-content-center">
                                                        <div className="col-md-12">
                                                            <a href="https://www.facebook.com/login/">
                                                                <FacebookShareButton
                                                                    url={
                                                                        "https://lovewithoutreason.org/InnovativeSolutions"
                                                                    }
                                                                    quote={"LWR"}
                                                                    hashtag="#lovewithoutreason"
                                                                >
                                                                    <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                                                </FacebookShareButton>
                                                            </a>
                                                            <a href="https://www.whatsapp.com/">
                                                                <WhatsappShareButton
                                                                    url={
                                                                        "https://lovewithoutreason.org/InnovativeSolutions"
                                                                    }
                                                                    quote={"LWR"}
                                                                    hashtag="#lovewithoutreason"
                                                                >
                                                                    <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                                                </WhatsappShareButton>
                                                            </a>
                                                            <a href="https://twitter.com/">
                                                                <TwitterShareButton
                                                                    url={
                                                                        "https://lovewithoutreason.org/InnovativeSolutions"
                                                                    }
                                                                    quote={"LWR"}
                                                                    hashtag="#lovewithoutreason"
                                                                >
                                                                    <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-info"></i>

                                                                </TwitterShareButton>
                                                            </a>
                                                            <CopyToClipboard
                                                                text={"https://lovewithoutreason.org/InnovativeSolutions"}
                                                                onCopy={handleCopy}
                                                            >
                                                                <i
                                                                    className="fa fa-clone fa-3x m-1"
                                                                    style={{ color: "#C3C4C4" }}
                                                                ></i>
                                                            </CopyToClipboard>
                                                            <p
                                                                style={{
                                                                    fontSize: "8px",
                                                                    textAlign: "end",
                                                                }}
                                                            >
                                                                {urlToCopy !== "" ? urlToCopy : ""}
                                                            </p>
                                                            {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/DonateIndia"><button className="btn btn-danger mb-3">Donate&nbsp;<i className="fas fa-duotone fa-hand-holding-heart"></i></button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className=' mb-2' style={{ minHeight: '100vh' }}>
                <div className='container'>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 text-center mt-5">
                            <h4 class="text-dark fw-bold mb-0 text-uppercase mt-3">Awareness and Leadership Training</h4>
                            <img className="img-fluid" src={line} alt='image' />
                        </div>
                    </div>

                    <div className='row '>
                        <div className='col-md-12 text-center '>
                            <p className="">
                                Education is the single most important factor in
                                eradicating human trafficking. Every opportunity to raise awareness of this evil crime is taken
                                during the medical camps and during Love Without Reason events. Our volunteers and
                                counselors empower and educate the parents and children during the medical camps with
                                information about human trafficking. Our efforts include sharing contact information of local
                                authorities to whom our families may report suspicious activities.
                            </p>
                        </div>
                    </div>


                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-6 text-center mt-5'>
                            <img className="img-fluid" src={innovatives1} />

                            <p className='mt-5'>
                                People, Not Products is a one-day seminar featuring speakers on local, national and
                                international levels to educate on cybersecurity, internet safety, addiction and human
                                trafficking.<br /><br />


                            </p>
                        </div>
                        <div className='col-md-6 text-center mt-5 '>
                            <img className="img-fluid" src={innovatives2} />

                            <p className='mt-5'>


                                Community groups and churches provide leadership training to cultivate dedicated, integrity-driven leaders. Partnerships with schools, churches, and businesses facilitate this essential development.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />


        </>
    )
}

export default Innovative
