import React from "react";
import uganda1 from "../../assests/group90.png";
import uganda2 from "../../assests/ugandapopup.png";
import line from "../../assests/Group 1870 line2.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

function Uganda() {
  const [urlToCopy, setURLToCopy] = useState("");

  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  console.log("urlToCopy", urlToCopy);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      <section className="uganda mt-5" style={{ minHeight: "100vh" }}></section>

      <section style={{ minHeight: "80vh" }}>
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h4 class=" text-dark fw-bolder mb-0">AJOK GRACE</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div> */}

          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col-md-6 text-justify">
            <h3 className="fw-bolder text-center">LOVE WITHOUT REASON <span className="text-danger">UGANDA</span></h3>
              <p className="india-text mt-3">
                Uganda has one of the youngest, most rapidly growing populations
                in the world. The birth rate is climbing. The country does also
                take in refugees from surrounding countries, and this only adds
                to the climbing population. The health care facilities need
                additional support in providing life-transforming care for cleft
                children. Unfortunately, in a climate of poverty and poor
                health, human trafficking is prevalent. Now, more than ever,
                education and awareness is needed to protect children and youth
                from this evil crime. You can be a part of sending a team to
                Uganda for a medical camp. You can be a part of the change. You
                can be a part of #PhilipsDream
              </p>
              <button
                className="btn btn-danger fw-bolder  mt-3 mb-3"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop1"
              >
                Read More
              </button>
            </div>
            <div className="col-md-4 mb-3">
              <img className=" img-fluid" src={uganda1} />
            </div>
          </div>

          <div
            class="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content modelscroll">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div class="modal-body">
                  <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-6">
                      <img className="img-fluid rounded " src={uganda2} />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12 text-center">
                      <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                        AJOK GRACE
                      </h4>

                      <img className=" img-fluid" src={line} />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-10">
                      <p className="india-text">
                        Uganda has one of the youngest, most rapidly growing
                        populations in the world. The birth rate is climbing.
                        The country does also take in refugees from surrounding
                        countries, and this only adds to the climbing
                        population. The health care facilities need additional
                        support in providing life-transforming care for cleft
                        children. Unfortunately, in a climate of poverty and
                        poor health, human trafficking is prevalent. Now, more
                        than ever, education and awareness is needed to protect
                        children and youth from this evil crime. You can be a
                        part of sending a team to Uganda for a medical camp. You
                        can be a part of the change. You can be a part of
                        #PhilipsDream
                        <br />
                        Ajok Grace lived her life in South Sudan, never
                        expecting anything good to come out of it. When she fled
                        to Uganda to escape the war-torn South Sudan, she had no
                        great expectations in life. With her cleft lip, people
                        treated her as less than a human. However, after the
                        Uganda medical camp in 2022, Ajok’s smile and her life
                        was forever changed. Hope began to rise up in her as she
                        observed her new smile after surgery. After several
                        months, she received a marriage proposal. Today, she is
                        a wife and mother. Through the traumatic experiences of
                        her young life, something new was born. And it started
                        with her smile.
                        <br />
                        <br />
                        The neonatal mortality rate indicates the probability of
                        a newborn’s death within the first 28 days of life in a
                        specific time per 1000 live births. The data is
                        collected by UNICEF, and thankfully the numbers have
                        declined over the last 40 years. However, they do still
                        indicate 19 deaths out of 1000 births in Uganda over the
                        past 3 years. Children born with clefts will deal with
                        infection and nutritional and feeding challenges which
                        makes the first 28 days of life so critical. There is
                        much to improve with education, psychosocial and
                        emotional wellbeing, physical health, and preventative
                        care. Partner with the Love Without Reason team to bring
                        tangible change to the children of Uganda. They are the
                        future!
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-2">
                    <div
                      class="modal fade"
                      id="staticBackdropuganda1"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered modal-sm">
                        <div class="modal-content">
                          <div class="modal-header p-3">
                            <h5
                              class="modal-title fw-bolder"
                              id="staticBackdropLabel"
                            >
                              AJOK GRACE
                            </h5>
                            <button
                              type="button"
                              class="btn-close border"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div className="row d-flex justify-content-center">
                              <div className="col-md-12">
                                <a href="https://www.facebook.com/login/">
                                  <FacebookShareButton
                                    url={"https://lovewithoutreason.org/Uganda"}
                                    quote={"LWR"}
                                    hashtag="#lovewithoutreason"
                                  >
                                    <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                  </FacebookShareButton>
                                </a>
                                <a href="https://www.whatsapp.com/">
                                  <WhatsappShareButton
                                    url={"https://lovewithoutreason.org/Uganda"}
                                    quote={"LWR"}
                                    hashtag="#lovewithoutreason"
                                  >
                                    <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                  </WhatsappShareButton>
                                </a>
                                <a href="https://twitter.com/">
                                  <TwitterShareButton
                                    url={"https://lovewithoutreason.org/Uganda"}
                                    quote={"LWR"}
                                    hashtag="#lovewithoutreason"
                                  >
                                    <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                  </TwitterShareButton>
                                </a>
                                <a href="https://www.linkedin.com/">
                                  <LinkedinShareButton
                                    url={"https://lovewithoutreason.org/Uganda"}
                                    title={"LWR"}
                                    summary={"https://lovewithoutreason.org/"}
                                    source={"#lovewithoutreason"}
                                  >

                                    <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                  </LinkedinShareButton>
                                </a>

                                <CopyToClipboard
                                  text={"https://lovewithoutreason.org/Uganda"}
                                  onCopy={handleCopy}
                                >
                                  <i
                                    className="fa fa-clone fa-3x m-1"
                                    style={{ color: "#C3C4C4" }}
                                  ></i>
                                </CopyToClipboard>
                                <p
                                  style={{ fontSize: "8px", textAlign: "end" }}
                                >
                                  {urlToCopy !== "" ? urlToCopy : ""}
                                </p>
                                {/* <a href="https://www.instagram.com/">
                                  <i
                                    className="fa-brands fa-square-instagram fa-3x m-1"
                                    style={{ color: "#E4405F" }}
                                  ></i>
                                </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 ms-auto ">
                      <button
                        type="button"
                        class="btn btn-danger w-100 mb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropuganda1"
                      >
                        Share <i class="fa-solid fa-share"></i>
                      </button>
                    </div>
                    <div className="col-md-2 ">
                      <button
                        type="button"
                        class="btn btn-danger w-100"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ minHeight: "100vh" }} className="uganda-global mb-5">
        <div className="container mt-5 ">
          <div className="row  d-flex align-items-center mt-5 pt-5">
            <div className="col-md-12 text-white text-end mt-5 pt-5">
              <h1 className="fw-bolder mt-5 pt-5">21</h1>
              <h5 className="fw-bolder">NUMBER OF SURGERIES</h5>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export { Uganda };
