import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import React from 'react';
import axios from 'axios';
import { Header } from "../Header";
import { Footer } from "../Footer";
import paypal from '../../assests/usapaypal.png';
import venmo from '../../assests/usavenmo.png';
import cashapp from '../../assests/usasmile.png';
import india1 from '../../assests/Donationindia1.png';
import india2 from '../../assests/Donationindia2.png';
import india3 from '../../assests/donationupiindia.png';

function DonationUsa({ surgeriesCounter, countryCount, campCount }) {
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };


    return (
        <>
            <Header />
            <section className='Donation-usa mt-5'>
                <div className="container">
                    <div className="row  mt-5">
                        <div className="col-md-5 mt-5">
                            <div class="card text-center p-3">
                                {/* <img src="..." class="card-img-top" alt="..." /> */}
                                <div class="card-body">
                                    <h4 class="card-title fw-bolder">Donate to <span className="text-danger">Love Without Reason</span></h4>
                                    <p class="card-text mt-3" style={{ textAlign: 'justify' }}>We invite you to become a monthly partner (PHILIPIAN) with Love Without Reason.  For just $100 a month, you can save a life from trafficking and provide the needed surgery to completely change a life forever!</p>
                                    {/* <a href="https://lovewithoutreasonusa.harnessgiving.org/donate/" target="_blank" class="btn btn-danger">Click to Donate</a> */}
                                    <button
                                        onClick={handleButtonClick}
                                        className="btn btn-danger"
                                        style={{ padding: '10px 20px', fontSize: '16px' }}
                                    >
                                        Click to Donate
                                    </button>
                                    {showModal && (
                                        <div className="modal show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                            <div className="modal-dialog modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Donate to Love Without Reason</h5>
                                                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <iframe
                                                            src="https://lovewithoutreasonusa.harnessgiving.org/donate/"
                                                            width="100%"
                                                            height="600px"
                                                            style={{ border: 'none' }}
                                                            title="Donation Page"
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center mb-3">
                                <div className="col-lg-4 mt-3 text-center">
                                    <Link to="https://www.paypal.com/donate/?hosted_button_id=CHDYF6D2KDTVQ" target="_blank"><img className="img-fluid rounded" src={paypal} /></Link>


                                </div>
                                <div className="col-lg-4 mt-3 text-center">
                                    <img className="img-fluid rounded" src={venmo} />

                                </div>
                                <div className="col-lg-4 mt-3 text-center">
                                    <img className="img-fluid rounded" src={cashapp} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container-fluid mb-4'>
                    <div className='row d-flex justify-content-center mt-5'>
                        <div className='col-md-5'>
                            <progress class="progress1" value="100" max="100"></progress>
                            <progress class="progress2 ms-1" value="50" max="50"></progress>
                            <progress class="progress3 ms-1" value="20" max="20"></progress>
                            <progress class="progress4 ms-1" value="10" max="10"></progress>
                            <h5 className='fw-bolder'>IMPACT OF YOUR DONATION</h5>
                            <p style={{ fontSize: '20px' }}>These numbers are not just statistics they represent real lives touched,families forever changed,and dreams rekindled. Your Support
                                has been instrumental in bringing about this transformation.</p>
                            <img src={india1} className='img-fluid' />
                        </div>
                        <div className='col-md-5'>
                            <img src={india2} className='img-fluid' />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center bg-danger p-4 text-center text-white mt-3'>
                        <div className='col-md-3'>
                            <h2 className='fw-bolder'>{countryCount}</h2>
                            <h4 className='fw-bolder'>COUNTRIES</h4>
                        </div>
                        <div className='col-md-3'>
                            <h2 className='fw-bolder'>600+</h2>
                            <h4 className='fw-bolder'>VOLUNTEERS</h4>
                        </div>
                        <div className='col-md-3'>
                            <h2 className='fw-bolder'>{campCount}</h2>
                            <h4 className='fw-bolder'>CAMPS</h4>
                        </div>
                        <div className='col-md-3'>
                            <h2 className='fw-bolder'>{surgeriesCounter}</h2>
                            <h4 className='fw-bolder'>SURGERIES</h4>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DonationUsa