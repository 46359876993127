import React from "react";
import image_1 from "../../assests/Sponsor1.png";
import line from "../../assests/Group 1870 line2.png";
import image_2 from "../../assests/sponsor3.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useState, useEffect } from 'react';
import axios from 'axios';



function Sponsor() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)

    // }).catch((err) => {
    //   alert('Failed to Submit')
    //   console.log(err);
    // })

  };
  return (
    <>
      <Header />
      <div
        className="Sponser container-fluid d-flex align-items-end justify-content-end">
        <div className="row ">
          <div className="col-md-12 text-center mt-5 mb-2">
            <button
              className="btn btn-light fw-bold ms-3 m-1 mb-1 "
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              BECOME A PARTNER
            </button>
            <button
              className="btn btn-danger fw-bold ms-3 m-1 mb-1 "
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              BECOME A SPONSOR
            </button>
            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg ">
                <div class="modal-content ">
                  <div class="modal-header border-0 ">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h4
                          class="modal-title fw-bolder text-center mb-5 mt-0"
                          id="exampleModalLabel"
                        >
                          Partnership Form
                        </h4>
                        <p className="fs-6 px-5"> We invite hospitals, nonprofit organizations and philathropists to partner with Love Without Reason to make the world a safer place. Would you take the time to let us know how you would like to sponsor or partner with us? </p>
                      </div>
                      <div className="col-md-2">

                      </div>
                      <div className="col-md-8">
                        <form onSubmit={sendEmail} className="text-center">
                          <div className="row mb-2 ">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="First Name"
                                name="firstname"
                                type="text"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Last Name"
                                type="text"
                                name="lastname"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row  mb-3">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Phone number"
                                type="number"
                                name="number"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <p className="text-dark m-1 text-start fs-6">
                              Please let us know your thoughts with regards to partnering with Love Without Reason
                            </p>
                            <div className="col-md-12">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Message"
                                type="text"
                                name="message"
                                onChange={(e) => { userDetails(e) }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-12 text-center">
                              <div className="d-flex">

                              </div>
                              <button
                                className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                data-bs-toggle="modal" data-bs-target="#exampleModalsponsor1"
                                type="submit"
                              >
                                Submit
                              </button>
                              <div class="modal fade" id="exampleModalsponsor1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                    <div class="modal-body">
                                      <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                      <hr />

                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 fs-5">
                                          Thank you for contakcting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer border-0">
                                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Modal 2--> */}
            <div
              class="modal fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg ">
                <div class="modal-content ">
                  <div class="modal-header border-0 ">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h4
                          class="modal-title fw-bolder text-center mb-5 mt-0"
                          id="exampleModalLabel"
                        >
                          Sponsorship Form
                        </h4>
                        <p className="fs-6 px-5">We thank you for your generosity and philanthropy. We love to hear from you about your interest in investing in the work of Love Without Reason. Do take the time to let us know how you would like to be a part of this great move. </p>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <form onSubmit={sendEmail} className="text-center">
                          <div className="row mb-2 ">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="FirstName"
                                name="first Name"
                                type="text"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Last Name"
                                type="text"
                                name="lastname"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row  mb-3">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Phone number"
                                type="number"
                                name="number"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <p className="text-dark m-1 text-start fs-6">
                              Please let us know your thoughts with regards to partnering with Love Without Reason
                            </p>
                            <div className="col-md-12">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Message"
                                type="text"
                                name="message"
                                onChange={(e) => { userDetails(e) }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-12 text-center">
                              <div className="d-flex">
                                {/* <p className="text-dark m-1 text-start">
                                  Thank you for submitting your application,one
                                  of our Love Without Reason staff will contact
                                  you in the next few days.
                                </p> */}
                              </div>
                              <button
                                className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                type="submit"
                                data-bs-toggle="modal" data-bs-target="#exampleModalsponsor2"
                              >
                                Submit
                              </button>
                              <div class="modal fade" id="exampleModalsponsor2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                    <div class="modal-body">
                                      <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                      <hr />

                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 fs-5">
                                          Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer border-0">
                                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section style={{ minHeight: "100vh" }}>
        <div className="container mt-5">
          <div className="row d-flex justify-content-center align-items-center pt-3 mt-5">
            <div className="col-md-5 mt-5">
              <img className="img-fluid" src={image_1} />
            </div>

            <div className="col-md-7">
              <div className="row">
                <div className="col-12 text-center">
                  <h4 className="border-danger fw-bold ">
                    SPONSOR A MEDICAL CAMP MEMBER
                  </h4>
                  <img className=" img-fluid" src={line} />
                  <p className="mt-5" style={{ fontSize: "18px" }}>
                    Providing travel, food, and lodging for team members during
                    the medical camp is crucial. We rely heavily on our staff to
                    work directly with communities in Zimbabwe, Kenya, Uganda
                    and India.
                  </p>
                </div>
              </div>
              <div className="row d-flex justify-content-center px-3">
                <div className="col-md-4 col-sm-12">
                  <button className="border-0 btn btncards" data-bs-toggle="modal" data-bs-target="#exampleModalcard1">
                    <div className="card text-center p-3 shadow m-1" style={{ width: '100%', height: '100%' }}>
                      <small className="">
                        <b>5 Business Day Medical Camp</b>
                      </small>
                      <p className="mt-2">
                        $ 3500
                        <br />
                        (per individual)
                      </p>
                    </div>
                  </button>
                </div>
                <div class="modal fade" id="exampleModalcard1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header border-1">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h4
                              class="modal-title fw-bolder text-center mb-5 mt-0"
                              id="exampleModalLabel"
                            >
                              5 Buisness Day Medical Camp
                            </h4>

                          </div>
                          <div className="col-md-2">

                          </div>
                          <div className="col-md-8">
                            <form onSubmit={sendEmail} className="text-center">
                              <div className="row mb-2 ">
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="First Name"
                                    name="firstname"
                                    type="text"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Last Name"
                                    type="text"
                                    name="lastname"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                              </div>
                              <div className="row  mb-3">
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Phone number"
                                    type="number"
                                    name="number"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                              </div>
                              <div className="row ">
                                <p className="text-dark m-1 text-start fs-6">
                                  Please let us know your thoughts with regards to partnering with Love Without Reason
                                </p>
                                <div className="col-md-12">
                                  <textarea
                                    class="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Message"
                                    type="text"
                                    name="message"
                                    onChange={(e) => { userDetails(e) }}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center mt-2">
                                <div className="col-md-12 text-center">
                                  <div className="d-flex">

                                  </div>
                                  <button
                                    className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                    type="submit"
                                    data-bs-toggle="modal" data-bs-target="#exampleModalsponsor3"
                                  >
                                    Submit
                                  </button>
                                  <div class="modal fade" id="exampleModalsponsor3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                      <div class="modal-content">
                                        {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                        <div class="modal-body">
                                          <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                          <hr />

                                          <div className="row d-flex justify-content-center">
                                            <div className="col-md-12 fs-5">
                                              Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                            </div>
                                          </div>
                                        </div>
                                        <div class="modal-footer border-0">
                                          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <button className="border-0 btn btncards" data-bs-toggle="modal" data-bs-target="#exampleModalcard2">
                    <div className="card text-center p-3 shadow m-1" style={{ width: '100%', height: '100%' }}>
                      <small className="">
                        <b>Two - week Medical Mission</b>
                      </small>

                      <p className="mt-2">
                        $ 5000
                        <br />
                        (per individual)
                      </p>
                    </div>
                  </button>
                </div>

                <div class="modal fade" id="exampleModalcard2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h4
                              class="modal-title fw-bolder text-center mb-5 mt-0"
                              id="exampleModalLabel"
                            >
                              Two-week Medical Mission
                            </h4>

                          </div>
                          <div className="col-md-2">

                          </div>
                          <div className="col-md-8">
                            <form onSubmit={sendEmail} className="text-center">
                              <div className="row mb-2 ">
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="First Name"
                                    name="firstname"
                                    type="text"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Last Name"
                                    type="text"
                                    name="lastname"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                              </div>
                              <div className="row  mb-3">
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Phone number"
                                    type="number"
                                    name="number"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                              </div>
                              <div className="row ">
                                <p className="text-dark m-1 text-start fs-6">
                                  Please let us know your thoughts with regards to partnering with Love Without Reason
                                </p>
                                <div className="col-md-12">
                                  <textarea
                                    class="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Message"
                                    type="text"
                                    name="message"
                                    onChange={(e) => { userDetails(e) }}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center mt-2">
                                <div className="col-md-12 text-center">
                                  <div className="d-flex">

                                  </div>
                                  <button
                                    className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                    type="submit"
                                    data-bs-toggle="modal" data-bs-target="#exampleModalsponsor4"
                                  >
                                    Submit
                                  </button>
                                  <div class="modal fade" id="exampleModalsponsor4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                      <div class="modal-content">
                                        {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                        <div class="modal-body">
                                          <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                          <hr />

                                          <div className="row d-flex justify-content-center">
                                            <div className="col-md-12 fs-5">
                                              Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                            </div>
                                          </div>
                                        </div>
                                        <div class="modal-footer border-0">
                                          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-md-4 col-sm-12">
                  <button className="btn border-0 btncards" data-bs-toggle="modal" data-bs-target="#exampleModalcard3">
                    <div className="card text-center p-3 shadow m-1" style={{ width: '100%', height: '100%' }}>
                      <small className="">
                        <b>Three-week Medical Mission</b>
                      </small>

                      <p className="mt-2">
                        $ 6500
                        <br />
                        (per individual)
                      </p>
                    </div>
                  </button>
                </div>

                <div class="modal fade" id="exampleModalcard3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <h4
                              class="modal-title fw-bolder text-center mb-5 mt-0"
                              id="exampleModalLabel"
                            >
                              Three-week Medical Mission
                            </h4>

                          </div>
                          <div className="col-md-2">

                          </div>
                          <div className="col-md-8">
                            <form onSubmit={sendEmail} className="text-center">
                              <div className="row mb-2 ">
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="First Name"
                                    name="firstname"
                                    type="text"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Last Name"
                                    type="text"
                                    name="lastname"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                              </div>
                              <div className="row  mb-3">
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Phone number"
                                    type="number"
                                    name="number"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <input
                                    class="form-control"
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    onChange={(e) => { userDetails(e) }}
                                  />
                                </div>
                              </div>
                              <div className="row ">
                                <p className="text-dark m-1 text-start fs-6">
                                  Please let us know your thoughts with regards to partnering with Love Without Reason
                                </p>
                                <div className="col-md-12">
                                  <textarea
                                    class="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Message"
                                    type="text"
                                    name="message"
                                    onChange={(e) => { userDetails(e) }}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-center mt-2">
                                <div className="col-md-12 text-center">
                                  <div className="d-flex">

                                  </div>
                                  <button
                                    className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                    type="submit"
                                    data-bs-toggle="modal" data-bs-target="#exampleModalsponsor5"
                                  >
                                    Submit
                                  </button>
                                  <div class="modal fade" id="exampleModalsponsor5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                      <div class="modal-content">
                                        {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                        <div class="modal-body">
                                          <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                          <hr />

                                          <div className="row d-flex justify-content-center">
                                            <div className="col-md-12 fs-5">
                                              Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                            </div>
                                          </div>
                                        </div>
                                        <div class="modal-footer border-0">
                                          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="" style={{ minHeight: "90vh", backgroundColor: "#1A1E27" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 mt-5">
              <h4 className="text-white mt-5"> MEDICAL CAMP</h4>
              <p className="text-white mt-3 ">
                At <span className="text-danger ">$75,000</span>
              </p>
              <p className="text-white mt-3 " style={{ fontSize: "18px" }}>
                Funded by 5-10 community-focused philanthropic efforts to
                contribute between $10,000 - $20,000 to ensure an entire camp's
                operating budget.This guarantees underdeveloped country medical
                camps with the materials to run an ICU, anesthesia, laboratory,
                appropriate medications, and emergency care drugs and equipment.
                On-site pediatricians, surgeons, anesthesiologists, nurses,
                counselors, human trafficking educators, and volunteers are
                ready for the intensive screening, surgery, recovery, and next
                steps to speech therapy.
              </p>
              <button className="btn btn-danger border border-danger text-white mt-3" data-bs-toggle="modal" data-bs-target="#exampleModalmedical">Would you Like to Sponsor a Medical Camp </button>
            </div>
            <div class="modal fade" id="exampleModalmedical" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title fw-bold" id="exampleModalLabel">Medical Camp Sponsor</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <form onSubmit={sendEmail} className="text-center">
                          <div className="row mb-2 ">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="FirstName"
                                name="first Name"
                                type="text"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Last Name"
                                type="text"
                                name="lastname"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row  mb-3">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Phone number"
                                type="number"
                                name="number"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <p className="text-dark m-1 text-start fs-6">
                              Please let us know your thoughts with regards to partnering with Love Without Reason
                            </p>
                            <div className="col-md-12">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Message"
                                type="text"
                                name="message"
                                onChange={(e) => { userDetails(e) }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-12 text-center">
                              <div className="d-flex">
                                {/* <p className="text-dark m-1 text-start">
                                  Thank you for submitting your application,one
                                  of our Love Without Reason staff will contact
                                  you in the next few days.
                                </p> */}
                              </div>
                              <button
                                className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                type="submit"
                                data-bs-toggle="modal" data-bs-target="#exampleModalsponsor6"
                              >
                                Submit
                              </button>
                              <div class="modal fade" id="exampleModalsponsor6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                    <div class="modal-body">
                                      <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                      <hr />

                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 fs-5">
                                          Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer border-0">
                                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                  </div>
                  {/* <div class="modal-footer border-0">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-5  mt-5">
              <img className="img-fluid " src={image_2} />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white" style={{ minHeight: "80vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10 text-center">
              <h4 class="text-center fw-bold mb-0  mt-5">
                THANK YOU PARTNERS
              </h4>
              <img className=" img-fluid" src={line} />
              <p style={{ fontSize: "18px" }}>
                A big <span className="fw-bold">THANK YOU</span> to all of our
                Corporate Partners. With the partnerships of corporates who
                care, Love Without Reason reaches remote villages to provide
                cost-free surgeries to the poor.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-5 mt-3">
              <p>
                <i class="fa-solid fa-caret-right"></i> Baptist Christian
                Hospital - Tezpur, Assam
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Aster CMI Hospital -
                Bangalore, India
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> AAR Hospital - Nairobi,
                Kenya
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Samaritan’s Purse
                International Relief
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Kenyatta University
                Teaching, Referral and Research Hospital - Nairobi, Kenya
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Harare Central Hospital
                - Harare,Zimbabwe
              </p>
            </div>

            <div className="col-md-5 mt-3">
              <p>
                <i class="fa-solid fa-caret-right"></i> Chitungwiza Central
                Hospital - Chitungwiza, Zimbabwe
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Dr. Sujay, Orthodontics
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Christia Seva Mission
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> Axon Anaesthesia
                Associates
              </p>

              <p>
                <i class="fa-solid fa-caret-right"></i> Supplies OverSeas
                International
              </p>
              <p>
                <i class="fa-solid fa-caret-right"></i> MedShare
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="founderbg1 mb-5" style={{ minHeight: "100vh", backgroundColor: "#1A1E27" }}>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-10 text-center mb-2 mt-3">
              <h4 class="text-center text-white mb-0 mt-5 fw-bold"> SPONSORS</h4>
              <img className=" img-fluid" src={line} />
              <p className="text-white mt-4" style={{ fontSize: "18px" }}>A big <b>THANK YOU </b>to all of our Corporate, Organizational, Churches and individual sponsors. With the help of our amazing sponsors, Love Without Reason has been able to raech remote villages that are inaccessible to many and provide cost-free surgeries to the poor.</p>
            </div>
          </div>

          <div className="row d-flex justify-content-center text-white">
            <div className="col-md-5 mb-5">
              <ul>
                <li><i class="fa-solid fa-caret-right"></i> 10/40 Connections</li>
                <li><i class="fa-solid fa-caret-right"></i>  Apple Corporate Wellness, Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Art of Dentistry, PLLC</li>
                <li><i class="fa-solid fa-caret-right"></i> AVM Enterprises</li>
                <li><i class="fa-solid fa-caret-right"></i> Buddy’s Bar-B-Q</li>
                <li><i class="fa-solid fa-caret-right"></i> Catholic Health Initiatives</li>
                <li><i class="fa-solid fa-caret-right"></i> Causecast Foundation</li>
                <li><i class="fa-solid fa-caret-right"></i> Chattanooga Area Chamber</li>
                <li><i class="fa-solid fa-caret-right"></i> Chattanooga Christian Assembly</li>
                <li><i class="fa-solid fa-caret-right"></i> Chattanooga Cornerstone Church</li>
                <li><i class="fa-solid fa-caret-right"></i> Chattanooga State Community College</li>
                <li><i class="fa-solid fa-caret-right"></i> Christian Assembly of Houston</li>
                <li><i class="fa-solid fa-caret-right"></i> Christian Community Church</li>
                <li><i class="fa-solid fa-caret-right"></i> Cloud 9 Cafe & Cakery</li>
                <li><i class="fa-solid fa-caret-right"></i> CnJ Technologies</li>
                <li><i class="fa-solid fa-caret-right"></i> Dollywood</li>
                <li><i class="fa-solid fa-caret-right"></i> Dwelling Place Church International</li>
                <li><i class="fa-solid fa-caret-right"></i> East Nooga, LLC</li>
                <li><i class="fa-solid fa-caret-right"></i> East Ridge Chamber Council</li>
                <li><i class="fa-solid fa-caret-right"></i> Encounter With Truth Ministries</li>
                <li><i class="fa-solid fa-caret-right"></i> Eyear Optical</li>
                <li><i class="fa-solid fa-caret-right"></i> First Baptist Duluth</li>
                <li><i class="fa-solid fa-caret-right"></i> Fletcher Bright Company</li>
                <li><i class="fa-solid fa-caret-right"></i> Food City</li>
                <li><i class="fa-solid fa-caret-right"></i> Global Green Lighting</li>
                <li><i class="fa-solid fa-caret-right"></i> Green Meadows</li>
                <li><i class="fa-solid fa-caret-right"></i> Harvest Grocery</li>
                <li><i class="fa-solid fa-caret-right"></i> Hope and Love For His People, Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Hope International Christian Fellowship</li>
                <li><i class="fa-solid fa-caret-right"></i> Hospitology Products LLC</li>
                <li><i class="fa-solid fa-caret-right"></i> In The Buff Day Spa</li>
                <li><i class="fa-solid fa-caret-right"></i> IPC Florida, Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Janice W. Jones Survivors Trust</li>
                <li><i class="fa-solid fa-caret-right"></i> JIG Consulting, LLC</li>
                <li><i class="fa-solid fa-caret-right"></i> Keithcare Management LLC</li>
                <li><i class="fa-solid fa-caret-right"></i> One Church, New York</li>
                <li><i class="fa-solid fa-caret-right"></i> Grace Pentecostal Church, Philadelphia</li>
                <li><i class="fa-solid fa-caret-right"></i> Abundant Life Bible Church, Georgia</li>




              </ul>
            </div>

            <div className="col-md-5">
              <ul>
                <li><i class="fa-solid fa-caret-right"></i> Life Stream Church</li>
                <li><i class="fa-solid fa-caret-right"></i> Lighting Gallery</li>
                <li><i class="fa-solid fa-caret-right"></i> Living Water Christian Church</li>
                <li><i class="fa-solid fa-caret-right"></i> MainStay Suites</li>
                <li><i class="fa-solid fa-caret-right"></i> Maple Street Biscuit Company</li>
                <li><i class="fa-solid fa-caret-right"></i> McKee Foods Corporation</li>
                <li><i class="fa-solid fa-caret-right"></i> Metro Church of God</li>
                <li><i class="fa-solid fa-caret-right"></i> Miller & Martin Miller & McPhail</li>
                <li><i class="fa-solid fa-caret-right"></i> Miramar High School</li>
                <li><i class="fa-solid fa-caret-right"></i> My Discount Liquor/Wine</li>
                <li><i class="fa-solid fa-caret-right"></i> Chattanooga State Community College</li>
                <li><i class="fa-solid fa-caret-right"></i> Pediatric Dentistry of Cleveland and Ootlewah</li>
                <li><i class="fa-solid fa-caret-right"></i> Pentecostal Youth Fellowship of America, Inc.</li>
                <li><i class="fa-solid fa-caret-right"></i> R.T. Enterprises, Inc.</li>
                <li><i class="fa-solid fa-caret-right"></i> Rainforest Adventures, Sevierville</li>
                <li><i class="fa-solid fa-caret-right"></i> Ruby's Warehouse</li>
                <li><i class="fa-solid fa-caret-right"></i> Seed Biotech</li>
                <li><i class="fa-solid fa-caret-right"></i> Silicon Valley Community Foundation</li>
                <li><i class="fa-solid fa-caret-right"></i> Spectrum Financial Group Stone</li>
                <li><i class="fa-solid fa-caret-right"></i> Tennessee Titans</li>
                <li><i class="fa-solid fa-caret-right"></i> The Community Foundation of South Alabama</li>
                <li><i class="fa-solid fa-caret-right"></i> The Father's House</li>
                <li><i class="fa-solid fa-caret-right"></i> The Generosity Trust</li>
                <li><i class="fa-solid fa-caret-right"></i> The Indian Christian Church, Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> The International Christian Church, Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Thirty-One Gifts</li>
                <li><i class="fa-solid fa-caret-right"></i> Thompson Engineering Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Vision Hospitality Group Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Wayside Chapel Sedona Community Church</li>
                <li><i class="fa-solid fa-caret-right"></i> Wellspring Church</li>
                <li><i class="fa-solid fa-caret-right"></i> Wild Olive Branches</li>
                <li><i class="fa-solid fa-caret-right"></i> Wilson & Bow</li>
                <li><i class="fa-solid fa-caret-right"></i> Wonderful Day Inc</li>
                <li><i class="fa-solid fa-caret-right"></i> Your Cause LLC for MasterCard</li>
                <li><i class="fa-solid fa-caret-right"></i> Zion LLC</li>
                <li><i class="fa-solid fa-caret-right"></i> Compassion House, Georgia</li>
                <li><i class="fa-solid fa-caret-right"></i> The Maclellan Foundation, Tennessee</li>
                <li><i class="fa-solid fa-caret-right"></i> Doyle Family Dentistry</li>

              </ul>

            </div>
          </div>

        </div>
      </section>



      <Footer />
    </>
  );
}

export { Sponsor };
