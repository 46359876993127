import React from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import lwr3 from "../../assests/LWRstory3.png";
import lwr4 from "../../assests/LWRstory4.png";
import lwr5 from "../../assests/LWRstory5.png";
import lwr6 from "../../assests/LWRstory6.png";
import { useEffect } from 'react';


function Yourimpact() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
    return (
        <>
            <Header />


            <section id="impact" style={{ minHeight: "100vh", backgroundColor: "#1A1E27" }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="col-md-12 text-center mt-5">
              <h2 className="fwbold text-white mt-5">YOUR IMPACT</h2>
              <img src={line} className="img-fluid" />
            </div>
          </div>
          <div className="row d-flex justify-content-center  text-center">
            <div className="col-md-10">
              <p className="text-white p-3" style={{fontSize:"20px"}}>
                These numbers are not just statistics; they represent real lives
                touched, families forever changed, and dreams rekindled. Your
                support has been instrumental in bringing about this
                transformation.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center   mt-3">
            <div className="col-md-4">
              <img src={lwr3} className="img-fluid " />
            </div>
            <div className="col-md-2">
              <img src={lwr4} className="img-fluid" />
              <img src={lwr5} className="img-fluid" />
            </div>
            <div className="col-md-4">
              <img src={lwr6} className="img-fluid " />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-5 mb-3 text-center">
              <progress class="progress1 m-1" value="100" max="100"></progress>
              <progress class="progress2 m-1" value="50" max="50"></progress>
              <progress class="progress3 m-1" value="20" max="20"></progress>
              <progress class="progress4 m-1" value="10" max="10"></progress>
            </div>

          </div>
        </div>
      </section>


            <Footer />

        </>
    )
}

export default Yourimpact
