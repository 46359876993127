import React from 'react'
import logo from '../../assests/lwror.jpeg';
import line from '../../assests/Group 1870 line2.png';
import { Header } from "../Header";
import { Footer } from '../Footer';
import { useEffect } from "react";



function PhilipVideos() {

  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>

      <Header />
      <section className='mt-3 bg-light' style={{ minHeight: "100vh" }}>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-5' >PHILIP'S VIDEOS</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row d-flex justify-content-center align-items-center mt-3'>
            <div class='col-md-8 mt-3'>
              <div class='card shadow border-0 text-center rounded'>
                <div className='row justify-content-center'>
                  <div className='col-3'>
                    <img className='img-fluid mt-2' src={logo} />

                  </div>
                </div>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/2D-G46CPvNo" title="Philip Mathews speaking at PCNAK Miami 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark font-weight-bold">PHILIP SPEAKING AT PCNAK</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-3' >PHILIP'S LIFE STORY</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5'>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/AQldocL75bA?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="PHILIP MATHEWS - LIFESTORY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip’s<br/> Life Story</h4>
                </div>
              </div>
            </div>

            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/7Lxxv-tBsB8?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="700 Club Interactive  The Miracle of Life – December 10, 2015" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">700 Club Interactive The Miracle of Life</h4>
                </div>
              </div>
            </div>

          </div>
          
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-2' >PHILIP'S SPEAKING</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5 '>
          <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
              <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/58DF_DwCeac?si=5X36RJsScDoACqjt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen" ></iframe>
              <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold"> Smile Creators- Featuring Philip Mathews</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/Te-dWyuc3mI?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Love Without Reason, Philip Mathews - Motivational speaker" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip Mathews - Motivational speaker</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/PjEiHb49Djs?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip - Never Give Up" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip <br /> Never Give Up</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-2' >PHILIP'S SINGING</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5'>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/w_EoUHlYp7E?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip at Westview&#39;s got Talent - Singing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip at Westview's got Talent - Singing</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
              <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/RkzQtVJFNas?si=zSxDMs2z275wIjqn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip on Mother's Day - Love Without Reason</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/FJ7YKxD_KvQ?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Amazing grace - Philip Singing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Amazing grace <br/> Philip Singing</h4>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
          <section style={{ minHeight: "100vh", backgroundColor: " #1A1E27" }}>
        <div className="container ">
          <div className='row d-flex align-items-center' >
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-white fw-bold mb-0 mt-2' >PHILIP'S HOME GOING</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5 '>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/AQldocL75bA?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="PHILIP MATHEWS - LIFESTORY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip’s <br />Life Story</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/oK3cl69rBJU?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip Mathews  |  Memorial Service   |  10 - 25 -2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip Mathews  Memorial Service  10 - 25 -2019</h4>
                </div>
              </div>
            </div>

            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/wvPOIuDRadY?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip Mathews  |  Home Going  Service   |  10 - 26 -2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip Mathews  Home Going Service 10 - 26 -2019</h4>
                </div>
              </div>
            </div>

          </div>

          </div>
      </section>
      
      <Footer />
    </>
  )
}

export { PhilipVideos }