import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import human1 from "../../assests/fight-human-trafficking.png";
import human2 from "../../assests/what-we-do-holistic.png";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";

function Human() {
  const [urlToCopy, setURLToCopy] = useState("");
  const [ipInfo, setIpInfo] = useState({});

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=f667c2c73c3553');
        console.log("response", response);
        setIpInfo(response.data);
      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };

    fetchIpInfo();
  }, []);

  const handleCopy = () => {
    setURLToCopy("Copied");
  };

  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  // useEffect(() => {
  //   // Use setTimeout to clear the text after a specific duration
  //   const timer = setTimeout(() => {
  //     setURLToCopy("");
  //   }, displayDuration);

  //   // Clean up the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, [displayDuration, urlToCopy]);

  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  const [mailData, setMaildata] = useState({
    firstname: "",
    lastname: "",
    number: "",
    email: "",
    message: "",
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: "service_tt2kz7w",
      template_id: "template_d0upv0l",
      user_id: "ppekGscEBiOpBosKz",
      template_params: {
        firstname: mailData.firstname,
        lastname: mailData.lastname,
        number: mailData.number,
        email: mailData.email,
        message: mailData.message,
      },
    };
    axios
      .post(
        "https://api.emailjs.com/api/v1.0/email/send",
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        alert(res.data === "OK" ? "Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible." : "");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        alert("Failed to Submit");
        console.log(err);
      });
  };
  return (
    <>
      <Header />
      <section className="humanbg"> </section>

      <section style={{ minHeight: "70vh" }}>
        <div className="container mt-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 className="fw-bold mb-0 ">FIGHT HUMAN TRAFFICKING</h4>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>
          {/* <div className='col-md-5 mt-3'>
                            <img className="img-fluid rounded shadow-sm" src={human1} alt="Human" />
                        </div> */}

          {/* <div className='row d-flex justify-content-center'>
                        <div className='col-md-10 text-center mt-3'>
                            <p style={{fontSize:"20px"}}>Anti-human trafficking strategies use many approaches to confront the need for awareness of the growing crisis. Our founder's preventative initiatives include education and awareness of connections between congenital craniofacial differences and natural disasters.</p>
                            <p style={{fontSize:"20px"}}>The Project Butterfly initiative focuses on providing a sustainable recovery in Mumbai, India's red-light districts, providing an exit from a trafficking situation for women and children.</p>
                        </div>
                    </div> */}
          <div className="row mt-3 d-flex justify-content-center align-items-center mb-3">
            <div className="col-md-4">
              <img
                className="img-fluid rounded shadow-sm"
                src={human1}
                alt="Human"
              />
            </div>
            <div className="col-md-7 india-text">
              <p style={{ fontSize: "20px" }}>
                {" "}
                Anti-human trafficking strategies confront the need for
                awareness of the growing crisis. Our founder's preventative
                initiatives include education and awareness of connections
                between congenital craniofacial differences and human
                trafficking.{" "}
              </p>
              <p style={{ fontSize: "20px" }}>
                The Project Butterfly initiative focuses on providing a
                sustainable recovery in Mumbai, India's red-light districts,
                providing an exit from a trafficking situation for women and
                children.
              </p>

              <div className="d-flex justify-content-between mt-2 mb-2">
                <div className="col-md-2">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia14"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia14"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title india-text fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          FIGHT HUMAN TRAFFICKING
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-4">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/AntiHumanTrafficking"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/AntiHumanTrafficking"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/AntiHumanTrafficking"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/AntiHumanTrafficking"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>

                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/AntiHumanTrafficking"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  {ipInfo.country == "IN" ?
                    <Link to="/DonateIndia?reason=7" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>
                    : <Link to="#campaign-8483" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "70vh" }}>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 className="fw-bold mb-0  mt-3">PROJECT BUTTERFLY CENTER</h4>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
            {/* <div className='col-md-5 mt-3'>
                            <img className="img-fluid rounded" src={human2} alt="Human" />
                        </div> */}
          </div>
          <div className="row mt-3 d-flex justify-content-center align-items-center mb-3">
            <div className="col-md-4">
              <img className="img-fluid rounded" src={human2} alt="Human" />
            </div>
            <div className="col-md-7 india-text ">
              <p style={{ fontSize: "20px" }}>
                The Project Butterfly Center leaders themselves have come from
                an impoverished situation and now help others. Through their
                diligent work of breaking the cycle of slavery with leadership,
                literacy, building self-confidence, and then business skills, a
                sustainable recovery for the women and children in the red-light
                areas in Mumbai is provided.
              </p>
              <div className="d-flex justify-content-between mt-2 mb-2">
                <div className="col-md-2">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia13"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia13"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          PROJECT BUTTERFLY CENTER
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/AntiHumanTrafficking"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/AntiHumanTrafficking"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/AntiHumanTrafficking"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/AntiHumanTrafficking"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>

                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/AntiHumanTrafficking"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                            {/* <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  {ipInfo.country == "IN" ?
                    <Link to="/DonateIndia?reason=6" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>
                    : <Link to="#campaign-8483" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>}
                </div>
              </div>
            </div>
          </div>

          {/* <div className='row d-flex justify-content-center'>
                        <div className='col-md-10 text-center mt-3 text-white'>
                            <p style={{ fontSize: "20px" }}>The Project Butterfly Center leaders themselves have come from an impoverished situation and now help others. Through their diligent work of breaking the cycle of slavery with leadership, literacy, building self-confidence, and then business skills, a sustainable recovery for the women and children in the red-light areas in Mumbai is provided.</p>
                        </div>
                    </div> */}
        </div>
      </section>

      {/* <section style={{ minHeight: "100vh" }}>
                <div className="container  p-3">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 mb-2 text-center">
                            <h4 class=" text-dark fw-bold mb-0 mt-5">VOLUNTEER APPLICATION</h4>
                            <img className=" img-fluid " src={line} />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8">
                                    <form onSubmit={sendEmail} className="text-center">
                                        <div className="row mb-2 ">
                                            <div className="col-md-6">
                                                <input class="form-control" placeholder="FirstName" name="firstname" type="text" onChange={(e) => { userDetails(e) }} />
                                            </div>
                                            <div className="col-md-6">
                                                <input class="form-control" placeholder="Lastname" type="text" name="lastname" onChange={(e) => { userDetails(e) }} />
                                            </div>
                                        </div>
                                        <div className="row  mb-3">
                                            <div className="col-md-6" >
                                                <input class="form-control" placeholder="Phone number" type="number" name="number" onChange={(e) => { userDetails(e) }} />
                                            </div>
                                            <div className="col-md-6" >
                                                <input class="form-control" placeholder="Email" type="email" name="email" onChange={(e) => { userDetails(e) }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <textarea
                                                    class="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows="3"
                                                    placeholder="Message" onChange={(e) => { userDetails(e) }}
                                                    type="text" name="message"></textarea>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-center mt-2">
                                            <div className="col-md-12 text-center">
                                                <div className="d-flex">
                                                    <p className="text-dark m-1 text-start" >
                                                        Thank you for submitting your application,one of our Love
                                                        Without Reason staff will contact you in the next few
                                                        days.
                                                    </p>
                                                </div>
                                                <button className="btn btn-danger mt-3 fw-bold align-items-center px-3" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <Footer />
    </>
  );
}

export default Human;
