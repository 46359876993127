import React from "react";
import { useState } from 'react';
import { Header } from "../Header";
import { Footer } from "../Footer";
import dayposternewsletter from '../../assests/DayposteroctoberNewsletter.png';
import dayposterphilipimage from'../../assests/DayPhilipimage.png';
import { FaDownload } from 'react-icons/fa';


function Day() {
  
  return (
    <>
     
      <section style={{ minHeight: "100vh" }}>
        <div className="container  px-5 mt-5">
        <div className="row d-flex justify-content-center">
            <div className="col-md-4">
              <h4 className=" text-center border-bottom border-3 border-danger fw-bold mt-5">
              Love Without Reason Day 
              </h4></div>
          </div>

          <div className="row d-flex justify-content-center mt-2">
            <div className="col-md-12 text-justify">
            
              <p style={{ fontSize: "18px" }}>Recently, Tennessee’s Hamilton County Mayor, Weston Wamp, officially declared October 22nd as Love Without Reason Day. Mayor Wamp’s proclamation, which is attached, calls for the citizens of Hamilton County to embrace Philip’s spirit of generosity by showing kindness in their daily lives. </p>
              <p style={{ fontSize: "18px" }}>October 22nd holds deep significance as it marks the Heavenly anniversary of Philip Mathews, who passed away at age 19 in 2019. His life continues to inspire Love Without Reason’s mission, with every surgery performed and life touched.</p>
              <p style={{ fontSize: "18px" }}>As we commemorate Love Without Reason Day, we not only honor Philip’s memory but also invite the community to take part with one act of love at a time.</p>
              <p style={{ fontSize: "18px" }}>Here’s how you can participate on Love Without Reason Day:  </p>
              <p style={{ fontSize: "18px" }}><span className="text-dark fw-bolder  "> LOVE WITHOUT REASON:</span> Acts of love and kindness—whether deserved or not—embody the spirit of this special day. Take the time to love. </p>
              <p style={{ fontSize: "18px" }}><span className="text-dark fw-bolder  "> Give a Gift of Generosity:</span> Your gift of $10, $100, or $1,000 can prevent a child from being trafficked, and change their smile forever. Check our website <a href="https://lovewithoutreason.org/"className="text-danger" >www.lovewithoutreason.org</a> for more information.  </p>
              <p style={{ fontSize: "18px" }}><span className="text-dark fw-bolder  "> Share Your Experience:</span> Encourage others to “Love Without Reason,” by sharing on social media about the acts of love that you did. Tag us and use #LoveWithoutReasonDay.</p>
              <p style={{ fontSize: "18px" }}>Together, we are making the world a better place—one child, one surgery, and one act of kindness at a time. Your support allows us to continue our mission and bring smiles to the faces of children and families who need them the most.  </p>
              <p style={{ fontSize: "18px" }}>Thank you once again for your partnership.   </p>
              <p style={{ fontSize: "18px" }}>Until the World is Cleft Free, <br></br><span className='text-dark fw-bolder 'style={{ fontSize: "18px" }}>Santhosh and Susan Mathews </span> </p>
             
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col md-6 ms-5">
              <p className="text-center text-dark fw-bolder "style={{ fontSize: "18px" }}><a className="text-decoration-none text-dark fw-bolder border p-2 bg-danger text-white" href={dayposternewsletter} download>Click to Download  <FaDownload /></a></p>
            
            <a href={dayposternewsletter} download>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                
            }}>
            <img 
                style={{ 
                    width: "350px", 
                  
                }} 
                src={dayposternewsletter} 
                className="img-fluid text-center" 
                alt="October Newsletter Image" 
               
            />
            </div>
            </a>
            </div>
            <div className="col-md-6 text-start">
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                
            }}>
            <img 
                style={{ 
                    width: "350px", 
                    
                }} 
                src={dayposterphilipimage} 
                className="img-fluid" 
                alt="October Newsletter Image" 
                
            />
            </div>
            </div>
          </div>
        </div>
      </section>



      <Header />
      <Footer />
    </>
  );
}

export { Day };
