import React from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import lwr2 from "../../assests/lwrs4.png";
import lwr1 from "../../assests/LwRstory2.png";
import { useEffect, useState } from 'react';
import lwr3 from "../../assests/lwrs4.png";
import { Link } from 'react-router-dom';
import axios from "axios";

import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";


function Ourvalues() {

  const [urlToCopy, setURLToCopy] = useState("");
  const [ipInfo, setIpInfo] = useState({});

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=f667c2c73c3553');
        console.log("response", response);
        setIpInfo(response.data);
      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };

    fetchIpInfo();
  }, []);

  const handleCopy = () => {
    setURLToCopy("Copied");
  };

  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      <section id='values' style={{ minHeight: "80vh" }}>
        <div className="container">
          <div className="row d-flex mt-5">
            <div className="col-md-12 text-center mt-5">
              <h3 className="fw-bold text-dark mb-0">OUR VALUES</h3>
              <img src={line} className="img-fluid " />
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-4">
            <div className="col md-8 ms-5">
              <h4 className="fw-bold text-danger">RESPECT</h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                We are intentionally respectful of local culture but direct in
                our communication to empower new mindsets and life trajectories.
              </p>

              <h4 className="fw-bold text-danger">PREVENT</h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                Our collective effort results in a powerful tool in prevention,
                training, and creating new support networks in the areas we
                serve.
              </p>

              <h4 className="fw-bold text-danger">SUSTAIN</h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                Our organization's delivery design is to be sustainable, bring
                acceptance, and inspire community humanitarianism.
              </p>

              <div className="d-flex justify-content-between mt-2 mb-2">
                <div className="col-md-2 ">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia14"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia14"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title india-text fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          OUR VALUES
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-4">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourvalues"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourvalues"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourvalues"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/Ourvalues"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>

                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/Ourvalues"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  {ipInfo.country == "IN" ?
                    <Link to="/DonateIndia" target="_top">
                      <button class="btn btn-danger  ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>
                    : <Link to="#campaign-8483" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>}
                </div>
              </div>
            </div>



            <div className="col-md-4 text-start">
              <img className=" img-fluid " src={lwr1} />
            </div>
          </div>
        </div>
      </section>

      <section className='mb-5' id='values' style={{ minHeight: "80vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center mt-4">
            <div className="col-md-12 text-center mt-5 ">
              <h3 className="fw-bold text-dark mb-0">OUR MISSION STATEMENT</h3>
              <img src={line} className="img-fluid " />
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col-md-4 text-start">
              <img className=" img-fluid " src={lwr2} />
            </div>
            <div className="col md-8 ms-5">
              <h4 className="fw-bold text-danger mt-2">OUR VISION</h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                To fight human trafficking, to prevent it and to end it before it starts.
              </p>

              <h4 className="fw-bold text-danger mt-4">OUR MISSION</h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                Our dedicated mission of love is to build a foundation of self-respect and value for each human life.
              </p>
              <div className="d-flex justify-content-between mt-2 mb-2">
                <div className="col-md-2 ">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia14"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia14"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title india-text fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          OUR VALUES
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-4">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourvalues"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourvalues"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourvalues"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/Ourvalues"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>

                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/Ourvalues"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"> 
                  {ipInfo.country == "IN" ?
                    <Link to="/DonateIndia" target="_top">
                      <button class="btn btn-danger  ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>
                    : <Link to="#campaign-8483" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>}
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
      <Footer />

    </>
  );
}

export default Ourvalues;
