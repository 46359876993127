import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import hospital_1 from "../../assests/hospital_1.png";
import hospital_2 from "../../assests/hospital_2.png";

function UpcomingCamps() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  const [mailData, setMaildata] = useState({
    firstname: "",
    lastname: "",
    number: "",
    email: "",
    message: "",
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: "service_tt2kz7w",
      template_id: "template_d0upv0l",
      user_id: "ppekGscEBiOpBosKz",
      template_params: {
        firstname: mailData.firstname,
        lastname: mailData.lastname,
        number: mailData.number,
        email: mailData.email,
        message: mailData.message,
      },
    };
    axios
      .post(
        "https://api.emailjs.com/api/v1.0/email/send",
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    // .then((res) => {
    //   alert(res.data === "OK" ? "Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible." : "");
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    // })
    // .catch((err) => {
    //   alert("Failed to Submit");
    //   console.log(err);
    // });
  };
  return (
    <>
      <Header />
      <section
        className="camps1bg mt-5"
        style={{ minHeight: "100vh" }}
      ></section>

      <section style={{ minHeight: "50vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center px-5">
            <div className="col-md-12 mt-5 text-center">
              <h4 className="border-danger fw-bold mb-0">MEDICAL CAMPS</h4>
              <img className="img-fluid" src={line} />
              <p className="mt-4 india-text">
                Volunteers who attend the cleft lip and palate camps are
                instructed on the Love Without Reason Way. Every child and every
                parent attending these camps must be welcomed and loved by the
                entire team. From the screening on day one to the assigned day
                of surgery in the week following, the team places the health and
                safety of the child as the top priority. Discharge and cleaning
                instructions are demonstrated and given to each parent to ensure
                a smooth and successful recovery. The local teams will make
                plans for follow-up visits of the patients to provide
                encouragement and counsel.
              </p>
            </div>
          </div>

          {/* <div className="row d-flex justify-content-center">
            <div
              className="col-md-12 p-5  mt-3 rounded text-center"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <h4 className="text-uppercase border-bottom border-3 border-danger text-white fw-bolder">
                Cleft lip and palate camp in Nairobi, Kenya
              </h4>
              <h5 className="text-uppercase text-white fw-bolder">
                16 – 21 June 2024
              </h5>
              <p className="text-white mt-2">
                Team members from the USA, Singapore, South Africa, and various states of India will converge in Nairobi, Kenya, to bring an eternal impact to children and adults born with cleft lip and palate. Partner with us as we work Until The World is Cleft Free!
              </p>
              
            </div>
          </div> */}
        </div>
      </section>

      {/* <section className="mt-3" style={{ minHeight: "80vh" }}>
        <div
          className="container rounded text-center"
          style={{ backgroundColor: "#1A1E27" }}
        >
          <div className="row d-flex justify-content-center mb-4">
            <div className="col-md-12  rounded text-center  mb-5">
              <h4 class=" text-white fw-bolder mb-0 text-uppercase mt-4">
                UPCOMING CLEFT CAMPS
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 text-center mb-5">
                <img className="img-fluid" src={hospital_1} />
                <p className="text-white mt-2">
                  <span className="text-white fw-bolder">LOCATION :</span>AAR
                  Hospital Nairobi , Kenya.
                </p>
                <p className="text-white mt-2 mb-5">
                  <span className="text-white fw-bolder">DATE  :</span>17-21 January, April 2nd/3rd week
                </p>
              </div>

              <div className="col-md-6 text-center ">
                <img className="img-fluid" src={hospital_2} />
                <p className="text-white mt-2">
                  <span className="text-white fw-bolder">LOCATION :</span>Duncan Hospital
                  Raxual, Bihar, India.
                </p>
                <p className="text-white mt-2">
                  <span className="text-white fw-bolder">DATE        :</span>18-21 February
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section style={{ minHeight: "20vh" }}>
        <div
          className="container rounded text-center mt-3"
          style={{ backgroundColor: "#1A1E27" }}
        >
          <div className="row d-flex justify-content-center mb-4">
            <div className="col-md-9  rounded text-center mt-3 mb-5">
              <h4 className="text-white text-uppercase fw-bolder border-3 border-bottom border-danger">Leadership, Outreach and Conferences in Uganda and Kenya</h4>
              <h5 className="text-white">June 18 - July 03 </h5>
            </div>
          </div>
        </div>
      </section> */}

      <section style={{ minHeight: "40vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div
              className="col-md-10 p-5 rounded text-center mt-2 mb-5"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <h5 className="text-white">
                Do you want to volunteer for a medical camp?
              </h5>

              <p className="text-white mt-3">
                Do you want to volunteer for a medical camp? If you are a
                medical provider or a music teacher, no matter what your
                profession, you can volunteer for a medical camp. Background
                check, references, and CV may be requested. Please reach out to
                us at{" "}
                <span className="text-danger">info@lovewithoutreason.org</span>{" "}
                for more information.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "80vh" }}>
        <div className="container  p-3 rounded mt-3 border">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12  mt-5 text-center">
              <h4 class=" text-dark fw-bold mb-0">MEDICAL CAMP VOLUNTEERS</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <form onSubmit={sendEmail} className="text-center">
                    <div className="row mb-2 ">
                      <div className="col-md-6">
                        <input
                          class="form-control"
                          placeholder="First Name"
                          name="firstname"
                          type="text"
                          onChange={(e) => {
                            userDetails(e);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          class="form-control"
                          placeholder="Last Name"
                          type="text"
                          name="lastname"
                          onChange={(e) => {
                            userDetails(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row  mb-3">
                      <div className="col-md-6">
                        <input
                          class="form-control"
                          placeholder="Phone number"
                          type="number"
                          name="number"
                          onChange={(e) => {
                            userDetails(e);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          class="form-control"
                          placeholder="Email"
                          type="email"
                          name="email"
                          onChange={(e) => {
                            userDetails(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Message"
                          onChange={(e) => {
                            userDetails(e);
                          }}
                          type="text"
                          name="message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-12 text-center">
                        <div className="d-flex">
                          {/* <p className="text-dark m-1 text-start">
                            Thank you for submitting your application,one of our
                            Love Without Reason staff will contact you in the
                            next few days.
                          </p> */}
                        </div>
                        <button
                          className="btn btn-danger mt-3 mb-5 fw-bold align-items-center px-4"
                          type="submit"
                          data-bs-toggle="modal" data-bs-target="#exampleModalupcomingcamps"
                        >
                          Submit
                        </button>
                        <div class="modal fade" id="exampleModalupcomingcamps" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                              <div class="modal-body">
                                <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                <hr />

                                <div className="row d-flex justify-content-center">
                                  <div className="col-md-12 fs-5">
                                    Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer border-0">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default UpcomingCamps;
