import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import React from "react";
import axios from 'axios';
import paypal from '../../assests/donationpaypal.png';
import india1 from '../../assests/Donationindia1.png';
import india2 from '../../assests/Donationindia2.png';
import india3 from '../../assests/donationupiindia.png';
import { Header } from "../Header";
import { Footer } from "../Footer";


const PUBLIC_KEY = "pk_test_51OzecVSIT0ICxpHH3b0zNYAp0wbWILgoadK0zCrh0mIj9a5j9QbMCOFFTlXlVEyiNb9tnY2eq0Zhl9T9vw0dDzpi0023uX7aLn";

const stripeTestPromise = loadStripe(PUBLIC_KEY)

const DonationIndia = ({ surgeriesCounter, countryCount, campCount }) => {
  const location = useLocation();
  const [selectReason, setSelectReason] = useState('');

  // Extracting reason parameter from URL
  const searchParams = new URLSearchParams(location.search);
  const reasonParam = searchParams.get('reason');

  useEffect(() => {
    // Set the selected reason based on the reason parameter from URL
    if (reasonParam) {
      setSelectReason(reasonParam);
    }
  }, [reasonParam]);



  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed    
  }, []);
  // stripe logic
  const handleSubmit = async (e) => {
    e.preventDefault()
    const stripe = await stripeTestPromise;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: {
        number: cardnumber,
        exp_month: cardexpiry.split('/')[0],
        exp_year: cardexpiry.split('/')[1],
        cvc: cardcvc

      },
      billing_details: {
        email: email,
        phone: phoneNumber
      }
    })


    if (!error) {
      try {
        const { id } = paymentMethod
        const response = await axios.post("https://lovewithoutreason.org/payment",
          {
            amount: 1000,
            id
          })

        if (response.data.success) {
          console.log("Successful payment")
        }

      } catch (error) {
        console.log("Error", error)
      }
    } else {
      console.log(error.message)
    }
  }

  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (validateFields()) {
      setStep((prevStep) => {
        const newStep = prevStep + 1;
        console.log(newStep);
        return newStep;
      });
    } else {
      console.error('Please fill out all the required fields.');
      // You can also set an error state to display a message to the user
      // setError('Please fill out all the required fields.');
    }
  };



  const prevStep = () => {
    setStep((prevStep) => {
      const newStep = prevStep - 1;
      console.log(newStep);
      return newStep;
    });
  };


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectreason, setSelectreason] = useState('');
  const [selectonetime, setSelectonetime] = useState(false);
  const [selectmonthly, setSelectmonthly] = useState(false);
  const [selectsemiannual, setSelectsemiannual] = useState(false);
  const [selectannual, setSelectannual] = useState(false);
  // card
  const [cardnumber, setSelectcardnumber] = useState();
  const [cardexpiry, setSelectcardexpiry] = useState();
  const [cardcvc, setSelectcardcvc] = useState();
  // const [paymenttype, setpaymenttype] = useState();
  const [Line1, setLine1] = useState();
  const [Line2, setLine2] = useState();
  const [postal, setPostal] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [subcountry, setsubCountry] = useState("IN");
  const [paymenttype, setPaymenttype] = useState('once');
  const [money, setMoney] = useState();
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const showInput = () => {
    setIsInputVisible(true);
  };

  // useEffect to retrieve values from sessionStorage
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('firstName');
      sessionStorage.removeItem('lastName');
    };
  }, []);

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    switch (id) {
      case 'firstNameInput':
        setFirstName(value);
        break;
      case 'lastNameInput':
        setLastName(value);
        break;
      case 'Emailid':
        setEmail(value);
        break;
      case 'phonenumber':
        setPhoneNumber(value);
        break;
      case 'donortype':
        setSelectedOption(value);
        break;
      case 'reason':
        setSelectReason(value);
        break;
      default:
        break;
    }

    if (id === 'firstNameInput') {
      setFirstName(value);
      sessionStorage.setItem('firstName', value);
    } else if (id === 'lastNameInput') {
      setLastName(value);
      sessionStorage.setItem('lastName', value);
    } else if (id === "phonenumber") {
      setPhoneNumber(value);
      sessionStorage.setItem('phoneNumber', value);
    } else if (id === "Emailid") {
      setEmail(value);
      sessionStorage.setItem('email', value);
    } else if (id === "donortype") {
      setSelectedOption(value);
      sessionStorage.setItem('selectedOption', value);
    } else if (id === "reason") {
      setSelectReason(value);
      sessionStorage.setItem('selectReason', value);
    }
  };

  const [country, setCountry] = useState('in');
  const [selectedButton, setSelectedButton] = useState(null);
  // const [money, setMoney] = useState(50);
  // const [money, setMoney] = useState('50');
  console.log(money, "money")
  const handleButtonClick = (amount) => {
    setSelectedButton(amount);
  };
  const validateFields = () => {
    if (!firstName || !lastName || !email || !phoneNumber || !selectedOption || !selectReason || !money) {
      return false;
    }
    return true;
  };


  const subscribe = async (e) => {
    e.preventDefault()
    console.log("hi");
    const stripe = loadStripe('pk_test_51OzecVSIT0ICxpHH3b0zNYAp0wbWILgoadK0zCrh0mIj9a5j9QbMCOFFTlXlVEyiNb9tnY2eq0Zhl9T9vw0dDzpi0023uX7aLn')
    const amount = parseInt(money);
    try {

      axios.post("https://lovewithoutreason.org/create-checkout-session", {
        name: firstName,
        email,
        paymentMethod: 'card',
        amount: amount * 100,
        money,
        address: { city: city, postal_code: postal, line1: Line1, line2: Line2, country: subcountry, state: state },
        paymenttype: paymenttype

      }).then(async (res) => {
        console.log("res", res);
        window.open(res.data.url, "_blank");
        const confirm = await stripe.confirmCardPayment(res.data.clientSecret);
        if (confirm.error) return alert("Payment unsuccessful!");
        alert("Payment Successful! Subscription active.");
      }).catch((err) => {
        console.log(err);
      })

    } catch (err) {
      console.error(err);
      alert("Payment failed! " + err.message);
    }
    setClicked(true);
  };

  return (
    <>
      <Header />
      <section className='Donation-india mt-5'>

        <div className='container'>
          <div className='row d-flex justify-content-start pt-3 pb-2'>
            <div className='col-md-5 p-3 bg-white text-center shadow rounded mt-2 '>
              {/* form-1 */}
              {step === 1 && (
                <form className="" >
                  <div className='row d-flex justify-content-center'>
                    <div className='col-md-8'>
                      <h5>Donate to <span className='text-danger fw-bolder'>Love Without Reason</span> </h5>
                      <p className='border border-2 rounded border-danger'></p>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>
                    <div className='col-md-6 text-start'>
                      {/* <h6 className=''>First name</h6> */}
                      <input
                        id="firstNameInput"
                        placeholder="First Name"
                        className="form-control form-control-sm mt-2"
                        type="text"
                        value={firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className='col-md-6 text-start'>
                      {/* <h6 className=''>Last name</h6> */}
                      <input
                        id="lastNameInput"
                        placeholder="Last Name"
                        className="form-control form-control-sm mt-2"
                        type="text"
                        value={lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>
                    <div className='col-lg-6 col-md-6 mt-2'>
                      {/* <h6 className='text-start'>Email</h6> */}
                      <input className='form-control form-control-sm' placeholder="Email" type='email'
                        id='Emailid'
                        value={email} onChange={handleInputChange} required />
                    </div>
                    <div className='col-lg-6 col-md-6 mt-2'>
                      {/* <h6 className='text-start'>Phone number</h6> */}
                      <input className='form-control form-control-sm' placeholder="Phone Number" type='text'
                        id='phonenumber'
                        value={phoneNumber}
                        onChange={handleInputChange} required />
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>

                  </div>
                  <div className='row d-flex justify-content-center mt-2'>
                    <div className='col-lg-6 col-sm-12'>
                      {/* <h6 className='text-start'>Donor type</h6> */}
                      <select
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                        id="donortype"
                        value={selectedOption}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>Donor Type</option>
                        <option value="1">Individual</option>
                        <option value="2">Organization</option>
                      </select>

                    </div>
                    <div className='col-lg-6 col-sm-12'>
                      {/* <h6 className='text-start'>Reason for Donation</h6> */}
                      <select
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                        id='reason'
                        value={selectReason}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>Reason for Donation</option>
                        <option value="1">Area of Greatest Need</option>
                        <option value="2">Cleft-Free World</option>
                        <option value="3">Director's Innovative Fund</option>
                        <option value="4">Father-Daughter Dance</option>
                        <option value="5">Give In Honor Of Philip</option>
                        <option value="6">Project Butterfly</option>
                        <option value="7">Anti-Human Trafficking</option>
                        <option value="8">The Joy of Smile</option>
                      </select>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>

                  </div>

                  <div className='row d-flex justify-content-center mt-2'>
                    <div className='col'>
                      <h6 className='fw-bolder'>Donation Type</h6>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check form-check-inline form-check-sm">
                        <input
                          checked={paymenttype === 'once'}
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="once"
                          onClick={(e) => setPaymenttype(e.target.value)}
                        />
                        <label className="form-check-label m-1" htmlFor="inlineRadio1">
                          Give Once
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="monthly"
                          onClick={(e) => setPaymenttype(e.target.value)}
                        />
                        <label className="form-check-label m-1" htmlFor="inlineRadio2">
                          Monthly
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='row d-flex justify-content-center'>
                    <div className='col'>
                      <h6 className='fw-bolder'>Donation Amount</h6>
                    </div>
                  </div>

                  {paymenttype === 'once' && (
                    <div>
                      {/* Give Once Amount */}
                      <div className="row d-flex justify-content-evenly">

                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 500 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(500)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 500
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 1000 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(1000)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 1000
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 2000 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(2000)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 2000
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 5000 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(5000)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 5000
                          </button>
                        </div>
                        <div className="col-md-3">
                          {!isInputVisible && (
                            <button className="btn btn-outline-danger m-1 w-100 p-1 fw-bold" onClick={showInput}>
                              Other
                            </button>
                          )}
                          {isInputVisible && (
                            <input
                              type="number"
                              className="form-control m-1 w-100 p-1"
                              placeholder="Enter amount"
                              onChange={(e) => setMoney(e.target.value)}
                            />
                          )}
                        </div>
                      </div>

                    </div>
                  )}

                  {paymenttype === 'monthly' && (
                    <div>
                      {/* Monthly Amount */}
                      <div className="row d-flex justify-content-evenly">
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 500 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(500)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 500
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 1000 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(1000)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 1000
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 2000 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(2000)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 2000
                          </button>
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            className={`btn ${money === 5000 ? 'btn-danger' : 'btn-outline-danger'} m-1 w-100 p-1`}
                            onClick={() => setMoney(5000)}
                          >
                            <i className="fa-solid fa-indian-rupee-sign"></i> 5000
                          </button>
                        </div>
                        <div className="col-md-3">
                          {!isInputVisible && (
                            <button className="btn btn-outline-danger m-1 w-100 p-1 fw-bold" onClick={showInput}>
                              Other
                            </button>
                          )}
                          {isInputVisible && (
                            <input
                              type="number"
                              className="form-control m-1 w-100 p-1"
                              placeholder="Enter amount"
                              onChange={(e) => setMoney(e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='row d-flex justify-content-center'>
                    <div className='col-md-5'>
                      <button className='btn btn-danger px-5 p-1 mt-2' onClick={nextStep}>Next</button>
                    </div>
                  </div>
                </form>
              )}

              {/* form-2 */}
              {step === 2 && (

                <div className="p-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="line1" className="form-label">Address Line 1:</label>
                        <input type="text" className="form-control" id="line1" onChange={(e) => { setLine1(e.target.value) }} name="line1" required />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="line2" className="form-label">Address Line 2:</label>
                        <input type="text" className="form-control" id="line2" onChange={(e) => { setLine2(e.target.value) }} name="line2" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label">City:</label>
                        <input type="text" className="form-control" id="city" onChange={(e) => { setCity(e.target.value) }} name="city" required />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="state" className="form-label">State:</label>
                        <input type="text" className="form-control" onChange={(e) => { setState(e.target.value) }} id="state" name="state" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mb-3">
                      <label htmlFor="postal_code" className="form-label">Postal Code:</label>
                      <input type="text" className="form-control" id="postal_code" onChange={(e) => { setPostal(e.target.value) }} name="postal_code" required />
                    </div>
                    {selectedOption === "2" && (
                      <div className="col mb-3">
                        <label htmlFor="org_name" className="form-label">Organization Name:</label>
                        <input type="text" className="form-control" id="org_name" name="org_name" required />
                      </div>
                    )}
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-4 mt-2">
                      <button className='btn btn-outline-danger p-2 fw-bolder w-75 text-dark ' onClick={prevStep}>Back</button>
                    </div>
                    <div className="col-md-4 mt-2">
                      <button onClick={subscribe} className={`btn btn-danger  p-2 fw-bolder${clicked ? ' disabled' : ''}`} disabled={clicked}>{paymenttype == 'once' ? "Donate Now" : "Subscribe"}</button>
                    </div>
                  </div>
                </div>

              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="row d-flex justify-content-center">
                <div className="col-md-5 text-center">
                  <img className="img-fluid mb-5" src={india3} />
                </div>
                <div className="col-md-5 text-center">
                  <Link to="https://www.paypal.com/donate/?hosted_button_id=CHDYF6D2KDTVQ" target="_blank"><img className="img-fluid rounded mb-5" src={paypal} /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <section>
        <div className='container-fluid mb-4'>
          <div className='row d-flex justify-content-center mt-5'>
            <div className='col-md-5'>
              <progress class="progress1" value="100" max="100"></progress>
              <progress class="progress2 ms-1" value="50" max="50"></progress>
              <progress class="progress3 ms-1" value="20" max="20"></progress>
              <progress class="progress4 ms-1" value="10" max="10"></progress>
              <h5 className='fw-bolder'>IMPACT OF YOUR DONATION</h5>
              <p style={{ fontSize: '20px' }}>These numbers are not just statistics they represent real lives touched,families forever changed,and dreams rekindled. Your Support
                has been instrumental in bringing about this transformation.</p>
              <img src={india1} className='img-fluid' />
            </div>
            <div className='col-md-5'>
              <img src={india2} className='img-fluid' />
            </div>
          </div>
          <div className='row d-flex justify-content-center bg-danger p-4 text-center text-white mt-3'>
            <div className='col-md-3'>
              <h2 className='fw-bolder'>{countryCount}</h2>
              <h4 className='fw-bolder'>COUNTRIES</h4>
            </div>
            <div className='col-md-3'>
              <h2 className='fw-bolder'>600+</h2>
              <h4 className='fw-bolder'>VOLUNTEERS</h4>
            </div>
            <div className='col-md-3'>
              <h2 className='fw-bolder'>{campCount}</h2>
              <h4 className='fw-bolder'>CAMPS</h4>
            </div>
            <div className='col-md-3'>
              <h2 className='fw-bolder'>{surgeriesCounter}</h2>
              <h4 className='fw-bolder'>SURGERIES</h4>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
};


export default DonationIndia;
