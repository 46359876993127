import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Home } from "./Application/Home";
import { India } from "./Application/India";
import { Kenya } from "./Application/Kenya";
import { Zimbabwe } from "./Application/Zimbabwe";
import { Usa } from "./Application/Usa";
import { Uganda } from "./Application/uganda";
import { Africa } from "./Application/africa";
import { Philip } from "./Application/philip";
import { Founder } from "./Application/Founder";
import LWRstory from "./Application/LWRstory";
import { Director } from "./Application/Director";
import GlobalTeam from "./Application/Globalteam";
import { Volunteers } from "./Application/Volunteers";
import { Student } from "./Application/student";
import { Susanblog_2 } from "./Application/susanblog2";
import { Sponsor } from "./Application/Become a Sponser";
import { Eventfather } from "./Application/Eventfather";
import { Eventpeople } from "./Application/Eventpeople";
import Craniofacial from "./Application/Craniofacial";
import { Event5krun } from "./Application/Event5krun";
import Human from "./Application/HumanTrafficking";
import { Vehicle } from "./Application/Vehicledonate";
import Ourvalues from "./Application/ourvalues";
import Yourimpact from "./Application/yourimpact";
import Contact from "./Application/Contactus";
import { PersonalProperty } from "./Application/PersonalProperty";
import { Plannedgift } from "./Application/PlannedGifts";
import Inkind from "./Application/Inkind";
import { Video } from "./Application/Video";
import { Press } from "./Application/Press";
import { Press2 } from "./Application/Press2";
import { Susan } from "./Application/Susanblogs";
import Innovative from "./Application/Innovativesolutions";
import UpcomingCamps from "./Application/UpcomingCamps";
import { Events } from "./Application/Events";
import { Leader } from "./Application/Usa Lerder";
import Ourmission from "./Application/ourmission";
import OurStrategy from "./Application/OurStrategy";
import MediacalCamps from "./Application/MediacalCamps";
import { PrivacyPolicy } from "./Application/PrivacyPolicy";
import { PhilipVideos } from "./Application/PhilipVideos";
import { Press5k } from './Application/press5k';
import SmilewithSanta from './Application/SmilewithSanta';
import Newsletters from './Application/Newsletters';
import Donations from './Application/DonationIndia';
import DonationUsa from './Application/DonationUsa';
import Event5kSponsorship from './Application/Event5kSponsorship';
import FatherDaughterSponser from './Application/FatherDaughterSponser';
import { Day } from './Application/Day';
import FatherDaughterDanceImagesNew from './Application/FatherDaughterDanceGallery';


function App() {
  const [surgeriesCounter, setSurgeriesCounter] = useState(1073);
  const [countryCount, setCountryCount] = useState(7);
  const [campCount, setCampCount] = useState(75);
  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://ipinfo.io?token=f667c2c73c3553');
        const data = await response.json();
        setUserCountry(data.country);
      } catch (error) {
        console.error('Error fetching user country:', error);
        // Handle error
      }
    };

    fetchCountry();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home surgeriesCounter={surgeriesCounter}
            countryCount={countryCount}
            campCount={campCount} />} />
          <Route path="Home" element={<Home surgeriesCounter={surgeriesCounter}
            countryCount={countryCount}
            campCount={campCount} />} />
          <Route path="India" element={<India />} />
          <Route path="Kenya" element={<Kenya />} />
          <Route path="Zimbabwe" element={<Zimbabwe />} />
          <Route path="USA" element={<Usa />} />
          <Route path="Uganda" element={<Uganda />} />
          <Route path="SouthAfrica" element={<Africa />} />
          <Route path="philip" element={<Philip />} />
          <Route path="Founders" element={<Founder />} />
          <Route path="About Us" element={<LWRstory />} />
          <Route path="OurMission" element={<Ourmission />} />
          <Route path="Director" element={<Director />} />
          <Route path="GlobalTeams" element={<GlobalTeam />} />
          <Route path="volunteers" element={<Volunteers />} />
          <Route path="UpcomingCamps" element={<UpcomingCamps />} />
          <Route path="EventVolunteers" element={<Volunteers />} />
          <Route path="MedicalCamps" element={<MediacalCamps />} />
          <Route path="susanblog_2" element={<Susanblog_2 />} />
          <Route path="susan" element={<Susan />} />
          <Route path="CorporateSponsors" element={<Sponsor />} />
          <Route path="PeopleNotProducts" element={<Eventpeople />} />
          <Route path="ExecutiveBoardOfDirectors" element={<Director />} />
          <Route path="GlobalTeams" element={<GlobalTeam />} />
          <Route path="volunteers" element={<Volunteers />} />
          <Route path="StudentIntern" element={<Student />} />
          <Route path="susanblog_2" element={<Susanblog_2 />} />
          <Route path="sponsor" element={<Sponsor />} />
          <Route path="Craniofacial" element={<Craniofacial />} />
          <Route path="OurStrategy" element={<OurStrategy />} />
          <Route path="FatherDaughterDance" element={<Eventfather />} />
          <Route path="Dance" element={<Eventfather />} />
          <Route path="CraniofacialSurgeries" element={<Craniofacial />} />
          <Route path="5kRun4Love" element={<Event5krun />} />
          <Route path="PersonalProperty" element={<PersonalProperty />} />
          <Route path="OurValues" element={<Ourvalues />} />
          <Route path="YourImpact" element={<Yourimpact />} />
          <Route path="ContactUS" element={<Contact />} />
          <Route path="LegacyPlannedGifts" element={<Plannedgift />} />
          <Route path="event5krun" element={<Event5krun />} />
          <Route path="AntiHumanTrafficking" element={<Human />} />
          <Route path="VehicleDonation" element={<Vehicle />} />
          <Route path="yourimpact" element={<Yourimpact />} />
          <Route path="contactus" element={<Contact />} />
          <Route path="Inkind" element={<Inkind />} />
          <Route path="Videos" element={<Video />} />
          <Route path="PressRelease" element={<Press />} />
          <Route path="Press2" element={<Press2 />} />
          <Route path="InnovativeSolutions" element={<Innovative />} />
          <Route path="Events" element={<Events />} />
          <Route path="USALeadershipCommittee" element={<Leader />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="PhilipVideos" element={<PhilipVideos />} />
          <Route path="Press5k" element={<Press5k />} />
          <Route path="SmileWithSanta" element={<SmilewithSanta />} />
          <Route path="Newsletters" element={<Newsletters />} />
          <Route path="Event5kSponsorship" element={<Event5kSponsorship />} />
          <Route path="FatherDaughterSponser" element={<FatherDaughterSponser />} />
          <Route path="Day" element={<Day />} />
          <Route path="FatherDaughterDanceGallery" element={<FatherDaughterDanceImagesNew />} />
          <Route path="/DonateIndia" element={<Donations surgeriesCounter={surgeriesCounter}
            countryCount={countryCount}
            campCount={campCount} />} />

          <Route path="/Donate" element={<DonationUsa surgeriesCounter={surgeriesCounter}
            countryCount={countryCount}
            campCount={campCount} />} />
        </Routes >

      </Router >
    </>
  );
}

export default App;
