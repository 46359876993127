
import React from 'react';
import { useState } from 'react';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

import { Link } from "react-router-dom";
const initialMailData = {
  firstname: '',
  lastname: '',
  number: '',
  email: '',
  checkbox: [], // Use an array to store checkbox values
  message: '',
};

function Inkind() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  const [mailData, setMailData] = useState(initialMailData);


  const userDetails = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const updatedCheckboxes = checked
        ? [...mailData.checkbox, value] // Add the value if checked
        : mailData.checkbox.filter((item) => item !== value); // Remove the value if unchecked

      setMailData({
        ...mailData,
        checkbox: updatedCheckboxes,
      });
      console.log(mailData, "e");
    } else {
      setMailData({
        ...mailData,
        [name]: value,
      });
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        firstname: mailData.firstname,
        lastname: mailData.lastname,
        number: mailData.number,
        email: mailData.email,
        checkbox: mailData.checkbox.join(', '), // Join selected checkboxes as a comma-separated string
        message: mailData.message,
      },
    };

    axios

      .post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    //   setMailData(initialMailData);
    // })
    // .catch((err) => {
    //   alert('Failed to Submit');
    //   console.log(err);
    // });
  };

  return (

    <>

      <Header />
      <section className='inkind'></section>


      <section className='border-bottom border-3 shadow-sm pb-3' style={{ minHeight: '90vh' }}>
        <div className='container shadow-sm'>
          <div className="row  d-flex justify-content-between mt-5">
            <div className="col-md-4 p-3">
              <h4 className="border-bottom w-75 border-3 border-danger fw-bold">
                MEDICAL SUPPLIES
              </h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12 text-center'>
              <p style={{ fontSize: "18px" }}>We accept brand-new medical supplies and equipment, with expiration date visible.Items that Love Without Reason Medical Camps need:</p>
            </div>
          </div>
          <div className='row d-flex justify-content-center mt-3'>
            <div className='col-md-8 col-sm-12 mt-3'>
              <h4 className="fw-bold">
                DONATE MEDICAL SUPPLIES
              </h4>
              <form className="text-center " onSubmit={sendEmail} >
                <div className="row mb-2 ">
                  <div className="col-md-6">
                    <input
                      class="form-control"
                      placeholder="First Name"
                      name="firstname"
                      type="text" onChange={(e) => { userDetails(e) }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      class="form-control"
                      placeholder="Last Name"
                      type="text"
                      name="lastname" onChange={(e) => { userDetails(e) }}
                    />
                  </div>
                </div>
                <div className="row  mb-3">
                  <div className="col-md-6">
                    <input
                      class="form-control"
                      placeholder="Phone number"
                      type="number"
                      name="number" onChange={(e) => { userDetails(e) }}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      class="form-control"
                      placeholder="Email"
                      type="email"
                      name="email" onChange={(e) => { userDetails(e) }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='text-start col-md-6 col-sm-12 ' style={{ fontSize: '12px' }}>
                    <ul>
                      <li><input class="form-check-input m-1" name="checkbox" value="Abgel" onChange={(e) => userDetails(e)} type="checkbox" id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Abgel
                        </label>
                      </li>

                      <li>
                        <input class="form-check-input m-1" name="checkbox" value="Ambu bags adult,Infant & pediatric" onChange={(e) => userDetails(e)} type="checkbox" id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Ambu bags adult,Infant & pediatric
                        </label>
                      </li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Bovie Pencils" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Bovie Pencils
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value=" Bovie needle tips w/Insulated needle" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Bovie needle tips w/Insulated needle
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Bovie Grounding Pads" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Bovie Grounding Pads
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Colorado Dissection Tips" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Colorado Dissection Tips
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="EKG Pads" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          EKG Pads
                        </label></li>

                      <li> <input class="form-check-input m-1" type="checkbox" name="checkbox" value="IV Catheters (20,22, & 24 gauge)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          IV Catheters (20,22, & 24 gauge)
                        </label></li>

                      <li> <input class="form-check-input m-1" type="checkbox" name="checkbox" value=" IV Tubing (15 drop & 60 drop, with atleast 60 tubing and injection ports)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          IV Tubing
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Oral RAE Tubes (sizes 3.5-7)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Oral RAE Tubes (sizes 3.5-7)
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className='text-start col-md-6 col-sm-12 ' style={{ fontSize: '12px' }}>
                    <ul>
                      <li>  <input class="form-check-input m-1" type="checkbox" name="checkbox" value="Medications (contact us for specifics)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Medications (contact us for specifics)
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Nasopharyngeal Airways" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Nasopharyngeal Airways
                        </label></li>

                      <li>  <input class="form-check-input m-1" type="checkbox" name="checkbox" value="Silicone Catheters (6Fr, 7Fr, 8Fr)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Silicone Catheters (6Fr, 7Fr, 8Fr)
                        </label></li>

                      <li> <input class="form-check-input m-1" type="checkbox" name="checkbox" value="Suction Catheters - 8Fr, 10Fr,12Fr,14Fr" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Suction Catheters - 8Fr, 10Fr,12Fr,14Fr
                        </label></li>

                      <li> <input class="form-check-input m-1" type="checkbox" name="checkbox" value="Surgical Blades (sizes 11, 12, & 15)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Surgical Blades (sizes 11, 12, & 15)
                        </label></li>

                      <li> <input class="form-check-input m-1" type="checkbox" name="checkbox" value="Sutures (contact us for specifics)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Sutures (contact us for specifics)
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Surgical Gloves (sterile 6.5 to 7.5)" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Surgical Gloves (sterile 6.5 to 7.5)
                        </label></li>

                      <li><input class="form-check-input m-1" type="checkbox" name="checkbox" value="Syringes with Luer Lock Tips" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Syringes with Luer Lock Tips
                        </label></li>

                      <li>              <input class="form-check-input m-1" type="checkbox" name="checkbox" value=" Tape" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Tape
                        </label></li>

                      <li>
                        <input class="form-check-input m-1" type="checkbox" name="checkbox" value=" Tegaderm Dressing" onChange={(e) => userDetails(e)} id="flexCheckDefault" />
                        <label class="form-check-label m-1" for="flexCheckDefault">
                          Tegaderm Dressing
                        </label>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Message"
                      type="text"
                      name="message" onChange={(e) => { userDetails(e) }}
                    ></textarea>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mt-2">
                  <div className="col-md-12 text-center">
                    <div className="d-flex">
                      {/* <p className="text-dark m-1 text-start">
                        Thank you for submitting your application,one
                        of our Love Without Reason staff will contact
                        you in the next few days.
                      </p> */}
                    </div>
                    <button
                      className="btn btn-danger mt-3 fw-bold align-items-center px-3 mb-3"
                      type="submit"
                      data-bs-toggle="modal" data-bs-target="#exampleModalinkind"
                    >
                      Submit
                    </button>
                    <div class="modal fade" id="exampleModalinkind" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                          <div class="modal-body">
                            <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                            <hr />

                            <div className="row d-flex justify-content-center">
                              <div className="col-md-12 fs-5">
                                Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer border-0">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='container p-3'>
          <div className="row  d-flex justify-content-between mt-5">
            <div className="col-md-3 p-3">
              <h4 className="border-bottom w-75 border-3 border-danger fw-bold">
                GOOD/SERVICES
              </h4>
            </div>



            <div className="col-md-3 text-center">
              <button className="btn btn-danger fw-bold" data-bs-toggle="modal" data-bs-target="#exampleModal2">CONTACT US</button>
            </div>
          </div>

          <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title fw-bold" id="exampleModalLabel">Good/Services</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div className="row d-flex justify-content-center">

                    <div className="col-md-12 px-5">
                      <form onSubmit={sendEmail} className="text-center">
                        <div className="row mb-2 ">
                          <div className="col-md-6">
                            <input class="form-control" placeholder="First Name" name="firstname" type="text" onChange={(e) => { userDetails(e) }} />
                          </div>
                          <div className="col-md-6">
                            <input class="form-control" placeholder="Last Name" type="text" name="lastname" onChange={(e) => { userDetails(e) }} />
                          </div>
                        </div>
                        <div className="row  mb-3">
                          <div className="col-md-6" >
                            <input class="form-control" placeholder="Phone number" type="number" name="number" onChange={(e) => { userDetails(e) }} />
                          </div>
                          <div className="col-md-6" >
                            <input class="form-control" placeholder="Email" type="email" name="email" onChange={(e) => { userDetails(e) }} />
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12">
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="Message"
                              type="text" name="message" onChange={(e) => { userDetails(e) }}></textarea>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                          <div className="col-md-12 text-center">
                            <div className="d-flex">

                            </div>
                            <button className="btn btn-danger mt-3 fw-bold align-items-center px-3 mb-5" type="submit" data-bs-toggle="modal" data-bs-target="#exampleModalinkind1">Submit</button>
                            <div class="modal fade" id="exampleModalinkind1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                  <div class="modal-body">
                                    <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                    <hr />

                                    <div className="row d-flex justify-content-center">
                                      <div className="col-md-12 fs-5">
                                        Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer border-0">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-2"></div>
                  </div>

                </div>
                {/* <div class="modal-footer border-0">
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-md-12 mt-3 px-5'>
              <p style={{ fontSize: "18px" }}>Are you interested in donating your printing services? Do you want to provide breakfast for the volunteers of the 5k Run4Love this september ? Interested in donating your skills and time for a project?</p>
              <p style={{ fontSize: "18px" }}>Reach out to our team for more information on how you can contribute specific goods or services to Love Without Reason.</p>
            </div>
          </div>
        </div>

      </section>


      <Footer />
    </>
  )
}

export default Inkind
