import React from 'react';
import footerlogo from "../../assests/LWRblue.png";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const [userLocation, setUserLocation] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    async function fetchUserLocation() {
      try {
        const response = await fetch('https://ipinfo.io?token=f667c2c73c3553');
        const data = await response.json();
        setUserLocation(data.country);
      } catch (error) {
        console.error('Error fetching user location:', error);
        // Handle error if needed
      }
    }

    fetchUserLocation();
  }, []);
  useEffect(() => {
    // Update the current year when the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []);
  return (
    <>

      <section className='' style={{ minHeight: "60vh" }}>

        <div className='container-fluid  linear-gradientfooter'>
          <div className='row d-flex justify-content-center  align-items-centerpt-3 '>

            <div className='col-md-4 mt-3 me-5 pt-5  text-center mb-4'>
              <img className='img-fluid p-3 ' src={footerlogo} />
              <div className='mt-3 '>
                <a href="https://www.facebook.com/LoveWithoutReasonUSA/" target="_blank"><i class=" fa-brands fa-facebook border p-2 rounded-circle text-white"></i></a>
                <a href="https://twitter.com/LWRUSA/" target="_blank"><i class="m-1 fa-brands fa-x-twitter border p-2 rounded-circle text-white"></i></a>
                <a href="https://www.linkedin.com/company/lovewithoutreason/" target="_blank"><i class="m-1 fa-brands fa-linkedin linkedinfooter border p-2 rounded-circle text-white"></i></a>
                <a href="https://www.instagram.com/lovewithoutreasoninc/" target="_blank"><i class=" m-1 fa-brands fa-instagram border p-2 rounded-circle text-white"></i></a>
                <a href="https://www.youtube.com/LoveWithoutReason/" target="_blank"><i class=" fa-brands fa-youtube border  p-2 rounded-circle text-white"></i></a>
              </div>
            </div>
            <div className='col-md-3 mt-5'>
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <h5 className='fw-bolder text-white mx-4'> Quick Links
                  </h5>
                </li>

                <li className="ms-4 mt-3">
                  <Link to='/CraniofacialSurgeries' className="text text-white text-decoration-none">
                    <i className="fa-solid fa-caret-right text-danger"></i>&nbsp;Craniofacial Surgery
                  </Link>
                </li>
                <br />

                <li className='ms-4'>
                  <Link to="/AntiHumanTrafficking" className="text text-white text-decoration-none">
                    <i className="fa-solid fa-caret-right text-danger "></i>&nbsp;Human Trafficking
                  </Link>
                </li>
                <br />
                <li className="ms-4">
                  <Link to="/PressRelease" className="text text-white text-decoration-none">
                    <i className="fa-solid fa-caret-right text-danger"></i>&nbsp;Press Release
                  </Link>
                </li>
                <br />

                <li className="ms-4">
                  <Link to="/contactus" className="text text-white text-decoration-none">
                    <i className="fa-solid fa-caret-right text-danger"></i>&nbsp;Contact Us
                  </Link>
                </li>
                <br />

                <li className='ms-4'>
                  <Link to="/PrivacyPolicy" className="text text-white text-decoration-none">
                    <i className="fa-solid fa-caret-right text-danger "></i>&nbsp;Privacy Policy
                  </Link>
                </li>
                <br />

                <li className='ms-4'>
                  <Link to="/InnovativeSolutions" className="text text-white text-decoration-none">
                    <i className="fa-solid fa-caret-right text-danger "></i>&nbsp;Innovative solutions
                  </Link>
                </li>


                {/* <li className=''>
                  <h5 className='fw-bolder mt-5 text-white'>Innovative solutions</h5>
                </li> */}



              </ul>

            </div>


            {userLocation === 'IN' && (
              <div className='col-md-3 mt-5'>
                {/* India Address */}
                <h5 className='fw-bolder text-white'>Reach Out</h5>
                <p className='text-white'>+1(423) 402 0607<br />
                  <span className='  text-white '>Contact@lovewithoutreason.org</span>
                </p><br />
                <h5 className='fw-bolder text-white'>India Office:</h5>
                <p className='text-white'>
                  No 14, Bharathiyar Nagar, K.Vadamadurai, Coimbatore -641017
                </p><br />
                <h5 className='text-white mt-3 fw-bolder'>Mailing Address:</h5>
                <p className='text-white'>P.O. Box @India</p>
              </div>
            )}

            {userLocation === 'KE' && (
              <div className='col-md-3 mt-5'>
                {/* Kenya Address */}
                <h5 className='fw-bolder text-white'>Reach Out</h5>
                <p className='text-white'>+(254) 0791899855<br />
                  <span className='  text-white '>lwrkenya@lovewithoutreason.org</span>
                </p><br />
                <h5 className='fw-bolder text-white'>Kenya Office:</h5>
                <p className='text-white'>
                  Fika Citi Mall ,Sheikh Karume street
                </p><br />
                <h5 className='text-white mt-3 fw-bolder'>Mailing Address:</h5>
                <p className='text-white'>P.O. Box @Kenya</p>
              </div>
            )}

            {(userLocation !== 'IN' && userLocation !== 'KE') && (
              <div className='col-md-3 mt-5'>
                {/* Default USA Address */}
                <h5 className='fw-bolder text-white'>Reach Out</h5>
                <p className='text-white'>+1(423) 402 0607<br />
                  <span className='  text-white '>info@lovewithoutreason.org</span>
                </p><br />
                <h5 className='fw-bolder text-white'>USA Office:</h5>
                <p className='text-white'>
                  5600 Brainerd Road Suite WS-3
                  Chattanooga, TN, USA 37411
                </p><br />
                <h5 className='text-white mt-3 fw-bolder'>Mailing Address:</h5>
                <p className='text-white'>
                  P.O. Box 21009 Chattanooga,TN, USA 37424
                </p>
              </div>
            )}
          </div>

        </div>

      </section >

      <div className="copyright" >
        <div className="container ">
          <div className="row">
            <div className="col-md-12 text-center wow fadeIn animated" data-wow-delay="300ms">
              <p className="m-2">
                Copyright © {currentYear}{' '}
                <a href="/Home" className="hover-default text-danger text-decoration-none fw-bold">
                  Love Without Reason
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export { Footer }