import React from "react";
import line from "../../assests/Group 1870 line2.png";
import leader_1 from "../../assests/leader_1.png";
import leader_2 from "../../assests/leader_2.png";
import leader_3 from "../../assests/leader_3.png";
import leader_4 from "../../assests/binuimg.png";
import leader_5 from "../../assests/leader_5.png";
import leader_6 from "../../assests/aaronsusa.png";
import leader_7 from "../../assests/julie.png";
import leader_8 from "../../assests/jason.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from "react";

function Leader() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="leader mt-5">
      <div className="container mt-5">
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h3 className="text-white fw-bold mt-5 mb-0">USA LEADERSHIP</h3>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>
        </div>
      </section>
      <section style={{ minHeight: "90vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 text-center">
              <h4 class=" text-dark fw-bolder  mb-0 mt-5">
                USA LEADERSHIP 
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row ">
            <div className="col-md-11">
              <p className="text-center mt-3">
                Leadership committee members indirectly establish our
                organizational structure for projects and directly and
                indirectly contribute to accomplishing our goals and mission.
              </p>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-5 mb-5 text-center ">
          <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_3} alt="Board Member 2" />
              <h5 className="text-center text-danger fw-bold text-uppercase">Community Relations <br />
                & Networking</h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_2} alt="President & CEO" />
              <h5 className="text-center text-danger fw-bold  text-uppercase">
                Missions Outreach
              </h5>         
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_6} alt="Board Member 1" />
              <h5 className="text-center text-danger fw-bold text-uppercase">Missions Outreach</h5>
            </div>
        
           
                                
          </div>
          <div className="row d-flex justify-content-center mt-5 mb-5 text-center">
          <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_7} alt="President & CEO" />
              <h5 className="text-center text-danger fw-bold  text-uppercase">
                Marketing and Fundraising
              </h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_8} alt="Board Member 2" />
              <h5 className="text-center text-danger fw-bold text-uppercase">Business and Networking</h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid" src={leader_1} alt="Board Member 1" />
              <h5 className="text-center text-danger fw-bold text-uppercase">Events Management <br />
                & Networking Director</h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_4} alt="Board Member 3" />
              <h5 className="text-center text-danger fw-bold text-uppercase">Information Technology</h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={leader_5} alt="Board Member 4" />
              <h5 className="text-center text-danger fw-bold text-uppercase">Business and Networking</h5>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-5 mb-5 text-center ">
            
            
           
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Leader };
