import React from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from '../../assests/Group 1870 line2.png';
import feb2024 from '../../assests/febnews.png';
import may2024 from '../../assests/may2024 (2).png';
import img1 from '../../assests/2018 JAN.png';
import img2 from '../../assests/newsimg1.png';
import img3 from '../../assests/newsimg2.png';
import img4 from '../../assests/newsimg3.png';
import img5 from '../../assests/newsimg5.jpg';
import img6 from '../../assests/newsimg4.png';
import img7 from '../../assests/NOV 23.png';
import img8 from '../../assests/JAN 22.png';
import img9 from '../../assests/MAY 22.png';
import img10 from '../../assests/OCT 21.png';
import img11 from '../../assests/DEC 21.png';
import img12 from '../../assests/FEB 20.png';
import img13 from '../../assests/MAY 20.png';
import img14 from '../../assests/JULY 20.png';
import img15 from '../../assests/OCT 20.png';
import img16 from '../../assests/DEC 20.png';
import img17 from '../../assests/FEB 19.png';
import img18 from '../../assests/APR 19.png';
import img19 from '../../assests/JUN 19.png';
import img20 from '../../assests/AUG 19.png';
import img21 from '../../assests/DEC 19.png';
import img22 from '../../assests/JAN 18.png';
import img23 from '../../assests/MAR 18.png';
import img24 from '../../assests/MAY 18.png';
import img25 from '../../assests/JUN 18.png';
import img26 from '../../assests/AUG 18.png';
import img27 from '../../assests/DEC 18.png';
import pdf1 from '../../assests/newsletter1.pdf';
import pdf2 from '../../assests/newsletter2.pdf';
import pdf3 from '../../assests/newsletter3.pdf';
import pdf4 from '../../assests/2023 November.pdf';
import pdf5 from '../../assests/newsletter5.pdf';
import pdf6 from '../../assests/2022 January.pdf';
import pdf7 from '../../assests/newsletter4.pdf';
import pdf8 from '../../assests/2022 May.pdf';
import pdf9 from '../../assests/2021 October.pdf';
import pdf10 from '../../assests/2021 December.pdf';
import pdf11 from '../../assests/2020 february.pdf';
import pdf12 from '../../assests/2020 May.pdf';
import pdf13 from '../../assests/2020 July.pdf';
import pdf14 from '../../assests/2020 October.pdf';
import pdf15 from '../../assests/2020 December.pdf';
import pdf16 from '../../assests/2019 February.pdf';
import pdf17 from '../../assests/2019 April.pdf';
import pdf18 from '../../assests/2019 June.pdf';
import pdf19 from '../../assests/2019 August.pdf';
import pdf20 from '../../assests/2019 December.pdf';
import pdf21 from '../../assests/2018jan.pdf';
import pdf22 from '../../assests/2018 March (2).pdf';
import pdf23 from '../../assests/2018 May.pdf';
import pdf24 from '../../assests/2018 June.pdf';
import pdf25 from '../../assests/2018 August.pdf';
import pdf26 from '../../assests/2018 December.pdf';
import pdf27 from '../../assests/2024 February.pdf';
import pdf28 from '../../assests/2024MayNewsletter.pdf';




import { useEffect } from 'react';


function Newsletters() {

    const openPDF1 = () => {
        window.open(pdf1, '_blank');
    };

    const openPDF2 = () => {
        window.open(pdf2, '_blank');
    };

    const openPDF3 = () => {
        window.open(pdf3, '_blank');
    };

    const openPDF4 = () => {
        window.open(pdf4, '_blank');
    };

    const openPDF5 = () => {
        window.open(pdf5, '_blank');
    };

    const openPDF6 = () => {
        window.open(pdf6, '_blank');
    };

    const openPDF7 = () => {
        window.open(pdf7, '_blank');
    };

    const openPDF8 = () => {
        window.open(pdf8, '_blank');
    };
    const openPDF9 = () => {
        window.open(pdf9, '_blank');
    };

    const openPDF10 = () => {
        window.open(pdf10, '_blank');
    };

    const openPDF11 = () => {
        window.open(pdf11, '_blank');
    };

    const openPDF12 = () => {
        window.open(pdf12, '_blank');
    };

    const openPDF13 = () => {
        window.open(pdf13, '_blank');
    };

    const openPDF14 = () => {
        window.open(pdf14, '_blank');
    };

    const openPDF15 = () => {
        window.open(pdf15, '_blank');
    };

    const openPDF16 = () => {
        window.open(pdf16, '_blank');
    };

    const openPDF17 = () => {
        window.open(pdf17, '_blank');
    };

    const openPDF18 = () => {
        window.open(pdf18, '_blank');
    };

    const openPDF19 = () => {
        window.open(pdf19, '_blank');
    };

    const openPDF20 = () => {
        window.open(pdf20, '_blank');
    };

    const openPDF21 = () => {
        window.open(pdf21, '_blank');
    };

    const openPDF22 = () => {
        window.open(pdf22, '_blank');
    };

    const openPDF23 = () => {
        window.open(pdf23, '_blank');
    };

    const openPDF24 = () => {
        window.open(pdf24, '_blank');
    };

    const openPDF25 = () => {
        window.open(pdf25, '_blank');
    };

    const openPDF26 = () => {
        window.open(pdf26, '_blank');
    };
    const openPDF27 = () => {
        window.open(pdf27, '_blank');
    };
    const openPDF28 = () => {
        window.open(pdf28, '_blank');
    };
    useEffect(() => {
        // Set the scroll position when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <section className='mt-5' style={{ maxHeight: '45vh' }}>
                <div className='container'>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-3">NEWS LETTERS-2024</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF28} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid border-0" src={may2024} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        MAY 2024
                                    </div>
                                </div>

                            </button>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF27} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={feb2024} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        FEBRUARY 2024
                                    </div>
                                </div>

                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-5' style={{ minHeight: '100vh' }}>
                <div className='container'>
                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-2">NEWS LETTERS-2023</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF1}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img2} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        FEBRUARY 2023
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF2} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img3} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        APRIL 2023
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF3} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img4} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        OCTOBER 2023
                                    </div>
                                </div>

                            </button>
                        </div>

                    </div>

                    <div className='row d-flex justify-content-center mt-4'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF4}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid newsimg" src={img7} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        NOVEMBER 2023
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF5}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid rounded newsimg" src={img5} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        DECEMBER 2023
                                    </div>
                                </div>

                            </button>
                        </div>


                    </div>
                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-2">NEWS LETTERS-2022</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF6}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img8} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        JUNUARY 2022
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF7} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img6} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        MARCH 2022
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF8} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img9} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        MAY 2022
                                    </div>
                                </div>

                            </button>
                        </div>

                    </div>

                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-2">NEWS LETTERS-2021</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF9}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img10} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        OCTOBER 2021
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF10} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img11} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        DECEMBER 2021
                                    </div>
                                </div>

                            </button>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-2">NEWS LETTERS-2020</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF11}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img12} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        FEBRUARY 2020
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF12} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img13} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        MAY 2020
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF13} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img14} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        JULY 2020
                                    </div>
                                </div>

                            </button>
                        </div>

                    </div>

                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF14}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img15} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        OCTOBER 2020
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF15}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img16} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        DECEMBER 2020
                                    </div>
                                </div>

                            </button>
                        </div>


                    </div>

                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-5">NEWS LETTERS-2019</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF16}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img17} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        FEBRUARY 2019
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF17} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img18} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        APRIL 2019
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF18} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img19} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        JUNE 2019
                                    </div>
                                </div>

                            </button>
                        </div>

                    </div>

                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF19}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img20} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        AUGUST 2019
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF20}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img21} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        DECEMBER 2019
                                    </div>
                                </div>

                            </button>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center  ">
                        <div className="col-md-12 mt-5 text-center">
                            <h4 class=" text-dark fw-bolder mb-0 mt-5">NEWS LETTERS-2018</h4>
                            <img className=" img-fluid" src={line} />
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF21}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img22} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        JUNUARY 2018
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF22} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img23} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        MARCH 2018
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF23} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img24} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        MAY 2018
                                    </div>
                                </div>

                            </button>
                        </div>

                    </div>

                    <div className='row d-flex justify-content-center  mb-5 mt-3'>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF24}>
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img25} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        JUNE 2018
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF25} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img26} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        AUGUST 2018
                                    </div>
                                </div>

                            </button>
                        </div>

                        <div className='col-md-4 mt-2'>
                            <button className='btn btncards' onClick={openPDF26} >
                                <div class="card text-center border-0 shadow-sm">
                                    <div className='card-img-top'>
                                        <img className="img-fluid" src={img27} />
                                    </div>
                                    <div class="card-footer fw-bolder text-danger">
                                        DECEMBER 2018
                                    </div>
                                </div>

                            </button>
                        </div>
                    </div>


                </div>
            </section>
            <Footer />

        </>
    )
}

export default Newsletters;

