import React from "react";
import line from "../../assests/Group 1870 line2.png";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Link } from "react-router-dom";
import { useEffect } from "react";


function Susanblog_2() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="blogsbg" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-12 text-center mt-5">
              <h4 class="text-center text-dark fw-bold mt-3 mb-0">
                SUSAN’S BLOG
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop7"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder
                        " id="staticBackdropLabel">
                          JEREMIAH 32:27
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}

                <div class="card-body">

                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold">
                        KNEES DOWN, EYES UP
                      </h5>
                    </div>


                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop1"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>



                  </div>



                  <p class="card-text">
                    Knees down, Eyes up Hello friends, I wanted to let you know
                    that Philip had his routine visit with his spine surgeon,
                    who is monitoring his scoliosis. Treatment began by him
                    wearing a brace in an attempt to...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    <b>Monday</b>
                    <span className="p-3 text-danger"><b>25 May 2020</b></span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">

                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop1"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
                          KNEES DOWN, EYES UP
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold">
                        I WISH YOU COULD HEAR PHILIP NOW
                      </h5>
                    </div>

                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop2"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>
                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    I wish you could hear Philip now hello friends! I sit here
                    blogging tonight and watching Philip asleep at my side. It
                    is so beautiful to hear him breathe so soundly...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Sunday
                    <span className="p-3 text-danger"> 05 April 2020</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop2"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
                          I WISH YOU COULD HEAR PHILIP NOW
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold">
                        STORIES FROM THE WALL
                      </h5>
                    </div>

                    <div className="col-2 text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop3"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>
                  </div>

                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Stories from The Wall After Philip’s birthday celebration last July, I began to think about the events leading up to his life and birth in 2000. God impressed on my heart, that I had not shared my story...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Wednesday
                    <span className="p-3 text-danger">01 April 2020</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">

                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop3"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
                          STORIES FROM THE WALL
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold">
                        SUPERBOWL 2010
                      </h5>
                    </div>

                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop4"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>
                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Superbowl 2010 What everyone really will be watching on
                    Superbowl Sunday, is the Tim Tebow commercial sponsored by
                    Focus on the Family! The ad has drawn a lot of concern and
                    anger from the pro-choice and gay and lesbian groups...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Wednesday
                    <span className="p-3 text-danger"> 01 April 2020</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">

                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop4"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
                          SUPERBOWL 2010
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold text-uppercase">
                        philip’s first (known) concert
                      </h5>
                    </div>

                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop5"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>
                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Philip’s First (known) Convert Two Wednesday’s back as we
                    were driving back from church, I overheard a conversation
                    between Philip and his six year old sister, Sara. He
                    questioned her about her own salvation...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Wednesday
                    <span className="p-3 text-danger"> 01 April 2020</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">

                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop6"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
                          {" "}
                          GOD STILL MOVES!
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold text-uppercase">
                        GOD STILL MOVES!
                      </h5>
                    </div>

                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop6"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>
                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    God still moves! God still moves, God still movesIn the
                    heart of His people, God still moves!He does not sleep, nor
                    does he slumberGod still moves, God still moves. Philip has
                    recovered well over the past week from the eye and ear
                    surgery...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>
                    Wednesday
                    <span className="p-3 text-danger"> 30 March 2020</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">

                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop11"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel">

                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold text-uppercase">
                        jeremiah 32:27

                      </h5>
                    </div>


                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop7"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>



                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Jeremiah 32:27 Philip’s promise verse for the new year was
                    Jeremiah 32:27 Behold, I am the LORD, the God of all flesh:
                    is there any thing too hard for me? definitely that time of
                    year where people smile, We asked him, well, what is the
                    answer to this question?...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Thursday
                    <span className="p-3 text-danger"> 21 March 2019</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop5"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title fw-bolder" id="staticBackdropLabel">
                          PHILIP’S FIRST (KNOWN) CONCERT
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold text-uppercase">
                        revelation..out of the mouths  of babes

                      </h5>
                    </div>


                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop8"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>



                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Revelation..out of the mouths of babes Well, you’ve read
                    some of the silly and humorous comments from Philip. To be
                    just, I should let you know of the serious, thoughtful ones
                    as well. 🙂 My son is so sweet...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Thursday
                    <span className="p-3 text-danger"> 10 September 2019</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                {/* <!-- Modal --> */}
                <div
                  class="modal fade"
                  id="staticBackdrop9"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title text-uppercase fw-bolder" id="staticBackdropLabel">
                         Philips & his ancestors
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                            </a>
                            <a href="https://twitter.com/">
                              <i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i>
                            </a>
                            <a href="https://www.instagram.com/">
                              <i
                                className="fa-brands fa-square-instagram fa-3x m-1"
                                style={{ color: "#E4405F" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img src="..." class="card-img-top" alt="..." /> */}
                
                
                <div class="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h5 class="card-title text-center text-danger fw-bold text-uppercase">
                        Philips & his ancestors

                      </h5>
                    </div>


                    <div className="col-2  text-end" style={{ marginTop: "-5px" }}>
                      <button
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop9"
                      >
                        <i class="fa fa-share-alt mt-1" ></i>
                      </button>
                    </div>
                  </div>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Philip and his ancestors I thought you all might laugh at
                    this event that happened a few weeks back. Philip and I work
                    very diligently on homework once I return home from work.
                    Every week he receives 5-7 new vocabulary words that he must
                    know...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Saturday
                    <span className="p-3 text-danger">15 June 2019</span>
                  </p>
                </div>
              </div>
            </div>

           
          </div>

          <div className="row mt-3 mb-4">
            <div className="col-4  text-center">
              <button className="btn btn-danger " > <i class="fa-solid fa-backward"></i> PREVIOUS</button>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-danger "> NEXT <i class="fa-solid fa-forward"></i></button>

            </div>
            <div className="col-4">
              <Link to='/susan'><button className="btn btn-secondary float-end"> SHOW ALL BLOG’S....</button></Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Susanblog_2 };
