import React from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import lwr2 from "../../assests/lwrs5.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";



function OurStrategy() {
  const [urlToCopy, setURLToCopy] = useState("");
  const [ipInfo, setIpInfo] = useState({});

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=f667c2c73c3553');
        console.log("response", response);
        setIpInfo(response.data);
      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };

    fetchIpInfo();
  }, []);

  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section id='ourmission' style={{ minHeight: "100vh" }}>
        <div className="container mt-5">
          <div className="row mt-5">
            <div className="col-md-12 text-center mt-5">
              <h3 className="fw-bold text-dark mb-0">OUR STRATEGY</h3>
              <img src={line} className="img-fluid " />
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-4">
            <div className="col md-8 ">
              <h4 className="fw-bold text-danger"></h4>
              <p className="text-dark " style={{ fontSize: "20px" }}>
                Love Without Reason's impact is because of Susan and Santhosh's new holistic strategy of rendering services for cleft surgeries in developing countries.


              </p>

              <h4 className="fw-bold text-danger"></h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                The Love Without Reason holistic services system that includes speech therapy and orthodontia gives these children a full array of opportunities to thrive and become productive citizens in these countries.


              </p>

              <h4 className="fw-bold text-danger"></h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                Each Love Without Reason child no longer is stigmatized as "cursed", abandoned or even killed.


              </p>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                Families are being educated and provided with resources to help them protect their children from organ harvesters who traffick human organs for obscene profits.
              </p>
              <div className="d-flex justify-content-between mt-2 mb-2">
                <div className="col-md-2">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia14"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia14"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title india-text fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          OUR STRATEGY
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-4">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourstrategy"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourstrategy"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/Ourstrategy"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/Ourstrategy"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>

                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/Ourstrategy"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  {ipInfo.country == "IN" ?
                    <Link to="/DonateIndia" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>
                    : <Link to="#campaign-8483" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>}
                </div>
              </div>
            </div>
            <div className="col-md-4 text-start">
              <img className=" img-fluid mb-2 " src={lwr2} />
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default OurStrategy

