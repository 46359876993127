import React from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from 'react';
import line from "../../assests/Group 1870 line2.png";
import axios from 'axios';
import { useState } from 'react';
import logo1 from "../../assests/logo1.png";
import logo2 from "../../assests/logo2.png";
import logo3 from "../../assests/logo3.png";
// import logo1 from "../../assests/logo1.png";
// import logo2 from "../../assests/logo2.png";
// import logo3 from "../../assests/logo3.png";

function Contact() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)

    // }).catch((err) => {
    //   alert('Failed to Submit')
    //   console.log(err);
    // })

  };
  return (
    <>
      <Header />

      <section style={{ minHeight: '100vh' }}>
        <div className="container pt-5">
          <div className="row d-flex justify-content-center align-items-center ">
            <div className="col-md-12 text-center mt-5">
              <h2 class=' text-dark fw-bold mb-0' >CONTACT US</h2>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-evenly mb-3 pt-5">
            <div className="col-md-5 rounded p-4 m-1" style={{background:'#24304c'}}>
              <div className="title">
                <h4 className="text-white position-relative">USA</h4>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="contact-address d-flex flex-wrap">
                    <h5 className="text-white">Office Address &nbsp;:</h5>
                    <p className="text-white ms-md-4">5600 Brainerd Road Suite WS-3 Chattanooga, TN, USA 37411
                    </p>
                    <h5 className="text-white">Mailing Address&nbsp;:</h5>
                    <p className="text-white ms-md-4">P. O. Box 21009, Chattanooga TN 37424
                    </p>

                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="contact d-flex flex-wrap">
                    <h5 className="text-white">Contact &nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#">+1(423) 402 0607</a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-email d-flex flex-wrap">
                    <h5 className="text-white">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#"> &nbsp;contact@lovewithoutreason.org</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 rounded p-4 m-1" style={{background:'#24304c'}}>
              <div className="title">
                <h4 className="text-white position-relative">INDIA</h4>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="contact-address d-flex flex-wrap">
                    <h5 className="text-white">Address &nbsp;:</h5>
                    <p className="text-white ms-md-4">No 14, Bharathiyar Nagar, K.Vadamadurai, Coimbatore
                      -641017</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="contact d-flex flex-wrap">
                    <h5 className="text-white">Contact &nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#">+(91) 8248383860</a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-email d-flex flex-wrap">
                    <h5 className="text-white">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#"> &nbsp;contact@lovewithoutreason.org</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-evenly mb-3">
            <div className="col-md-5 rounded  p-4 m-1" style={{background:'#24304c'}}>
              <div className="title">
                <h4 className="text-white position-relative">SOUTH AFRICA</h4>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="contact-address d-flex flex-wrap">
                    <h5 className="text-white">Address &nbsp;:</h5>
                    <p className="text-white ms-md-4">160 Constantia Drive , Constantia Kloof , Johannesburg
                      1709
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="contact d-flex flex-wrap">
                    <h5 className="text-white">Contact &nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#">+(27) 825514260</a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-email d-flex flex-wrap">
                    <h5 className="text-white">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#"> &nbsp;contact@lovewithoutreason.org</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 rounded  p-4 m-1" style={{background:'#24304c'}}>
              <div className="title">
                <h4 className="text-white position-relative">KENYA</h4>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="contact-address d-flex flex-wrap">
                    <h5 className="text-white">Address &nbsp;:</h5>
                    <p className="text-white ms-md-4"> Fika Citi Mall ,Sheikh Karume street</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="contact d-flex flex-wrap">
                    <h5 className="text-white">Contact &nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#">+(254) 0791899855 , +(254) 0746704300</a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-email d-flex flex-wrap">
                    <h5 className="text-white">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#"> &nbsp;lwrkenya@lovewithoutreason.org</a>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row d-flex justify-content-evenly mb-3">
            <div className="col-md-5 rounded  p-4 m-1" style={{background:'#24304c'}}>
              <div className="title">
                <h4 className="text-white position-relative">ZIMBABWE</h4>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="contact-address d-flex flex-wrap">
                    <h5 className="text-white">Address &nbsp;:</h5>
                    <p className="text-white ms-md-4 text-uppercase">
                      Love without reason - zimbabwe
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="contact d-flex flex-wrap">
                    {/* <h5 className="text-white">contact :</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#">+27825514260</a> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-email d-flex flex-wrap">
                    <h5 className="text-white">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#"> &nbsp;lwrzim@lovewithoutreason.org</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 rounded p-4 m-1" style={{background:'#24304c'}}>
              <div className="title">
                <h4 className="text-white position-relative">UGANDA</h4>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="contact-address d-flex flex-wrap">
                    <h5 className="text-white">Address &nbsp;:</h5>
                    <p className="text-white ms-md-4 text-uppercase">Love without reason - uganda</p>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="contact d-flex flex-wrap">
                    {/* <h5 className="text-white">contact :</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#">0791899855 , 0746704300</a> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-email d-flex flex-wrap">
                    <h5 className="text-white">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h5>
                    <a className="text-white text-decoration-none ms-md-4" href="#"> &nbsp;contact@lovewithoutreason.org</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" style={{ minHeight: "80vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-12  text-center">
              {/* <h2 className="fw-bold text-dark  mt-5">CONTACT US</h2> */}
              {/* <img src={line} className="img-fluid" /> */}
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-4  text-white ">
            <div className="col-md-3">
              <div className="card p-3" style={{ backgroundColor: "#1A1E27" }}>
                <div className="row mt-4 p-1">
                  <div className="col-3">
                    <img className="img-fluid" src={logo3} />
                  </div>
                  <div className="col-9">
                    <h5>Address</h5>
                    <p style={{fontSize:'12px'}}>
                      5600 Brainerd Road Suite WS-3 Chattanooga, TN, USA 37411
                    </p>
                  </div>
                </div>
                <div className="row mt-4 p-1">
                  <div className="col-3">
                    <img className="img-fluid" src={logo2} />
                  </div>
                  <div className="col-9">
                    <h5>Office Phone </h5>
                    <p style={{fontSize:'12px'}}>+1(423) 402 0607</p>
                  </div>
                </div>
                <div className="row mt-4 p-1">
                  <div className="col-3">
                    <img className="img-fluid" src={logo1} />
                  </div>
                  <div className="col-9">
                    <h5>Email Address</h5>
                    <p style={{fontSize:'12px'}}>info@lovewithoutreason.org</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 px-5 mt-1">
              <h2 className="fw-bold text-dark mt-2  ">CONTACT US</h2>
              <img src={line} className="img-fluid mb-2" />
              {/* <div className="row  ">
                <div className="col-md-6 ">
                  <input placeholder="First Name" />
                </div>
                <div className="col-md-6 ">
                  <input placeholder="Last Name" />
                </div>
              </div> */}
              {/* <div className="row  ">
                <div className="col-md-6">
                  <input placeholder="Phone number" />
                </div>
                <div className="col-md-6 ">
                  <input placeholder="Email" />
                </div>
              </div> */}
              {/* <div className="row ">
                <div className="col-md-12">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Message"
                  ></textarea>
                </div>
              </div> */}
              {/* <div className="row d-flex justify-content-center mt-2">
                <div className="col-md-12 ">
                  <div className="d-flex">
                   
                  </div>
                  <button className="btn btn-danger px-5 mt-3 mb-3 fw-bold ">
                    Send Message
                  </button>
                </div>
              </div> */}
              <div className="col-md-12">
                <form onSubmit={sendEmail} className="text-center">
                  <div className="row mb-2 ">
                    <div className="col-md-6">
                      <input class="form-control" placeholder="First Name" name="firstname" type="text" onChange={(e) => { userDetails(e) }} />
                    </div>
                    <div className="col-md-6">
                      <input class="form-control" placeholder="Last Name" type="text" name="lastname" onChange={(e) => { userDetails(e) }} />
                    </div>
                  </div>
                  <div className="row  mb-3">
                    <div className="col-md-6" >
                      <input class="form-control" placeholder="Phone number" type="number" name="number" onChange={(e) => { userDetails(e) }} />
                    </div>
                    <div className="col-md-6" >
                      <input class="form-control" placeholder="Email" type="email" name="email" onChange={(e) => { userDetails(e) }} />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Message" onChange={(e) => { userDetails(e) }}
                        type="text" name="message"></textarea>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-12 text-center">
                      <div className="d-flex">
                      </div>
                      <button className="btn btn-danger mt-3 fw-bold align-items-center px-3 mb-5" data-bs-toggle="modal" data-bs-target="#exampleModalcontactus" type="submit">Submit</button>
                      <div class="modal fade" id="exampleModalcontactus" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                            <div class="modal-body">
                              <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                              <hr />

                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12 fs-5 text-dark">
                                  Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer border-0">
                              <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Contact