
import { Header } from "../Header";
import { Footer } from "../Footer";
import React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';



function Event5kSponsorship() {

    let fileDownload = require('js-file-download');

    function DownloadDonation(e) {
        axios.get(`http://13.232.173.17/donerdetails`, {
            responseType: 'blob',
        })
            .then((res) => {
                // Ensure response contains the file data before downloading
                if (res && res.data) {
                    console.log(res, "hi");
                    fileDownload(res.data, 'DonorList');
                } else {
                    console.error("Empty response or missing data.");
                }
            })
            .catch((err) => {
                console.error("Error fetching or downloading the file:", err);
            });
    }

    const [totalAmount, setTotalAmount] = useState(0);
    const [phone, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');
    const handleCheckboxChange = (event) => {
        const value = parseInt(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked) {
            setTotalAmount(prevAmount => prevAmount + value);
        } else {
            setTotalAmount(prevAmount => prevAmount - value);
        }
    };
    useEffect(() => {
        // Set the scroll position when the component mounts
        window.scrollTo(0, 0); // Adjust the '500' value as needed
    }, []);
    const subscribe = async (e) => {
        e.preventDefault()
        console.log("hi");
        const stripe = loadStripe('')
        try {

            axios.post("http://13.232.173.17/create-checkout-session-usa", {
                firstName, // Assuming you have firstName state
                email,
                totalAmount,
                phone,
                // website,
                // address,
                // comments,
                // contact,
                paymentMethod: 'card',
            }).then(async (res) => {
                console.log("res", res);
                window.open(res.data.url, "_blank");
                const confirm = await stripe.confirmCardPayment(res.data.clientSecret);
                if (confirm.error) return alert("Payment unsuccessful!");
                alert("Payment Successful! Subscription active.");
            }).catch((err) => {
                console.log(err);
            })

        } catch (err) {
            console.error(err);
            alert("Payment failed! " + err.message);
        }
    };


    return (
        <>
            <Header />
            <section className="" style={{ minHeight: "40vh", marginTop: '6%' }}>
                <div className='container shadow-sm  mb-5 border mt-5 rounded' >
                    <div className="row  d-flex justify-content-center mt-5 ">
                        <div className="col-md-10">
                            <h4 className=" text-danger fw-bold">
                                SPONSORSHIP REGISTRATION </h4>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                        <div className="col-md-10">
                            <p>
                                Thank you for your support for children born with clefts. They are cleft heroes.
                                Please fill out the information below and submit it to us. Email us for any questions at <a className="text-warning text-decoration-none" href="" >events@lovewithoutreason.org</a>
                            </p>
                            <p>
                                Checks should be made payable to: <span className="fw-bold">Love Without Reason,</span> P.O. Box 21009 Chattanooga, TN 37424

                            </p>
                            <p><span className="fw-bold">Yes, I believe children born with clefts are heroes!</span> Here is my support of:</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">

                        <div className="col-md-10">
                            <form onSubmit={subscribe}>

                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="headliner"
                                        name="sponsorship_level"
                                        value="10000"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label m-1" htmlFor="headliner">Headliner $10,000</label>
                                </div>

                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="marathon"
                                        name="sponsorship_level"
                                        value="5000"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label m-1" htmlFor="marathon">Marathon Sponsor $5,000</label>
                                </div>

                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="pavilion"
                                        name="sponsorship_level"
                                        value="2500"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label m-1" htmlFor="pavilion">Participant Pavilion $2,500</label>
                                </div>

                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="cross-country"
                                        name="sponsorship_level"
                                        value="1000"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label m-1" htmlFor="cross-country">Cross Country Sponsor $1,000</label>
                                </div>

                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="running-circle"
                                        name="sponsorship_level"
                                        value="500"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label m-1" htmlFor="running-circle">Running Circle Sponsor $500</label>
                                </div>

                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="big-wheel"
                                        name="sponsorship_level"
                                        value="750"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label m-1" htmlFor="big-wheel">Big Wheel 500 Sponsor $750</label>
                                    <p className="mt-3">Total Amount: ${totalAmount}</p>
                                </div>


                                <div class="row mt-3">
                                    <div class="col-md-5">
                                        <div class="mb-3">
                                            <label for="name" class="form-label">Name</label>
                                            <input type="text" class="form-control" id="name" onChange={(e) => setFirstName(e.target.value)} name="name" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="email" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="email" onChange={(e) => setEmail(e.target.value)} name="email" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="website" class="form-label">Website</label>
                                            <input type="text" class="form-control" id="website" onChange={(e) => setWebsite(e.target.value)} name="website" />
                                        </div>

                                    </div>
                                    <div class="col-md-5">
                                        <div class="mb-3">
                                            <label for="phone" class="form-label">Phone</label>
                                            <input type="text" class="form-control" id="phone" onChange={(e) => setPhoneNumber(e.target.value)} name="phone" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="address" class="form-label">Address</label>
                                            <input type="text" class="form-control" id="address" onChange={(e) => setAddress(e.target.value)} name="address" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="website" class="form-label">Contact Information</label>
                                            <input type="text" class="form-control" id="contact" onChange={(e) => setContact(e.target.value)} name="conact" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-10">
                                        <div class="mb-3">
                                            <label for="comments" class="form-label">Comments</label>
                                            <textarea className="form-control" onChange={(e) => setComments(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-10">
                                        <div class="form-check d-flex">
                                            <input class="form-check-input" type="checkbox" id="" name="" value="" />
                                            <label class="form-check-label m-1" for="headliner">
                                                With my sponsorship, I want to submit promotional items for the 5K10K swag bag.
                                                Submit logo for online recognition as 300dpi JPEG or PNG. *Deadline for promotion August 1, 2024
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-md-10 text-center">
                                        <button className="btn btn-danger fw-bolder px-4 m-1">Submit</button>
                                        {/* <button type="button" className="btn btn-danger fw-bolder px-4 m-1" onClick={DownloadDonation}>Download</button> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Event5kSponsorship
