import React from 'react'
import { Header } from '../Header';
import { Footer } from '../Footer';
import Pressimg1 from '../../assests/pressimg.png';
import { useEffect } from 'react';


function Press2() {
    useEffect(() => {
        // Set the scroll position when the component mounts
        window.scrollTo(0, 0); // Adjust the '500' value as needed
    }, []);
    return (
        <>
            <Header />

            <section style={{ minHeight: '100vh' }}>
                <div className='container'>
                    <div className='row d-flex justify-content-center align-items-center mt-5'>
                        <div className='col-md-3 mt-5'>
                            <img src={Pressimg1} className='img-fluid' />
                        </div>
                        <div className='col-md-8 mt-5 india-text'>
                            <h5 className='fw-bolder mt-3'> LOVE WITHOUT REASON ANNOUNCES THE APPOINTMENT OF CHRISTY MATHEW AS THE CHAIRWOMAN OF THE BOARD.</h5>
                            <h4 className='fw-bold'>June 2020 </h4>
                            <p className='mt-4'>Love Without Reason, a global nonprofit organization in the fight against human trafficking announces the appointment of Christy Mathew as the Chairwoman of the Board of Directors. Christy, who joined Love Without Reason in 2012, succeeds Santhosh Mathews, Love Without Reason’s Founder and Chairman Emeritus, who served as chairman since the inception of the organization in 2007. Santhosh will continue to serve as the CEO and President of Love Without Reason, as Christy will take over the reins as the Chairwoman of the Board.  </p>
                        </div>
                    </div>

                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-11 mb-5 mt-5 india-text'>
                            <p>Christy Mathew lived in New York for almost twenty years and always had the heart to support the community which included church and non-profit organizations. While in New York she worked as an Advertising/Marketing Analyst, and graduated with a Masters in Organizational Leadership and Management.  Being in the corporate world for 25 years has helped her to engage with a diverse group of people.  </p>


                            <p>While in New York, she volunteered her time and served as part of the youth ministry, taught Sunday School, and was a leader of the children’s ministry. She served as Director of Curriculum for COMNET (Children’s Outreach Ministry Network), which she founded with her siblings.  She spent countless hours designing curriculum and traveling  through the country learning measures to raise the understanding for children to learn about Christ. She has led the task force  to organize Thanksgiving/Christmas meals for homeless  families in the community.  In Chattanooga, she has been faithfully attending Venue Church and serves in different facets of the church ministry.</p>


                            <p>Her expertise in project management has helped her lead large scale projects for her clients, which is her day job. She also runs an event planning business that continues to expand.  Her company has been hired to manage local and destination events for guests of up to 1000 individuals over a span of several days. Her experiences in the corporate world and the event industry led her to her current position as  Chairwoman of the Board of Directors for the organization Love Without Reason (LWR).   Over the last ten years, she has volunteered with LWR and served on the Board of Directors advancing the mission to help children born with facial deformities, who are also at risk for being sold into trafficking networks.</p>


                            <p>In becoming the Chairwoman for LWR, she is committed to discover different avenues to benefit and advocate for vulnerable children. The organization has conducted nearly 700 facial surgeries in India and Africa and has transformed the lives of many families and increased awareness of human trafficking globally.  Christy has organized many of the large scale fundraisers including the Shine Fashion Show, 5K Run4Love, Launching Philip’s Dream and exhibition booths on the road.  </p>

                            <p>She resides in Chattanooga with her husband Joey, and her two kids, her daughter Isabelle and her son Jack (Jack is her Cockapoo dog).  During her down time she enjoys cooking with her daughter, entertaining guests, re-watching 80s tv shows, listening to 80’s music, and working on her bed and breakfast. </p>
                        </div>
                    </div>



                </div>
            </section>









            <Footer />

        </>
    )
}

export { Press2 }