import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect, useState } from "react";
import line from "../../assests/Group 1870 line2.png";
import aarons from "../../assests/Group 1967.png";
import india1 from "../../assests/Slide 3.png";
import india2 from "../../assests/Slide 5.png";
import india3 from "../../assests/Slide 4.png";
import india4 from "../../assests/Slide 6.png";
import india5 from "../../assests/Slide 7.png";
import india6 from "../../assests/Slide 8.png";
import indiaupi from "../../assests/indiaupiimg.png";

import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import CopyToClipboard from "react-copy-to-clipboard";

function India() {
  const [urlToCopy, setURLToCopy] = useState("");

  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  console.log("urlToCopy", urlToCopy);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section style={{ minHeight: "100vh" }} className="india mt-5 border">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              {/* <h3 className="text-white fw-bold mt-5 mb-0">
                LOVE WITHOUT REASON FOUNDATION INDIA
              </h3> */}
              {/* <img className="img-fluid" src={line} alt="Line" /> */}
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "70vh" }}>
        <div className="container">
          {/* <div className="row d-flex justify-content-center align-items-center mt-2">
            <div className="col-md-12 text-center mt-3">
              <h4 class="text-dark fw-bold mb-0 ">INDIA</h4>
              <img className="img-fluid" src={line} />
            </div>
          </div> */}

          <div className="row mt-5 p-3 d-flex justify-content-center align-items-center">
            <div className="col-md-5 text-center">
              <iframe className="rounded" style={{ height: '55vh', width: '100%' }} src="https://www.youtube.com/embed/1_WY1aZPLtE" title="Aaron&#39;s Invitation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <h3 className="text-danger fw-bolder mt-2">- Aaron’s Invitation </h3>
            </div>
            <div className="col-md-7 india-text">
              <h3 className="fw-bolder">LOVE WITHOUT REASON <span className="text-danger">INDIA</span></h3>
              <p className="mt-2">
                On average, 35,000 to 40,000 cleft children are born each year in India. According to local cleft and craniofacial surgeons, that is not how many children are operated on annually. Santhosh Mathews, co-founder of Love Without Reason, roamed the streets of Bengaluru in the early years of his life. Over three decades, he only remembers meeting one child born with a cleft and having developmental delays. Where are the children? The work of Love Without Reason and the emphasis and value on these children's lives is essential now, more than ever.
              </p><br/>
              <p>
                In Mumbai, India, Love Without Reason began the initiative called Project Butterfly. Women in the red-light areas are empowered with education and business skills that will break a cycle of slavery in the generations. This initiative is reproducible, and the model will be expanded on a global scale.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh" }} className="india-global">
        <div className="container mt-5">
          <div className="row  d-flex align-items-center mt-5 pt-5">
            <div className="col-md-12 text-white text-end mt-5">
              <h1 className="fw-bolder ">449</h1>
              <h5 className="fw-bolder">NUMBER OF SURGERIES</h5>
              <h1 className="fw-bold mt-4">300+</h1>
              <h5 className="fw-bold  text-uppercase">Women Rescued</h5>
              <h1 className="fw-bold mt-4">6000+</h1>
              <h5 className="fw-bold  text-uppercase">OutReach Of Women</h5>
              <h1 className="fw-bold mt-4">05</h1>
              <h5 className="fw-bold  text-uppercase">drilled water wells</h5>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-md-12 mt-5 text-center">
              <h4 class=" text-dark font-weight-bold mb-0 fw-bolder">
                Our Story
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>

          <div className="row d-flex justify-content-center p-3">
            
            <div
              class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={india1} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">NO MORE WITHOUT A NAME</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Traditionally, the newborn baby would be given a name by her
                  grandparents. When a little girl was born to farming parents
                  in a rural village outside of Bengaluru, India, this tradition
                  was not followed. Her grandfather saw the cleft lip and
                  refused to give the child a name. Many of the family members followed suit...{" "}
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            <div
              class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
              style={{ backgroundColor: "#134E5E" }}
            >
              <img src={india2} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">
                  SIX MISCARRIAGES IN 20 YEARS
                </h4>
                <p
                  class="card-text"
                  style={{ fontSize: "14px" }}
                >
                  Hazira and Riyaz have lived in Bangalore all their lives. They
                  are the proud parents of their 20 year-old son, Khurnium.
                  Khurnium and his father Riyaz both work as taxi drivers -
                  known as auto rickshaw drivers in India. Khurnium grew up
                  alone in the house, he always wanted...{" "}
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            <div
              class="col-md-4 text-center rounded shadow-sm border border-0 mt-3"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={india3} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">THE 500TH</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  In a village on Bengaluru's outskirts, a woman named Rizwana
                  found herself in labor alone as her husband couldn't take off
                  from his job. She was eager to hold her firstborn child. The
                  moment that Rizwana looked down into her newborn son's little
                  face, eagerness turned...
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop2"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            {/* MOdal1 */}
            <div
              class="modal fade"
              style={{ maxWidth: "100%" }}
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="modal-body ">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded " src={india1} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark font-weight-bold fw-bolder mt-2 mb-0">
                          NO MORE WITHOUT A NAME
                        </h4>

                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className=" india-text">
                          Traditionally, the newborn baby would be given a name
                          by her grandparents. When a little girl was born to
                          farming parents in a rural village outside of
                          Bengaluru, India, this tradition was not followed. Her
                          grandfather saw the cleft lip and refused to give the
                          child a name. Many of the family members followed suit
                          and rejected the child.
                          <br />
                          <br />
                          In many cultures, the social stigma associated with a
                          facial difference is traumatizing because of the lack
                          of understanding and knowledge. Society's fear causes
                          horrible reactions that leave these innocent children
                          ostracized and humiliated.
                          <br />
                          <br />
                          Disregarding their own community's social norms, the
                          tiny baby's parents held on to their daughter and
                          loved her. They began to search for any way to
                          understand what had happened and to find help for her.
                          <br />
                          <br />
                          After many months, the family was introduced to Love
                          Without Reason founder Santhosh Mathews. The parents
                          felt hope as he explained the simple surgical
                          procedure to the family, especially the grandfather.
                          Santhosh shared his own son's success story. Still,
                          everything they heard seemed unimaginable for this
                          family as they had never seen a surgery like this.
                          <br />
                          <br />
                          When the parents met with the surgical team in
                          Bengaluru and prepared for their daughter's cleft lip
                          surgery, they held onto hope. They could only trust
                          that their little girl would be okay, and this would
                          help.
                          <br />
                          <br />
                          The whole family went to the hospital to see her when
                          this beautiful little girl was ready to come home. As
                          Santhosh greeted the family in the ward, the
                          grandfather turned to him with tears in his eyes. "We
                          didn't know how beautiful she would be. We couldn't
                          see her in the way that you saw her.”
                          <br />
                          <br />
                          That day this little girl's grandfather finally gave
                          her a name. "She is so beautiful, and we will call her
                          by the name Amulya.” Amulya means precious.
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                NO MORE WITHOUT A NAME
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://www.whatsapp.com/">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/India"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/India"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto mb-3">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia1"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100 "
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="staticBackdrop1"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={india2} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                          SIX MISCARRIAGES IN 20 YEARS
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className="text-justify india-text">
                          Hazira and Riyaz have lived in Bangalore all their
                          lives. They are the proud parents of their 20 year-old
                          son, Khurnium. Khurnium and his father Riyaz both work
                          as taxi drivers - known as auto rickshaw drivers in
                          India.
                          <br />
                          <br />
                          Khurnium grew up alone in the house, he always wanted
                          siblings. He watched as his mother went through six
                          miscarriages over a span of twenty years of his life.
                          He saw her grief and sorrow over each pregnancy loss.
                          She desperately wanted to have another child, but due
                          to her diabetes, she had miscarriages.
                          <br />
                          <br />
                          In 2019, when she thought all hope was lost, she
                          became pregnant with another son. The family was
                          overjoyed. Due to previous miscarriages, the
                          obstetrician was more cautious and diligent to do all
                          to protect the baby’s life. With the many tests and
                          ultrasounds, the family was advised that their baby
                          would have a cleft lip. However, nothing could take
                          away their joy to have this child in their life. Due
                          to Hazira’s health issues, baby Mounnadin was born
                          four weeks early. He had many breathing issues and
                          remained in NICU for two weeks. He was finally
                          released home, but the parents struggled in thinking
                          about the cleft lip repair. How could they afford the
                          surgery? They spent all that they had for Mounnadin’s
                          delivery and hospitalization.
                          <br />
                          <br />
                          Miraculously, the intensivists at Aster CMI hospital,
                          a partnering hospital of Love Without Reason,
                          introduced the parents to the Love Without Reason
                          family. At the right time, Mounnadin had successful
                          cleft lip repair, and the family rejoiced with chief
                          craniomaxillofacial surgeon, Dr Sathish. Hazira said,
                          “We cannot stop saying thanks to Dr. Sathish. He
                          shared with us about Love WIthout Reason, and we are
                          so thankful for the free surgery provided for our son.
                          If we see any children with cleft lip or palate, we
                          will be sure to introduce them to Love Without
                          Reason.”
                          <br />
                          <br />
                          Khurnium too is overjoyed. “I can't wait to teach him
                          how to drive, he grinned. There is so much joy in our
                          house now. My mother will stop crying now, and our
                          family will do everything to take care of our
                          Mounnadin.”{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia2"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                SIX MISCARRIAGES IN 20 YEARS
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://www.whatsapp.com/a">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >

                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>
                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/India"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/India"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia2"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="staticBackdrop2"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={india3} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                          THE 500TH
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className="india-text ">
                          In a village on Bengaluru's outskirts, a woman named
                          Rizwana found herself in labor alone as her husband
                          couldn't take off from his job. She was eager to hold
                          her firstborn child. The moment that Rizwana looked
                          down into her newborn son's little face, eagerness
                          turned into loathing. This could not be her son.
                          <br />
                          <br />
                          Rizwana could not control her emotions. She was angry.
                          She was in pain. She angrily asked God, "Why am I
                          going through this pain? What wrong did my innocent
                          baby do to deserve a face like this? " Baby Rehan was
                          born with a bilateral cleft lip. Rizwana had never
                          seen a child with a cleft and was dismayed. These
                          questions tormented her for days. She felt hopeless
                          and helpless and alone without her husband, Sher Ali
                          Khan. People asked her questions, and she had no
                          answers.
                          <br />
                          <br />
                          She felt like a failure, a failure to everyone. When
                          Sher Ali Khan was finally able to see his new son, he
                          struggled to comfort Rizwana. He felt, "As a father,
                          all my dreams and hopes for my child were shattered
                          when I saw his face."
                          <br />
                          <br />
                          The parents began to research the bilateral cleft lip.
                          Relief swept over them as they learned that Rehan
                          could be helped through surgery. Rizwana cradled her
                          little son with love and dreamed of the day he could
                          have surgery. The family understood that affording the
                          needed surgery to repair their baby's cleft lip was
                          probably impossible. This didn't stop them. They
                          started to save from their daily wage jobs for Rehan's
                          surgery. Even her grandmother contributed to the funds
                          from her cigarette-making job. Despite their struggle,
                          they felt discouraged and wondered if they could ever
                          afford surgery to repair their baby's face.
                          <br />
                          <br />
                          Finally, someone told them about Love Without Reason
                          and the free surgical care that baby Rehan could
                          receive in Bengaluru . The family used their meager
                          savings to travel to the city and meet with the chief
                          craniofacial surgeon, Dr. Sathish. Overjoyed, Rehan
                          was cleared for surgery, and the date was set.
                          <br />
                          <br />
                          Sher Ali Khan could not hide his anxiety, but he also
                          held on to his son's dreams as Rehan was admitted for
                          surgery. Rizwana and Sher Ali Khan entered the
                          recovery room after a successful repair and were
                          handed their little Rehan. Both parents were shocked
                          again! The tears and the delight on their faces were
                          genuine and heartfelt.
                          <br />
                          <br />
                          "Thank you, Love Without Reason, and Dr. Sathish, for
                          our miracle. I could not even have imagined my Rehan
                          would be like this. You saved our family when there
                          was no one to help us."{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia3"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                THE 500TH
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://www.whatsapp.com/">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/India"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/India"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia3"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-5  text-center">
              <h4 class="text-dark  mb-0 fw-bold">MUMBAI</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div> */}
          <div className="row d-flex justify-content-center mt-2 mb-2 p-3">
            <div
              class="col-md-4 shadow-sm border border-0 text-center rounded mt-3"
              style={{ backgroundColor: "#134E5E" }}
            >
              <img src={india4} class="img-fluid" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">HANGING DRESSES</h4>
                <p
                  class="card-text  "
                  style={{ fontSize: "14px" }}
                >
                  On her first visit to the red-light areas of Mumbai, Susan
                  Mathews, co-founder of Love Without Reason, walked through the
                  streets of Kamathipura. Though she would return many times,
                  she could not shake the lessons learned that day. It continues
                  to remind her that...{" "}
                </p>
                <button
                  class="btn btn-light rounded px-4 mb-1 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop3"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            <div
              class="col-md-4 shadow-sm border border-0 rounded mt-3 text-center"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={india5} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">WILL YOU HELP ME?</h4>
                <p
                  class="card-text"
                  style={{ fontSize: "14px" }}
                >
                  “Where are his parents?” Susan said this was the first thing
                  that popped into her mind. She had spotted a young man running
                  from car to car in the middle of Mumbai's heavy traffic. Susan
                  got a closer look and realized that he was young enough that
                  he should be in school...{" "}
                </p>
                <button
                  class="btn btn-light rounded px-4 mb-1 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop4"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            <div
              class="col-md-4 shadow-sm border border-0 rounded mt-3 text-center "
              style={{ backgroundColor: "#134E5E" }}
            >
              <img src={india6} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder ">WHAT'S THE PRICE?</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Join a Love Without Reason medical mission and be prepared to
                  become part of someone else's story in ways that you can never
                  forget. Philip was impacted by what went from encounters into
                  lifelong friendships. In fact, he includes many of those
                  life-shifting moments in his...
                </p>
                <button
                  class="btn btn-light rounded px-4 mb-1 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop5"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            {/* modal4 */}
            <div
              class="modal fade"
              id="staticBackdrop3"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={india4} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bolder mt-2 mb-0 ">
                          HANGING DRESSES
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10">
                        <p className="india-text">
                          On her first visit to the red-light areas of Mumbai,
                          Susan Mathews, co-founder of Love Without Reason,
                          walked through the streets of Kamathipura. Though she
                          would return many times, she could not shake the
                          lessons learned that day. It continues to remind her
                          that though evil exists, the effort of the good
                          matters. No matter how big or small, our impact
                          together is needed to overcome corruption.
                          <br />
                          <br />
                          Susan, walking with her team, felt the atmosphere
                          pressing around them like a heavy blanket weighing
                          down on them. It was as if all hope was suffocated by
                          the surrounding sense of doom and oppression.
                          <br />
                          <br />
                          In the middle of that darkness, she was startled to
                          see beautiful colorful children's dresses hanging on
                          clothing lines outside of the small street-side shops.
                          Susan wondered why these frilly and lacy dresses would
                          be sold at night on the street while no other
                          children's shops were open.
                          <br />
                          <br />
                          That first trip was soon filled with Susan listening
                          and learning as women shared story after story. And
                          then she understood the truth behind the hanging
                          dresses. They were really the beginning of the next
                          cycle of slavery, continued with every child's birth
                          in the red-light district.
                          <br />
                          <br />
                          Women and children sold into sex trafficking networks
                          will inevitably become pregnant. In fact, most "pimps"
                          encourage women to become pregnant. Pimps prey on the
                          natural desire that they see for a mother to do
                          anything to protect and feed her child. Exploiting
                          this maternal instinct makes their job easier and more
                          profitable. Suddenly, innocent children become
                          collateral that can be threatened with harm if the
                          women hinder the business of the sex trade.
                          <br />
                          <br />
                          Susan vividly describes her experience walking through
                          apartment-like structures where the women were housed.
                          She was alarmed to see little children under the bed
                          sleeping on the floor. Susan quickly learned from the
                          host that this was also where the mothers "worked."
                          <br />
                          <br />
                          To protect their children and make sure they slept,
                          the mothers would give their children drugs. In some
                          cases, children have been raped by the customer. With
                          the drugs, the children were hidden and quiet.
                          <br />
                          <br />
                          Susan learned more and more as she saw the rampant
                          lack of respect for life. Each visit inspires her of
                          the critical need for Love Without Reason. Their work
                          creating awareness and providing education is a vital
                          part of human trafficking prevention. Through Project
                          Butterfly, Susan sees her work in red-light areas in
                          Mumbai as taking down a little dress and breaking the
                          cycle of slavery. No longer do mothers live with no
                          options. Project Butterfly education and recovery
                          initiatives start with leadership, literacy, and
                          building self-confidence. Women experience sustainable
                          recovery. Through microloans and learning new business
                          skills, they begin to provide for their children.
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia4"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                HANGING DRESSES
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://lovewithoutreason.org/India">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/India"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/India"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia4"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Modal5 */}
            <div
              class="modal fade"
              id="staticBackdrop4"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={india5} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bold  mt-2 mb-0">
                          WILL YOU HELP ME?
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className="india-text">
                          “Where are his parents?” Susan said this was the first
                          thing that popped into her mind. She had spotted a
                          young man running from car to car in the middle of
                          Mumbai's heavy traffic. Susan got a closer look and
                          realized that he was young enough that he should be in
                          school on this busy weekday. But here he was balancing
                          toys, keychains, and souvenirs in his two hands, no
                          parents in sight, and doing his best to reach as many
                          cars as fast as his little legs could go.
                          <br />
                          <br />
                          As a mother herself, Susan began to search for a
                          parent. She couldn't imagine how this little child was
                          possibly safe! How was it even possible that this was
                          allowed on a busy city street?
                          <br />
                          <br />
                          Susan's hosts pulled up to this little man and found
                          themselves his newest potential customers. Everyone's
                          heart melted as his beautiful little face popped into
                          the frame of the car window. He urgently pressed his
                          merchandise forward with his hand, knowing his time to
                          sell them was limited and wanting to reach the other
                          cars. As their eyes met he motioned for Susan to buy
                          his products. The Love Without Reason passengers could
                          not take their eyes off this handsome little fellow.
                          Susan’s first impulse was Let’s just give him some
                          money? If only the solution was that simple.
                          <br />
                          <br />
                          Expressing her overwhelming desire to make sure she
                          could get him off the dangerous streets and back into
                          school, she asked the team, “Why can’t we give him
                          money?” The hosts in Mumbai understood how everyone
                          felt. They laid out the shocking reality that these
                          children's lives couldn't escape by money. They
                          explained how entrenched human traffickers and pimps
                          had become in enslaving the children who subsisted on
                          the street. Susan's heart sank as she learned that the
                          parent she was looking for on the road earlier did not
                          exist. Human traffickers and pimps claim these
                          "runaway children'' in the court system by posing to
                          be the parents. Abruptly a child's entire future is
                          altered. Suddenly, they are no longer children. A
                          slave for the "parent," these children are forced into
                          hawking trinkets on the street. The consequence for
                          not selling? Or missing money? Severe punishment.
                          <br />
                          <br />
                          Susan's question changed from, "Where is the parent to
                          what happens if these children remain on the street?"
                          Love Without Reason knows the answer to that question;
                          those children who remain on the street over the years
                          become chained to a life of crime, other forms of
                          trafficking, and disease.
                          <br />
                          <br />
                          But that has to change. Join Love Without Reason's
                          work to break the cycle of slavery through education
                          and awareness. Getting the kids off the street is
                          where Love Without Reason starts through the Project
                          Butterfly initiative; with your support, these
                          children are no longer at the mercy of traffickers.
                          Through Project Butterfly, they are being fed,
                          educated, counseled, and trained to become productive
                          citizens.
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia5"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                WILL YOU HELP ME?
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://lovewithoutreason.org/India">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/India"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/India"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia5"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal6 */}
            <div
              class="modal fade"
              id="staticBackdrop5"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={india6} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bold mt-2 mb-0">
                          WHAT'S THE PRICE?
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className="india-text">
                          Join a Love Without Reason medical mission and be
                          prepared to become part of someone else's story in
                          ways that you can never forget. Philip was impacted by
                          what went from encounters into lifelong friendships.
                          In fact, he includes many of those life-shifting
                          moments in his autobiography, "If Philip Can, You Can
                          Too."
                          <br />
                          <br />
                          As a parent, you conjure up every type of scenario
                          that could potentially happen. Teenagers, not so much.
                          In fact, every parent experiences their kiddos pushing
                          the boundaries every day, all the time. They are wired
                          to test us and see how far they can go; it's in their
                          nature. However, sometimes that pushing creates a
                          reaction that no one could imagine.
                          <br />
                          <br />
                          Rachel was a typical teenager. Born and raised in a
                          city on the Eastern coast of India, she tested the
                          boundaries with her parents. One day, walking out of
                          her home turned into two nights spent on the street.
                          Rachel had ended up in the heart of a bustling city
                          alone and scared. Feeling relieved and grateful at the
                          invitation of two strangers to join them in their home
                          for her was just an act of needed kindness.
                          <br />
                          <br />
                          Rachel entered a home and woke up in a new city. In
                          one evening, she had been drugged, sold, and loaded on
                          a train. Waking up in Mumbai, Rachel was taken by the
                          traffickers and sold again to a brothel. Rachel was a
                          fighter and spent three years in a dank dungeon. She
                          was kept from the sunlight that entire time. Why?
                          Human trafficking is about utterly breaking a human
                          life. Rachel was exhausted and finally became
                          compliant with the trafficker's demands. What was
                          their price? For months an utterly shattered,
                          helpless, and miserable little soul found her body
                          every night brutally ravaged multiple times. This
                          continued until the day her own life shifted. A young
                          man who had been a customer was determined to fight
                          for her to become his wife. His determination became a
                          ray of hope. Rachel was freed to start her own life
                          and family with him after incredible courage and
                          perseverance.
                          <br />
                          <br />
                          Philip, who is the inspiration behind Love Without
                          Reason, actually met Rachel during a Love Without
                          Reason outreach trip. He could never forget her story.
                          His friendships with Rachel and so many other families
                          grew with each visit to India. With Rachel's
                          permission, Philip would often share her story with
                          others who felt like an empty shell trapped in a life
                          of slavery -like Rachel. Rachel's story becomes their
                          ray of hope.
                          <br />
                          <br />
                          It is special indeed to watch as Rachel continues
                          Philip's desire to help others. Today, Rachel is one
                          of the biggest advocates for awareness and prevention
                          of human trafficking of youth. She now shares her own
                          story with young women of all ages in Mumbai. The
                          Project Butterfly center is a place where women
                          experience life-shifting moments. Working with those
                          who advocate providing education and reintegration
                          into society is truly transformational. The women find
                          incredible courage and perseverance because they want
                          to be free like Rachel.
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia6"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                WHAT'S THE PRICE?
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://lovewithoutreason.org/India">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/India"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/India"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/India"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia6"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export { India };
