import React, { useState } from "react";
import { useAsync } from 'react-use';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import cranio1 from "../../assests/cranio1.png";
import cranio2 from "../../assests/cranio3.png";
import cranio3 from "../../assests/cranio2.png";
import { useEffect } from "react";

import { Link } from "react-router-dom";
import {
  FacebookShareButton, FacebookIcon,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import FacebookProvider, { ShareButton } from 'react-facebook';

function Craniofacial() {
  const description = "Love Without Reason is committed to offering world-class, science-based medical camps which treat and screen those born with congenital craniofacial differences.";
  const imageUrl = "https://lovewithoutreason.org/static/media/cranio1.31476c0789bf672bf95b.png";
  const url = "https://lovewithoutreason.org/CraniofacialSurgeries";


  const shareUrl = "https://lovewithoutreason/CraniofacialSurgeries.org";
  const title = "Love without reason";
  const [urlToCopy, setURLToCopy] = useState("");
  const [displayDuration, setDisplayDuration] = useState(1000);
  const [ipInfo, setIpInfo] = useState({});

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=f667c2c73c3553');
        setIpInfo(response.data);
      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };

    fetchIpInfo();
  }, []);


  const handleCopy = () => {
    setURLToCopy("Copied");
  };

  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  useEffect(() => {
    // Use setTimeout to clear the text after a specific duration
    const timer = setTimeout(() => {
      setURLToCopy("");
    }, displayDuration);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [displayDuration, urlToCopy]);


  return (
    <>

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Facebook meta tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />

        {/* WhatsApp meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />

        {/* LinkedIn meta tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
      </Helmet>


      <Header />
      <section className="craniofacial-bg" style={{ minHeight: "100vh" }}>
        {" "}
      </section>



      <section style={{ minHeight: "70vh" }}>
        <div className="container mt-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 className="fw-bold mb-0">CRANIOFACIAL SURGERY</h4>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>

          <div className="row mt-3 mb-3 d-flex justify-content-center align-items-center p-3">
            <div className="col-md-5">
              <img className="img-fluid" src={cranio1} alt="Line" />
            </div>
            <div className="col-md-7">
              <p className="india-text" style={{ fontSize: "20px" }}>
                Love Without Reason is committed to offering world-class,
                science-based medical camps which treat and screen those born
                with congenital craniofacial differences. The medical team
                leaders bring expertise as heads of department in their
                respective fields. With extensive training and experience, these
                leaders share skills during the medical missions to build future
                medical leaders. Medical teams and administrative leaders work
                together to conduct medical camps in countries with the greatest
                need.
              </p>
              <p className="india-text" style={{ fontSize: "20px" }}>
                To help sustain the Love Without Reason medical camps and
                programming, you can make a tax-deductible gift to Love Without
                Reason.
              </p>
              <div className="d-flex justify-content-between">
                <div className="col-md-2 mb-2">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia15"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div className="col-md-2 mb-2">
                  {ipInfo.country == "IN" ?
                    <Link to="/DonateIndia?reason=2" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>
                    : <Link to="#campaign-8483" target="_top">
                      <button class="btn btn-danger  px-3 ">
                        Donate{" "}
                        <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                      </button>
                    </Link>}
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia15"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          CRANIOFACIAL SURGERY
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body p-3">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">

                            <FacebookShareButton
                              url={shareUrl}
                              quote={title}
                              picture={
                                "https://lovewithoutreason.org/static/media/slider4.76a25242995672661cdf.png"
                              }

                            >
                              <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                              {/* <FacebookIcon size={32} round /> */}
                            </FacebookShareButton>
                            {/* <a href="https://www.facebook.com/login/">
                               <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/static/media/Slide%203.118af2924fef4c93fb05.png "
                                }
                              
                              
                           
                                
                                quote={"Love Without Reason is committed to offering world-class, science-based medical camps which treat and screen those born with congenital craniofacial differences. The medical team leaders bring expertise as heads of department in their respective fields. With extensive training and experience, these leaders share skills during the medical missions to build future medical leaders. Medical teams and administrative leaders work together to conduct medical camps in countries with the greatest need."}
                                hashtag="#lovewithoutreason"

                               
                              >
                               
                                <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                              </FacebookShareButton> 
                            </a> */}

                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                image={
                                  "https://lovewithoutreason.org/static/media/cranio1.31476c0789bf672bf95b.png"

                                }
                                imageURL={
                                  "https://lovewithoutreason.org/static/media/cranio1.31476c0789bf672bf95b.png"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>

                            {/* <i className="fa fa-clone fa-3x m-1" style={{ color: "#C3C4C4" }}></i> */}

                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/CraniofacialSurgeries"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>
                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/CraniofacialSurgeries"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-3x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "90vh", backgroundColor: "#1A1E27" }}>
        <div className="container text-white mt-3">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center ">
              <h4 className="fw-bold mt-3 mb-0">HOLISTIC STRATEGY</h4>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>
          <div className="row mt-3 d-flex justify-content-center align-items-center p-3">
            <div className="col-md-5 mb-5">
              <img className="img-fluid rounded" src={cranio2} alt="Line" />
            </div>
            <div className="col-md-7 india-content">
              <p className="mt-2" style={{ fontSize: "20px" }}>
                1 in 700 births worldwide is a cleft lip or palate. "Cranio"
                refers to the skull and "facial" to the face.
                <br />
                <br />
                Love Without Reason operates on complex craniofacial syndromes
                on a case-by-case basis. Craniofacial surgeons reconstruct
                damaged bone and tissue and improve deformities of the head,
                skull, face, nose, jaws, and associated structures.
                <br />
                <br />
                Our holistic strategy treats syndromic children with a
                specialist team. The specialist team comprises cardiology,
                neurosurgery, otolaryngology, audiology, orthodontics,
                nutrition, and speech and language therapy. The life and the
                future of every child are the utmost priority to our dedicated
                teams.
                <br />
                <br />
                Cleft babies are unable to create the suction needed to
                breastfeed or drink from a standard bottle. They are also prone
                to gas and reflux caused by swallowing large amounts of air
                during feedings. In unreached poverty-stricken areas where
                resources are scarce, malnutrition is a preventable tragedy.
                <br />
                <br />
                You can Nourish Hope for a tiny infant through your donation
                that purchases Dr. Brown's Bottles. These bottles are a valuable
                tool for helping cleft babies' health evaluations to qualify for
                surgery.
              </p>
              <div className="d-flex justify-content-between">
                <div className="col-md-2 mb-2 ">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia16"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade "
                  id="staticBackdropindia16"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title fw-bolder text-dark"
                          id="staticBackdropLabel"
                        >
                          HOLISTIC STRATEGY
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>

                            {/* <i className="fa fa-clone fa-3x m-1" style={{ color: "#C3C4C4" }}></i> */}
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/CraniofacialSurgeries"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>
                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/CraniofacialSurgeries"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 mb-2">
                  <Link to="/DonateIndia?reason=2" target="_top">
                    <button class="btn btn-danger  px-3 ">
                      Donate{" "}
                      <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "80vh" }}>
        <div className="container mt-3 ">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 className="fw-bold mb-0 ">LINKING SURGERY AND TRAFFICKING</h4>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>

          {/* <div className="row d-flex justify-content-center">
                        <div className="col-md-4 mt-2">
                            <img className="img-fluid" src={cranio1} alt="Line" />
                        </div>
                    </div>

                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-10 mt-2 text-center'>
                            <p style={{ fontSize: "20px" }} >The founders discovered in researching anti-human trafficking initiatives that children born like their son Philip are easy prey in the developing world. Children with congenital craniofacial differences suffer the social stigma of being a 'curse,' which increases exploitation for those unwanted and neglected.<br /><br />
                                They unearthed the sinister connection that vulnerable children become slaves for organ harvesting, sexual slavery, child beggars, child soldiers, and child brides.<br /><br />
                                One surgery can prevent human trafficking and change the trajectory of a child's life.</p>
                            <button className='btn btn-danger fw-bold ps-4 pe-4 mb-3'>DONATE</button>
                        </div>
                    </div> */}

          <div className="row mt-3 d-flex justify-content-center align-items-center p-3">
            <div className="col-md-5 ">
              <img className="img-fluid" src={cranio3} alt="Line" />
            </div>
            <div className="col-md-7 india-text mt-3">
              <p style={{ fontSize: "20px" }}>
                The founders discovered in researching anti-human trafficking
                initiatives that children born like their son Philip are easy
                prey in the developing world. Children with congenital
                craniofacial differences suffer the social stigma of being a
                'curse,' which increases exploitation for those unwanted and
                neglected.
                <br />
                They unearthed the sinister connection that vulnerable children
                become slaves for organ harvesting, sexual slavery, child
                beggars, child soldiers, and child brides.
                <br />
                The trajectory of a child's life will be changed with cleft
                surgery, education, dignity and love.
              </p>

              <div className="d-flex justify-content-between">
                <div className="col-md-2 mb-3">
                  <button
                    type="button"
                    class="btn btn-danger px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropindia1"
                  >
                    Share <i class="fa-solid fa-share"></i>
                  </button>
                </div>
                <div
                  class="modal fade"
                  id="staticBackdropindia1"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5
                          class="modal-title fw-bolder"
                          id="staticBackdropLabel"
                        >
                          LINKING SURGERY AND TRAFFICKING
                        </h5>
                        <button
                          type="button"
                          class="btn-close border"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/">
                              <FacebookShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                              </FacebookShareButton>
                            </a>
                            <a href="https://www.whatsapp.com/">
                              <WhatsappShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                              </WhatsappShareButton>
                            </a>
                            <a href="https://twitter.com/">
                              <TwitterShareButton
                                url={
                                  "https://lovewithoutreason.org/CraniofacialSurgeries"
                                }
                                quote={"LWR"}
                                hashtag="#lovewithoutreason"
                              >
                                <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                              </TwitterShareButton>
                            </a>
                            <a href="https://www.linkedin.com/">
                              <LinkedinShareButton
                                url={"https://lovewithoutreason.org/CraniofacialSurgeries"}
                                title={"LWR"}
                                summary={"https://lovewithoutreason.org/"}
                                source={"#lovewithoutreason"}
                              >

                                <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                              </LinkedinShareButton>
                            </a>

                            <CopyToClipboard
                              text={
                                "https://lovewithoutreason.org/CraniofacialSurgeries"
                              }
                              onCopy={handleCopy}
                            >
                              <i
                                className="fa fa-clone fa-2x m-1"
                                style={{ color: "#C3C4C4" }}
                              ></i>
                            </CopyToClipboard>
                            <p style={{ fontSize: "8px", textAlign: "end" }}>
                              {urlToCopy !== "" ? urlToCopy : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <Link to="/DonateIndia?reason=2">
                    <button class="btn btn-danger px-3">
                      Donate{" "}
                      <i class="fas fa-duotone fa-hand-holding-heart"></i>{" "}
                    </button>
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Craniofacial;
