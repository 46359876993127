import React from "react";
import line from "../../assests/Group 1870 line2.png";
import image from "../../assests/usaimg.png";
import image_1 from "../../assests/usa_2.png";
import image_2 from "../../assests/Group 1976.png";
import image_4 from "../../assests/Group 1977.png";
import usa1 from "../../assests/popusa_1.png";
import usa2 from "../../assests/popusa_2.png";
import usa3 from "../../assests/popusa_3.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";


function Usa() {

  const [showPopup, setShowPopup] = useState(false);
  const image = "https://lovewithoutreason.org/static/media/usaimg.f9b95d3967c32e92b4da.png"; // Replace "path_to_your_image" with the actual path to your image
  const sectionId = "love-without-reason"; // ID for the section

  const handleShareClick = () => {
    const shareUrl = `${window.location.href}#${sectionId}`;
    setShowPopup(!showPopup);
    openSharePopup(shareUrl);
  };

  const openSharePopup = (shareUrl) => {
    const popupWidth = 600;
    const popupHeight = 400;
    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;
    const popupOptions = `width=${popupWidth},height=${popupHeight},top=${top},left=${left},scrollbars=yes,resizable=yes`;
    // window.open(shareUrl, 'sharePopup', popupOptions);
  };

  const shareOnFacebook = () => {
    const url = encodeURIComponent(window.location.href);
    const imageUrl = encodeURIComponent(image);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&picture=${imageUrl}&quote=LOVE WITHOUT REASON USA\n\nPhilip Mathews, the inspiration behind Love Without Reason...`; // Include your text here
    window.open(shareUrl, "_blank");
  };

  const shareOnWhatsApp = () => {
    const text = encodeURIComponent("LOVE WITHOUT REASON USA\n\nPhilip Mathews, the inspiration behind Love Without Reason...");
    const imageUrl = encodeURIComponent(image);
    const shareUrl = `https://api.whatsapp.com/send?text=${text}%0A%0A${imageUrl}`; // Include your text and image URL here
    window.open(shareUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent("LOVE WITHOUT REASON USA\n\nPhilip Mathews, the inspiration behind Love Without Reason...");
    const imageUrl = encodeURIComponent(image);
    const shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&media=${imageUrl}`; // Include your text and image URL here
    window.open(shareUrl, "_blank");
  };

  const shareOnLinkedIn = () => {
    const url = encodeURIComponent(window.location.href);
    const title = encodeURIComponent("LOVE WITHOUT REASON USA");
    const description = encodeURIComponent("Philip Mathews, the inspiration behind Love Without Reason...");
    const imageUrl = encodeURIComponent(image);
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${description}&source=${imageUrl}`; // Include your title, description, and image URL here
    window.open(shareUrl, "_blank");
  };


  const [urlToCopy, setURLToCopy] = useState("");

  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  console.log("urlToCopy", urlToCopy);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      <section className="usa mt-5" style={{ minHeight: "100vh" }}></section>

      <section id={sectionId} style={{ minHeight: "90vh" }}>
        <div className="container">

          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-4 text-center">
              <img className="img-fluid rounded" src={image} />
            </div>

            <div className="col-md-6 text-start ">
              <h3 className="fw-bolder">LOVE WITHOUT REASON <span className="text-danger">USA</span></h3>
              <p className="india-text   ">
                Philip Mathews, the inspiration behind Love Without Reason, was
                born in Chattanooga, Tennessee. His journey on earth through
                twenty-five surgeries was difficult, challenging, and with pain.
                Yet, his resilience and joy motivated Santhosh and Susan Mathews
                to be open and transparent when it comes to raising a child with
                special needs, and grieving the loss of that beautiful child.
                <br />
                <br />
                Philip’s mission and dream was to work “Until the World is Cleft
                Free and Until There are No More Sold.” The efforts of Love
                Without Reason to build awareness groups that are educated about
                human trafficking has been launched through the speaking
                engagements and the fundraising events of the founders of Love
                Without Reason. Together, we will work to continue Philip’s
                legacy.
              </p>
              {/* <button onClick={handleShareClick} className="btn btn-secondary">Share</button> */}
              {showPopup && (
                <div className="popup">
                  <button onClick={shareOnFacebook}>Share on Facebook</button>
                  <button onClick={shareOnWhatsApp}>Share on WhatsApp</button>
                  <button onClick={shareOnTwitter}>Share on Twitter</button>
                  <button onClick={shareOnLinkedIn}>Share on LinkedIn</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="usa-global " style={{ minHeight: "90vh" }}></section>

      <section className="mb-5" style={{ minHeight: "100vh" }}>
        <div className="container mt-3 ">
          <div className="row d-flex justify-content-center p-3">
            <div
              class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={image_1} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">HOW DO I...?</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Elijah was diagnosed with sleep apnea. "Have you ever used a
                  CPAP successfully on a child?" His mother, Jen, asked as she
                  reached out for help. Susan and Jen instantly connected as Susan
                  answered the call for support. Susan shared her own experiences
                  of determining how best to strap a CPAP face mask on her
                  8-year-old Philip...
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
                {/* Model-1 */}
                <div
                  class="modal fade"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content modelscroll">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="modal-body">
                        <div className="row d-flex justify-content-center mt-2">
                          <div className="col-md-6">
                            <img className="img-fluid rounded" src={usa1} />
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 text-center">
                            <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                              HOW DO I...?
                            </h4>
                            <img className=" img-fluid" src={line} />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                          <div className="col-md-10">
                            <p className="india-text text-dark">
                              Elijah was diagnosed with sleep apnea. "Have you
                              ever used a CPAP successfully on a child?" His
                              mother, Jen, asked as she reached out for help.
                              <br />
                              <br />
                              Susan and Jen instantly connected as Susan answered
                              the call for support. Susan shared her own
                              experiences of determining how best to strap a CPAP
                              face mask on her 8-year-old Philip.
                              <br />
                              <br />
                              Any parent whose child struggles with health issues,
                              congenital differences, or cognitive delays
                              experiences the immense joy of bringing a new life
                              into the world and unique hurdles. We are like every
                              parent learning the ropes as we go, reaching out to
                              parents who have walked the journey and have
                              succeeded. And are delighted for every day our child
                              is alive, healthy, and happy.
                              <br />
                              <br />
                              Santhosh and Susan Mathews are the founders of Love
                              Without Reason. They set aside time to support
                              parents in the USA through seminars, meetings,
                              workshops, and speaking engagements when they are
                              not engaged in international medical camps. The
                              avenue to encourage parents may be different in each
                              country, but the message is the same. We, like every
                              parent, will find hope and joy in the journey. We
                              can find knowledge and comfort as we reach out to
                              one another. We are not alone.
                            </p>
                          </div>
                        </div>

                        <div className="row ">
                          <div
                            class="modal fade"
                            id="staticBackdropusa2"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered modal-sm">
                              <div class="modal-content">
                                <div class="modal-header p-3">
                                  <h5
                                    class="modal-title fw-bolder"
                                    id="staticBackdropLabel"
                                  >
                                    HOW DO I...?
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close border"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div className="row d-flex justify-content-center">
                                    <div className="col-md-12">
                                      <a href="https://www.facebook.com/login/">
                                        <FacebookShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                        </FacebookShareButton>
                                      </a>
                                      <a href="https://www.whatsapp.com/">
                                        <WhatsappShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                        </WhatsappShareButton>
                                      </a>
                                      <a href="https://twitter.com/">
                                        <TwitterShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                        </TwitterShareButton>
                                      </a>

                                      <a href="https://www.linkedin.com/">
                                        <LinkedinShareButton
                                          url={"https://lovewithoutreason.org/USA"}
                                          title={"LWR"}
                                          summary={"https://lovewithoutreason.org/"}
                                          source={"#lovewithoutreason"}
                                        >

                                          <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                        </LinkedinShareButton>
                                      </a>

                                      <CopyToClipboard
                                        text={"https://lovewithoutreason.org/USA"}
                                        onCopy={handleCopy}
                                      >
                                        <i
                                          className="fa fa-clone fa-3x m-1"
                                          style={{ color: "#C3C4C4" }}
                                        ></i>
                                      </CopyToClipboard>
                                      <p
                                        style={{
                                          fontSize: "8px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {urlToCopy !== "" ? urlToCopy : ""}
                                      </p>
                                      {/* <a href="https://www.instagram.com/">
                                      <i
                                        className="fa-brands fa-square-instagram fa-3x m-1"
                                        style={{ color: "#E4405F" }}
                                      ></i>
                                    </a> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ms-auto ">
                            <button
                              type="button"
                              class="btn btn-danger w-100 mt-2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropusa2"
                            >
                              Share <i class="fa-solid fa-share"></i>
                            </button>
                          </div>
                          <div className="col-md-2 ">
                            <button
                              type="button"
                              class="btn btn-danger w-100 mt-2"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Model-1 */}
              </div>
            </div>
            <div
              class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
              style={{ backgroundColor: "#134E5E" }}
            >
              <img src={image_2} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">
                  PEOPLE, NOT PRODUCTS
                </h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Like everyone, the little residential neighborhood appreciated
                  the ability for children to play, socialize, and make lifelong
                  friends safely. However, one neighbor noted something odd was
                  starting to happen in the house across from her. Cars were
                  coming and going at all times of the day and night, which was
                  unusual She soon realized that different people...
                </p>
                <button
                  className="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                >
                  Read Full Story
                </button>
                {/* Model-2 */}
                <div
                  class="modal fade"
                  id="staticBackdrop1"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content modelscroll">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="modal-body">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-6">
                            <img className="img-fluid rounded" src={usa2} />
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 text-center">
                            <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                              PEOPLE, NOT PRODUCTS
                            </h4>
                            <img className=" img-fluid" src={line} />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                          <div className="col-md-10">
                            <p className="india-text text-dark">
                              Like everyone, the little residential neighborhood
                              appreciated the ability for children to play,
                              socialize, and make lifelong friends safely.
                              However, one neighbor noted something odd was
                              starting to happen in the house across from her.
                              Cars were coming and going at all times of the day
                              and night, which was unusual. She soon realized that
                              different people were entering and leaving home all
                              day and night. Placing a call to the National Human
                              Trafficking Hotline at 1-888-373-7888, she reported
                              the house's unusual activity to the police. Through
                              an investigation based on her observations, victims
                              were rescued.
                              <br />
                              <br />
                              We are not safer by thinking that human trafficking
                              is not our problem. Love Without Reason created a
                              collaboration of those in Tennessee involved in the
                              fight against human trafficking. Engaging leaders,
                              executives from nonprofit organizations, law
                              enforcement officials from the Tennessee Bureau of
                              Investigation and Homeland Security to create a
                              vital human trafficking awareness event.
                              <br />
                              <br />
                              People, Not Products aims to educate the public
                              regarding awareness campaigns that fight human
                              trafficking and patterns that feed human trafficking
                              on the local, national, and international scale.
                              <br />
                              <br />
                              Why did people like this neighbor think to call the
                              National Human Trafficking Hotline? Because of law
                              enforcement officials' and organizations' education
                              in an open public forum that shared insight and
                              awareness into the crime of human trafficking.
                              <br />
                              <br />
                              Awareness education is a critical component in
                              fighting this evil. Human trafficking happens in the
                              United States, and the public has a significant
                              impact on stopping it. Our everyday "super" skill to
                              fight this crime is observation and intuitive red
                              flags. This type of information made to the National
                              Human Trafficking Hotline at 1-888-373-7888 can be
                              one phone call that can save an innocent life.
                              <br />
                              <br />
                              Join People, Not Products, and together we can bring
                              change for the neighborhood, community, city, state,
                              country, and world.
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div
                            class="modal fade"
                            id="staticBackdropusa3"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered modal-sm">
                              <div class="modal-content">
                                <div class="modal-header p-3">
                                  <h5
                                    class="modal-title fw-bolder text-dark"
                                    id="staticBackdropLabel"
                                  >
                                    PEOPLE, NOT PRODUCTS
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close border"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div className="row d-flex justify-content-center">
                                    <div className="col-md-12">
                                      <a href="https://www.facebook.com/login/">
                                        <FacebookShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                        </FacebookShareButton>
                                      </a>
                                      <a href="https://www.whatsapp.com/">
                                        <WhatsappShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                        </WhatsappShareButton>
                                      </a>
                                      <a href="https://twitter.com/">
                                        <TwitterShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                        </TwitterShareButton>
                                      </a>
                                      <a href="https://www.linkedin.com/">
                                        <LinkedinShareButton
                                          url={"https://lovewithoutreason.org/USA"}
                                          title={"LWR"}
                                          summary={"https://lovewithoutreason.org/"}
                                          source={"#lovewithoutreason"}
                                        >

                                          <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                        </LinkedinShareButton>
                                      </a>

                                      <CopyToClipboard
                                        text={"https://lovewithoutreason.org/USA"}
                                        onCopy={handleCopy}
                                      >
                                        <i
                                          className="fa fa-clone fa-3x m-1"
                                          style={{ color: "#C3C4C4" }}
                                        ></i>
                                      </CopyToClipboard>
                                      <p
                                        style={{
                                          fontSize: "8px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {urlToCopy !== "" ? urlToCopy : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ms-auto ">
                            <button
                              type="button"
                              class="btn btn-danger w-100 mt-2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropusa3"
                            >
                              Share <i class="fa-solid fa-share"></i>
                            </button>
                          </div>
                          <div className="col-md-2 ">
                            <button
                              type="button"
                              class="btn btn-danger w-100 mt-2"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Model-2 */}
              </div>
            </div>

            <div
              class="col-md-4 text-center rounded shadow-sm border border-0 mt-3"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={image_4} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">SEPTEMBER IS CRANIOFACIAL ACCEPTANCE MONTH</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Philip Mathews, author of "If Philip Can, You Can Too,"
                  questioned his facial differences and hoped to look like
                  everyone else. It was when he understood and accepted his
                  uniqueness that he was able to say, "Why do you want to fit in
                  when you were born  to stand out?" But, Philip Mathews, the inspiration...
                </p>
                <button
                  className="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop3"
                >
                  Read Full Story
                </button>
                {/* Model-3 */}
                <div
                  class="modal fade"
                  id="staticBackdrop3"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content modelscroll">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div class="modal-body">
                        <div className="row d-flex justify-content-center mt-2">
                          <div className="col-md-6">
                            <img className="img-fluid rounded" src={usa3} />
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 text-center">
                            <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                              SEPTEMBER IS CRANIOFACIAL ACCEPTANCE MONTH
                            </h4>
                            <img className=" img-fluid" src={line} />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-2">
                          <div className="col-md-10">
                            <p className="india-text mt-2 text-dark">
                              Philip Mathews, author of "If Philip Can, You Can
                              Too," questioned his facial differences and hoped to
                              look like everyone else. It was when he understood
                              and accepted his uniqueness that he was able to say,
                              "Why do you want to fit in when you were born to
                              stand out?" But, Philip Mathews, the inspiration
                              behind Love Without Reason, knew the pain that
                              children with a facial difference felt and wanted
                              them to feel accepted and understood on a global
                              scale.
                              <br />
                              <br />
                              In honor of Craniofacial Acceptance Month, every
                              year, the Love Without Reason team celebrates the
                              month with a 5K Run4Love. The 5K Run4Love and the
                              virtual 5K Run4Love held worldwide are to honor
                              Philip and all those born with facial differences.
                              Join the run and donate as a clear message we love,
                              respect, honor, and hope for success to all those
                              born with facial differences.
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div
                            class="modal fade"
                            id="staticBackdropusa1"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered modal-sm">
                              <div class="modal-content">
                                <div class="modal-header p-3">
                                  <h5
                                    class="modal-title fw-bolder
                        "
                                    id="staticBackdropLabel"
                                  >
                                    SEPTEMBER IS CRANIOFACIAL ACCEPTANCE MONTH
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close border"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div className="row d-flex justify-content-center">
                                    <div className="col-md-12">
                                      <a href="https://www.facebook.com/login/">
                                        <FacebookShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                        </FacebookShareButton>
                                      </a>
                                      <a href="https://www.whatsapp.com/">
                                        <WhatsappShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                        </WhatsappShareButton>
                                      </a>
                                      <a href="https://twitter.com/">
                                        <TwitterShareButton
                                          url={
                                            "https://lovewithoutreason.org/USA"
                                          }
                                          quote={"LWR"}
                                          hashtag="#lovewithoutreason"
                                        >
                                          <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                        </TwitterShareButton>
                                      </a>
                                      <a href="https://www.linkedin.com/">
                                        <LinkedinShareButton
                                          url={"https://lovewithoutreason.org/USA"}
                                          title={"LWR"}
                                          summary={"https://lovewithoutreason.org/"}
                                          source={"#lovewithoutreason"}
                                        >

                                          <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                        </LinkedinShareButton>
                                      </a>
                                      <CopyToClipboard
                                        text={"https://lovewithoutreason.org/USA"}
                                        onCopy={handleCopy}
                                      >
                                        <i
                                          className="fa fa-clone fa-3x m-1"
                                          style={{ color: "#C3C4C4" }}
                                        ></i>
                                      </CopyToClipboard>
                                      <p
                                        style={{
                                          fontSize: "8px",
                                          textAlign: "end",
                                        }}
                                      >
                                        {urlToCopy !== "" ? urlToCopy : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ms-auto">
                            <button
                              type="button"
                              class="btn btn-danger w-100 mt-2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropusa1"
                            >
                              Share <i class="fa-solid fa-share"></i>
                            </button>
                          </div>
                          <div className="col-md-2 ">
                            <button
                              type="button"
                              class="btn btn-danger w-100 mt-2"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Model-3 */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Usa };
