import React from "react";
import line from "../../assests/Group 1870 line2.png";
import globe1 from "../../assests/globe1.png";
import globe2 from "../../assests/globe2.png";
import globe3 from "../../assests/globe3.png";
import globe4 from "../../assests/georgeimg.png";
import globe5 from "../../assests/globe5.png";
import globe6 from "../../assests/globe6.png";
import globe7 from "../../assests/globe7.png";
import globe8 from "../../assests/alanimg.png";
import globe9 from "../../assests/globalpradip.png";
import globe10 from "../../assests/sumithaimg.png";
import globe11 from "../../assests/kenyaglobal1.png";
import globe12 from "../../assests/kenya Salome.png";
import globe13 from "../../assests/kenyaglobal3.png";
import globe14 from "../../assests/kenyaglobals1.png";
import globe15 from "../../assests/kenyaglobals2.png";
import globe16 from "../../assests/globalteamkenya11.png";
import globe17 from "../../assests/globalteamkenya12.png";
import globe18 from "../../assests/globalteamkenya13.png";
import globe19 from "../../assests/southglobal1.png";
import globe20 from "../../assests/southglobal2.png";
import globe21 from "../../assests/southglobal3.png";
import globe22 from "../../assests/dr.sathis.png";
import globe23 from "../../assests/dr.Mathew.png";
import globe24 from "../../assests/Dr. Wayne Manana.png";
import globe25 from "../../assests/Dr. S.K Mohanty.png";
import zim_1 from "../../assests/globalzim_1 (2).png";
import zim_2 from "../../assests/globalzim_2.png";
import zim_3 from "../../assests/globalzim_3.png";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { useEffect } from "react";

function GlobalTeam() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="globeteam"></section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12  text-center mt-3">
              <h4 class=" text-dark fw-bold mb-0 ">INDIA</h4>
              <img className="img-fluid" src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 p-3">
              <img className="img-fluid" src={globe1} />
              <h4 className="text-danger mt-2 text-center fw-bold">
                CHAIRMAN OF THE TRUST
              </h4>
            </div>
          </div>

          <div className="row d-flex justify-content-evenly mt-5 border border-4 border-danger p-4 rounded">
            <div className="col-md-12 text-center">
              <h4 className="text-danger fw-bold">TRUSTEE BOARD</h4>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe2} />
              <p className="text-danger mt-2 fw-bold">BOARD MEMBER</p>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe3} />
              <p className="text-danger mt-2 fw-bold">SECRETARY</p>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe4} />
              <p className="text-danger  mt-2 fw-bold">BOARD MEMBER</p>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-5 border border-4 border-danger p-4 rounded mb-5">
            <div className="col-md-12 text-center">
              <h4 className="text-danger fw-bold">INDIA LEADERSHIP TEAM</h4>
            </div>
            <div className="col-md-3 mt-3 text-center">
              <img className="img-fluid" src={globe5} />
              <p className="text-danger mt-2 fw-bold">CORPORATE RELATIONS</p>
            </div>
            <div className="col-md-3 mt-3 text-center">
              <img className="img-fluid" src={globe6} />
              <p className="text-danger mt-2 fw-bold">INFORMATION TECHNOLOGY</p>
            </div>
            <div className="col-md-3 mt-3 text-center">
              <img className="img-fluid" src={globe7} />
              <p className="text-danger  mt-2 fw-bold">PROJECT MANAGER</p>
            </div>
            <div className="row  mt-4 d-flex justify-content-center">
              {/* <div className="col-md-3 mt-3 text-center">
                <img className="img-fluid" src={globe8} />
                <p className="text-danger mt-2 fw-bold">MEDIA</p>
              </div> */}
              <div className="col-md-3 mt-3 text-center">
                <img className="img-fluid" src={globe9} />
                <p className="text-danger mt-2 fw-bold">
                  ANTI-HUMAN TRAFFICKING PROJECT COORDINATION
                </p>
              </div>
              <div className="col-md-3 mt-3 text-center">
                <img className="img-fluid" src={globe10} />
                <p className="text-danger  mt-2 fw-bold">
                  CAMP COORDINATOR,
                  <br />
                  PROJECT MANAGER
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh", backgroundColor: "#1A1E27" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 class=" text-white fw-bold mb-0 mt-5">ZIMBABWE, AFRICA</h4>
              <img className="img-fluid" src={line} />
            </div>

            <div className="row mb-3 d-flex justify-content-evenly mt-5 border border-4 border-danger p-4 rounded">
              <div className="col-md-12 text-center">
                <h4 className="text-danger fw-bold">TRUSTEE BOARD</h4>
              </div>
              <div className="col-md-4 text-center mt-2">
                <img className="img-fluid" src={zim_1} />
                <p className="text-white mt-2 fw-bold text-uppercase">
                  Camp Coordinator
                </p>
              </div>
              <div className="col-md-4 text-center mt-2">
                <img className="img-fluid" src={zim_2} />
                <p className="text-white mt-2 fw-bold">
                  BOARD CHAIR
                </p>
              </div>
              <div className="col-md-4 text-center mt-2">
                <img className="img-fluid" src={zim_3} />
                <p className="text-white  mt-2 fw-bold text-uppercase">
                  Donor Relations
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 class=" text-dark fw-bold mb-0 mt-5">KENYA, AFRICA</h4>
              <img className="img-fluid" src={line} />
            </div>
          </div>
          <div className="row mb-3 d-flex justify-content-evenly mt-5 border border-4 border-danger p-4 rounded">
            <div className="col-md-12 text-center">
              <h4 className="text-danger fw-bold">TRUSTEE BOARD</h4>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe11} />
              <p className="text-danger mt-2 fw-bold text-uppercase">
                Executive Director
              </p>
            </div>
            <div className="col-md-4 text-center mt-2">

              <img className="img-fluid" src={globe18} />
              <p className="text-danger mt-2 fw-bold text-uppercase">
                Director, Finance
              </p>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe13} />
              <p className="text-danger  mt-2 fw-bold text-uppercase">
                Secretary/Administration
              </p>
            </div>
            <div className="row d-flex justify-content-center">
              {/**/}
              <div className="col-md-4 text-center mt-2">
                <img className="img-fluid" src={globe12} />
                <p className="text-danger  mt-2 fw-bold text-uppercase">
                  BOARD MEMBER
                </p>
              </div>
              <div className="col-md-4 text-center mt-2">
                <img className="img-fluid" src={globe15} />
                <p className="text-danger  mt-2 fw-bold text-uppercase">
                  Health Liaison
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-3 d-flex justify-content-center mt-5 border border-4 border-danger p-4 rounded">
            <div className="col-md-12 text-center">
              <h4 className="text-danger fw-bold">KENYA LEADERSHIP TEAM</h4>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe14} />
              <p className="text-danger mt-2 fw-bold text-uppercase">
                Transportation & Logistics Manager
              </p>
            </div>
            {/* <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe16} />
              <p className="text-danger mt-2 fw-bold text-uppercase">
                Speech and Language Pathologist
              </p>
            </div> */}
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe17} />
              <p className="text-danger mt-2 fw-bold text-uppercase">
                INFORMATION TECHNOLOGY
              </p>
            </div>

          </div>
        </div>
      </section>
      <section style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-3 text-center">
              <h4 class="  fw-bold mb-0 mt-5">SOUTH AFRICA</h4>
              <img className="img-fluid" src={line} />
            </div>
          </div>
          <div className="row mb-5 d-flex justify-content-evenly mt-5 border border-4 border-danger p-4 rounded">
            <div className="col-md-12 text-center">
              <h4 className="text-danger fw-bold">LEADERSHIP TEAM</h4>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe19} />
              <p className='text-danger mt-2 fw-bold text-uppercase'>Patient Outreach</p>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe20} />
              <p className='text-danger mt-2 fw-bold text-uppercase'>School Outreach</p>
            </div>
            <div className="col-md-4 text-center mt-2">
              <img className="img-fluid" src={globe21} />
              <p className='text-danger  mt-2 fw-bold text-uppercase'>Donor Relations & Fundraising</p>
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12  text-center">
              <h4 class="  fw-bold mb-0  text uppercase">Medical Team Leadership</h4>
              <img className="img-fluid" src={line} />
            </div>
          </div>
          <div className="row mb-5 d-flex justify-content-evenly mt-5 border border-4 border-danger p-4 rounded">
            <div className="col-md-12 text-center">
              <h4 className="text-danger fw-bold">MEDICAL LEADERSHIP TEAM</h4>
            </div>
            <div className="col-md-3 text-center mt-2">
              <img className="img-fluid" src={globe22} />
              <p className='text-danger mt-2 fw-bold text-uppercase'>CMF Surgeon</p>
            </div>
            <div className="col-md-3 text-center mt-2">
              <img className="img-fluid" src={globe23} />
              <p className='text-danger mt-2 fw-bold text-uppercase'>CMF Surgeon</p>
            </div>
            <div className="col-md-3 text-center mt-2">
              <img className="img-fluid" src={globe25} />
              <p className='text-danger  mt-2 fw-bold text-uppercase'>Pediatric
                Anesthesiologist</p>
            </div>
            <div className="col-md-3 text-center mt-2">
              <img className="img-fluid" src={globe24} />
              <p className='text-danger  mt-2 fw-bold text-uppercase'>CMF Surgeon</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default GlobalTeam;
