import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from "react";
import line from "../../assests/Group 1870 line2.png";
import south1 from "../../assests/southimage_11.png";
import south2 from "../../assests/southimage_22.png";
import south3 from "../../assests/afr3.png";



function Africa() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      <section className="africa mt-5" style={{ minHeight: "100vh" }}></section>
      <section style={{ minHeight: "60vh" }}>
        <div className="container">
          {/* <div className="row d-flex justify-content-center ">
            <div className="col-md-12 text-center">
              <h4 className=" text-dark fw-bolder mt-3 mb-0 mt-5">SOUTH AFRICA</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div> */}
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-4 text-center">
              <img className="img-fluid rounde" src={south1} />
            </div>
            <div className="col-md-6 text-start mt-3">
            <h3 className="fw-bolder">LOVE WITHOUT REASON <span className="text-danger">SOUTH AFRICA</span></h3>

              <p className="india-text mt-3">
                The Love Without Reason South Africa arm was established in 2022
                as the Southern region of Africa headquarters. The executive
                board was formed of leaders residing in South Africa who are
                passionate about transforming the lives of children in Africa
                with new smiles and free craniofacial surgery. This team is also
                intentional in addressing human trafficking and measures to
                build awareness in the entire continent of Africa. With their
                efforts, fundraising, and networking, the children of Africa
                will have a new smile.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="south-global mt-5"
        style={{ minHeight: "90vh" }}
      ></section>

      <section style={{ minHeight: "60vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 text-center">
              <h4 className=" text-dark fw-bolder mt-5   mb-0 ">
                THE IMPACT OF 2023
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row mt-3 d-flex justify-content-center">
            <div className="col-md-6 text-start ">
              <p className="india-text  ">
                <br />

                In Kenya, training on human trafficking for volunteers, patients
                and families was arranged by the Love Without Reason team.
                Twenty families were impacted as their loved ones went through
                successful cleft surgery in Nairobi, Kenya.
              </p>
              <p className="india-text">
                The nation of Zimbabwe received education and awareness on human
                trafficking during the March 2023 medical camp. This training
                was provided to volunteers, patients and families. The lives of
                ten children were forever transformed through free cleft
                surgery. A shipment of sanitizer and baby care items was also
                donated by a church for the people of Zimbabwe.
              </p>
            </div>

            <div className="col-md-4 text-center">
              <img className="img-fluid rounde" src={south2} />
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "90vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 text-center">
              <h4 className=" text-dark fw-bolder mt-3 mb-0 mt-5">
                LOOKING AT 2024
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row mt-3 d-flex justify-content-center">
            <div className="col-md-4 text-center">
              <img className="img-fluid rounde" src={south3} />
            </div>
            <div className="col-md-6 text-start ">
              <p className="india-text pe-3  ">
                <br />
                <br />
                The Love Without Reason South Africa team looks ahead with giant
                goals. Can we double or triple the number of surgeries funded in
                2024? Can our team work to abolish human trafficking in one
                region of our homeland?
              </p>
              <p className="india-text" >
                We can do this together, with the power of your partnership.
                Please reach out to us to learn more at <span className="text-danger fw-bolder">info@lovewithoutreason.org</span>   and let us work together to fulfil
                #PhilipsDream.
              </p>
            </div>
          </div>

        </div>
      </section>

      <Footer />
    </>
  );
}

export { Africa };
