import React from "react";
import founder1 from "../../assests/Santhosh_M.png";
import founder2 from "../../assests/Susan_M.png";
import lwr1 from "../../assests/lwrstorysan1.png";
import lwr2 from "../../assests/lwrstoryphilip1.png";
import lwr3 from "../../assests/lwrstoryphilps2.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from 'axios';
import { Header } from '../Header';
import { Footer } from '../Footer';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import CopyToClipboard from "react-copy-to-clipboard";




function Founder() {

  const [urlToCopy, setURLToCopy] = useState("");
  const shareOnWhatsApp = () => {
    const text = "Check out this amazing story: LWR";
    const url = "https://lovewithoutreason.org/LWRstory";
    const image = "https://lovewithoutreason.org/static/media/founder1.62ee5d556b3401b75be9.png"; // Replace with the URL of your image

    const shareLink = `whatsapp://send?text=${encodeURIComponent(`${text}\n${url}`)}`;

    // Open the WhatsApp share dialog
    window.location.href = shareLink;
  };
  const handleCopy = () => {
    setURLToCopy("Copied");
  };


  return (
    <>
      <Header />
      <section
        className="founder mt-5"
        style={{ minHeight: "100vh" }}
      ></section>



      <section style={{ minHeight: "90vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center "></div>

          <div className="row mt- d-flex justify-content-center align-items-center mt-3">
            <div className="col-md-4 mt-3">
              <img className=" img-fluid  founders" src={founder2} />
            </div>
            <div className="col-md-6  text-justify text-black mt-4 ">
              <h2 className="text-danger  mb-3 fw-bold px-4 ">SUSAN MATHEWS</h2>
              <h4 className="text-danger fw-bolder px-4">Chairwoman, CMO </h4>
              <p style={{ fontSize: "20px" }} className="px-4 india-text">
              After obtaining her undergraduate degrees in chemistry and nursing, she went on to get her Master of Science in Nursing and served for over twenty years in the healthcare industry from CNA to Nurse to Family Nurse Practitioner. She is the wife of Santhosh Mathews, and mother to Philip, Sara and Caleb. Her experiences with her children inspired her first book, "A Mother's Heart," sharing the daily struggles of parents of "normal" children and those with special needs. She and Philip wrote "If Philip Can, You Can Too" and she published it posthumously for Philip. It became an Amazon best seller in its category.
              </p>
            </div>
            <div className="col-md-10 p-4">
              <p className="india-text">
              Raising a child with multiple craniofacial differences to excel against all the medical odds and graduate from high school is a story she shares around the globe. Grieving the loss of a child, picking up the mantle of his dream, and driving her surviving children forward is a challenge she inspires in her audience. She has traveled to countries from Sweden to South Africa, sharing her story, motivating her audience in faith, and relaying the joy that can come after grief.   In 2014, she laid down her stethoscope and picked up the spear to fight human trafficking and set captives free, physically and spiritually, through the work of Love Without Reason.
              </p>
            </div>

          </div>

        </div>

      </section>


      <section className="mb-4" style={{ minHeight: "70vh", backgroundColor: " #ffff" }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-6 text-justify text-white">
              <h3 className="text-danger mb-3 fw-bold ">SANTHOSH MATHEWS (EEE, MBA)</h3>
              <h4 className="text-danger fw-bolder">CEO, President</h4>
              <br></br>
              <p className="india-text text-dark" style={{ fontSize: "20px" }}>

                Santhosh Mathews is the innovator and creator behind Love Without Reason. After completing his Electrical and Electronics Engineering degree, he moved into web technologies, multimedia and UIX. In 2007, with an MBA in Business Administration and International Trade, Santhosh along with Susan founded Love Without Reason. With his background in counseling youth, working in the IT industry, and raising three children, including a son with craniofacial differences, his material for speaking and for blogging is never-ending. His passion to envision Love Without Reason began with the journey in raising his children Philip, Sara and Caleb.
              </p>
            </div>
            <div className="col-md-4 mt-5">
              <img className=" img-fluid founders" src={founder1} />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5" style={{ minHeight: "100vh" }}>
        <div className="container">

          <div className="row d-flex justify-content-center">
            <div className="col-md-10">
              <h4 className="fw-bold text-start"> What made our founders persistent and passionate about these causes?</h4>

            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-md-10">
              <p className="india-text"> Santhosh and Susan are blessed to have three children.  Philip, their firstborn, lived for 19 years before running ahead to heaven.  To read more about Philip’s life and read an excerpt of his autobiography,<Link className="text-danger text-decoration-none fw-bolder" to='/philip'> Click here </Link></p>

            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-md-6">
              <p className="india-text">


                Philip went through 25 surgeries in his life, the majority were craniofacial.  They understood the social stigma of parents raising a child with facial differences.  Their hope was that through the work of Love Without Reason, parents who attended the cleft camp would know there was a team advocating for them.  After learning that children born with facial differences were vulnerable to human traffickers, including organ harvesters who exploit these children and sell their organs on the black market, they knew that Love Without Reason would join the fight against this evil crime.
              </p>
            </div>
            <div className="col-md-4">
              <img src={lwr2} className="img-fluid" />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-md-10">
              <p className="india-text">
                Philip coined the phrase, <b>“ I want to work Until the World is Cleft Free and Until There are No More Sold.”</b>  The Mathews’ commitment is to fulfill Philip’s dream .
              </p>
            </div>
          </div>








          <div className='row  d-flex justify-content-center mb-5'>
            <div class='col-md-6'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/SJEVCz7Jd-o?si=BWDc2l_L8z4b_Jk3" title="PHILIP MATHEWS - LIFESTORY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Envisioning a World Cleft-Free</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-md-2 mb-3">
              <button
                type="button"
                class="btn btn-danger w-75 "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropindia16"
              >
                Share <i class="fa-solid fa-share"></i>
              </button>
            </div>
            <div
              class="modal fade "
              id="staticBackdropindia16"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                  <div class="modal-header p-3">
                    <h5
                      class="modal-title fw-bolder text-dark"
                      id="staticBackdropLabel"
                    >
                      LWR STORY
                    </h5>
                    <button
                      type="button"
                      class="btn-close border"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12">
                        <a href="https://www.facebook.com/login/">
                          <FacebookShareButton
                            url={
                              "https://lovewithoutreason.org/LWRstory"
                            }
                            quote={"LWR"}
                            hashtag="#lovewithoutreason"
                            image={"https://lovewithoutreason.org/static/media/founder1.62ee5d556b3401b75be9.png"}
                          >
                            <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                          </FacebookShareButton>
                        </a>
                        <a href="https://www.whatsapp.com/">
                          <WhatsappShareButton
                            url={
                              "https://lovewithoutreason.org/LWRstory"
                            }
                            quote={"LWR"}
                            hashtag="#lovewithoutreason"
                            image={"https://lovewithoutreason.org/static/media/founder1.62ee5d556b3401b75be9.png"}
                          >
                            <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                          </WhatsappShareButton>
                        </a>

                        <a href="https://twitter.com/">
                          <TwitterShareButton
                            url={
                              "https://lovewithoutreason.org/LWRstory"
                            }
                            quote={"LWR"}
                            hashtag="#lovewithoutreason"
                          >
                            <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                          </TwitterShareButton>
                        </a>

                        {/* <i className="fa fa-clone fa-3x m-1" style={{ color: "#C3C4C4" }}></i> */}
                        <a href="https://www.linkedin.com/">
                          <LinkedinShareButton
                            url={"https://lovewithoutreason.org/LWRstory"}
                            title={"LWR"}
                            summary={"https://lovewithoutreason.org/"}
                            source={"#lovewithoutreason"}
                          >

                            <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                          </LinkedinShareButton>
                        </a>
                        <CopyToClipboard
                          text={
                            "https://lovewithoutreason.org/LWRstory"
                          }
                          onCopy={handleCopy}
                        >
                          <i
                            className="fa fa-clone fa-2x m-1"
                            style={{ color: "#C3C4C4" }}
                          ></i>
                        </CopyToClipboard>
                        <p style={{ fontSize: "8px", textAlign: "end" }}>
                          {urlToCopy !== "" ? urlToCopy : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 mb-3">
              <Link to="/DonateIndia" target="_top">
                <button class="btn btn-danger w-75">
                  Donate <i class="fas fa-duotone fa-hand-holding-heart"></i>

                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export { Founder }
