import React from 'react';
import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Header } from "../Header";
import { Footer } from "../Footer";
import mcamp1 from '../../assests/mcamp1.png';
import mcamp2 from '../../assests/mcamp2.png';
import line from "../../assests/Group 1870 line2.png";
import video from "../../assests/50.mp4";



function MediacalCamps() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);


  return (
    <>
      <Header />
      <section className='mcamps mt-5'>

      </section>

      <section style={{ backgroundColor: "#1A1E27", minHeight: '70vh' }}>
        <div className='container'>
          <div className='row d-flex justify-content-center align-items-center p-5'>
            <div className='col-md-7'>
              <h4 className='text-danger fw-bold mt-5' >GREAT AFRICAN ADVENTURE</h4>
              <p className='india-text text-white mt-4'>
              Love Without Reason medical camps are held in various countries around the world. We invite volunteers to join us with your unique skills, experience, and talent to change the children’s smiles across the world.
              </p>
              <p className='india-text text-white'>
              Do you have the education, gifts, skills, or the desire to serve? Whether you are a health care worker, teacher, IT specialist, or advocate passionate about helping the vulnerable, fill out the volunteer form today!
              </p>
            </div>
            <div className='col-md-4 mt-5'>
              <img className='img-fluid ' src={mcamp1} />
            </div>
            <div className='col-md-11 mt-3'>
              <p className='india-text text-white' >
              As a Medical Camp Volunteer team member, you engage with patients and work alongside clinicians as you work directly with communities in Zimbabwe, Kenya, and India. You will find yourself contributing your skills as social workers, public health educators, and on-the-ground workers
              </p>
            </div>
          </div>

        </div>

      </section>





      <section style={{ minHeight: '80vh' }}>
        <div className='container'>
          <div className='row d-flex justify-content-center align-items-center p-5'>
            <div className='col-md-7'>
              <h4 className='text-danger fw-bold '>BECOME A MEDICAL CAMP VOLUNTEER</h4>
              <p className='mt-3 india-text' >
              Volunteers are encouraged to sign up for the upcoming camps by using the online forms. 
              </p>
              <p className='india-text mt-lg-5'>
              The fees for the camp will vary based on the location and number of days that you wish to volunteer. Fees will include travel, lodging, meals, and donation to the organization. Love Without Reason strives to make it feasible for everyone to travel together and reduce costs where possible.
              </p>

            </div>
            <div className='col-md-4'>
              <img className='img-fluid' src={mcamp2} />
            </div>
            <div className='col-md-11'>
              <p className='india-text mt-3' >
              Love Without Reason encourages businesses, community organizations, or close friends to become part of a Medical Camp Volunteer team.
              </p>
            </div>
          </div>

        </div>

      </section>

      <section style={{ minHeight: '100vh' }}>
        <div className='container  mt-3'>
          <div className='row d-flex justify-content-center'>
            <div className='col-md-6'>
              <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src={video} frameborder="0" mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"></iframe>
            </div>

            <div className='row d-flex justify-content-center mb-5'>
              <div className='col-md-10 text-start mt-4'>
                <h3 className='fw-bold text-danger'>FREQUENTLY ASKED QUESTIONS</h3>
                <div className='col-md-12 text-start mt-5'>
                  <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header ><b>What do I need to do to travel to a different country? </b></Accordion.Header>
                      <Accordion.Body>
                        First and foremost, make sure you have your up-to-date passport and identification. Consult with your primary health care provider to verify your medications, vaccination status, and recommendations.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header><b>What documentation should I gather? </b></Accordion.Header>
                      <Accordion.Body>
                        Visas are required to enter a new country. Once you register with Love Without Reason, these steps will be shared with you based on the country.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header><b>How soon should I start to make sure I have the proper forms?</b></Accordion.Header>
                      <Accordion.Body>
                        A good rule-of-thumb would be to check all your documents at least six months before your scheduled trip.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header><b>What kind of things should I pack?</b></Accordion.Header>
                      <Accordion.Body>
                        Your standard packet of toiletries is a must and it’s helpful to have a travel adapter for your electronics. Based on the climate of your destination country, a list will be shared with you of recommended items.

                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />

    </>
  )
}

export default MediacalCamps
