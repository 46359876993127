import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { useState, useEffect } from 'react';
import axios from 'axios';


function Plannedgift() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);

  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)

    // }).catch((err) => {
    //   alert('Failed to Submit')
    //   console.log(err);
    // })

  };
  return (
    <>
      <Header />
      <section
        className="legacygiftsbg "
        style={{ minHeight: "110vh" }}
      ></section>

      <section style={{ minHeight: "68vh" }}>
        <div className="container p-3 ">
          <div className="row  d-flex justify-content-between mt-3">
            <div className="col-md-3 text-center">
              <h4 className="border-bottom w-100 border-3 border-danger fw-bold">
                LEGACY/PLANNED GIFTS
              </h4>
            </div>

            <div className="col-md-3 text-center mb-1 mt-2">
              <button
                className="btn btn-danger fw-bold"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                CONTACT US
              </button>
              {/* <!-- Modal --> */}
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-lg ">
                  <div class="modal-content ">
                    <div class="modal-header border-0 ">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h4
                            class="modal-title fw-bolder text-center mb-5 mt-0"
                            id="exampleModalLabel"
                          >
                            Legacy Planned Gifts Form
                          </h4>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <form onSubmit={sendEmail} className="text-center">
                            <div className="row mb-2 ">
                              <div className="col-md-6">
                                <input
                                  class="form-control"
                                  placeholder="First Name"
                                  name="firstname"
                                  type="text"
                                  onChange={(e) => { userDetails(e) }}
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  class="form-control"
                                  placeholder="Last Name"
                                  type="text"
                                  name="lastname"
                                  onChange={(e) => { userDetails(e) }}
                                />
                              </div>
                            </div>
                            <div className="row  mb-3">
                              <div className="col-md-6">
                                <input
                                  class="form-control"
                                  placeholder="Phone number"
                                  type="number"
                                  name="number"
                                  onChange={(e) => { userDetails(e) }}
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  class="form-control"
                                  placeholder="Email"
                                  type="email"
                                  name="email"
                                  onChange={(e) => { userDetails(e) }}
                                />
                              </div>
                            </div>
                            <div className="row ">
                              <p className="fs-6 ">Can you tell us some details about your plans for legacy giving. </p>
                              <div className="col-md-12">
                                <textarea
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  placeholder="Message"
                                  type="text"
                                  name="message"
                                  onChange={(e) => { userDetails(e) }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="row d-flex justify-content-center mt-2">
                              <div className="col-md-12 text-center">
                                {/* <div className="d-flex">
                                <p className="text-dark m-1 text-start">
                                  Thank you for submitting your application,one
                                  of our Love Without Reason staff will contact
                                  you in the next few days.
                                </p>
                              </div> */}
                                <button
                                  className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                  type="submit"
                                  data-bs-toggle="modal" data-bs-target="#exampleModalgifts"
                                >
                                  Submit
                                </button>
                                <div class="modal fade" id="exampleModalgifts" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                      {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                      <div class="modal-body">
                                        <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                        <hr />

                                        <div className="row d-flex justify-content-center">
                                          <div className="col-md-12 fs-5">
                                            Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                          </div>
                                        </div>
                                      </div>
                                      <div class="modal-footer border-0">
                                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-md-12  mt-4">
              <p style={{ fontSize: "18px" }}>
                This type of giving empowers generous individuals to make
                significant gifts to Love Without Reason. These gifts are
                substantial in helping Love Without Reason to continue providing
                craniofacial surgeries, empowering the women of Project
                Butterfly, and increasing the frequency of international medical
                camps.
              </p>

              <p style={{ fontSize: "18px" }}>
                Below is a partial list of suggestions for charitable
                contributions. Your tax and legal professionals may have
                additional ideas which are optimal for your personal goals.
              </p>
              <h6 className="mb-4 fw-bold" style={{ fontSize: "18px" }}>
                your personal goals.
              </h6>
              <p style={{ fontSize: "18px" }}>
                {" "}
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Bequests
              </p>
              <p style={{ fontSize: "18px" }}>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Gift of
                Retirement
              </p>
              <p style={{ fontSize: "18px" }}>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Charitable
                Lead Trust
              </p>
              <p style={{ fontSize: "18px" }}>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Pooled
                Income Fund Gift of Life Insurance
              </p>
              <p style={{ fontSize: "18px" }}>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Charitable
                Remainder Annuity Test
              </p>
              <p style={{ fontSize: "18px" }}>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Charitable
                Remainder Unitrust
              </p>
              <p style={{ fontSize: "18px" }}>
                <i class="fa-solid fa-circle text-danger"></i> &nbsp;Life Income
                Gift
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Plannedgift };
