import React from "react";
import { Link } from "react-router-dom";
import kenyacamp from "../../assests/Kenyacamposter.jpg";
import line from "../../assests/Group 1870 line2.png";
import kenya1 from "../../assests/kenyaimg.png";
import kenya2 from "../../assests/kenya22.png";
import kenya3 from "../../assests/Group 1978.png";
import kenya4 from "../../assests/kenya33.png";
import kenya5 from "../../assests/bongo.jpg";
import kenya6 from "../../assests/cecilia.jpg";
import kenya7 from "../../assests/kenya image 2(reworked).png";
import paypal from '../../assests/usapaypal.png';
import kenyatill from '../../assests/kenyatillnumber.png';
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import india1 from "../../assests/Slide 3.png";
import india2 from "../../assests/Slide 5.png";
import india3 from "../../assests/Slide 4.png";


import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";

import { Header } from "../Header";
import { Footer } from "../Footer";

function Kenya() {
  const [urlToCopy, setURLToCopy] = useState("");

  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  console.log("urlToCopy", urlToCopy);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      <section className="kenya mt-5" style={{ minHeight: "100vh" }}>

        <div className="container mt-5 my-auto">
          <div className="row mt-5 d-flex justify-content-end align-items-end">
            <div className="col-md-3 mt-5 text-center">
              <img src={kenyatill} className="img-fluid shadow-sm" />
            </div>
            <div className="col-md-2 mt-5 text-center">
              <Link to='https://www.paypal.com/donate/?hosted_button_id=CHDYF6D2KDTVQ' target="_blank"><img src={paypal} className="img-fluid shadow-sm rounded" /></Link>

            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container mt-3">
          {/* <div className="row d-flex justify-content-center ">
            <div className="col-md-12 mt-2 text-center">
              <h4 class=" text-dark fw-bolder mb-0 mt-2">KENYA</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div> */}
          <div className="row d-flex justify-content-center mt-5 align-items-center">

            <div className="col-md-6 mt-2 india-text">
              <h3 className="fw-bolder">LOVE WITHOUT REASON <span className="text-danger">KENYA</span></h3>
              <p className="india-text">

                In Northern Kenya, roughly 46 percent of the population earns less
                than 108.10 Kenyan shilling, the equivalent of one dollar a day.
                Kenya is also the world's 48th largest country. In Kenya, families
                must travel to reach the main cities to access the minimal
                healthcare resources. This often means a trip over 300 kilometers
                or 186 miles.<br /><br />
                This severe poverty has impacted so many children born with craniofacial differences. The journey is on rough terrain, is costly, and risky for a cleft baby. Love Without Reason is working with the Kenyan leadership to plan surgical camps in partnership with amazing hospitals, such as Kenyatta University Teaching Research and Referral Hospital (KUTRRH). Your donations will help fund the next medical camp which we call <b>"The Great African Adventure."</b>


              </p>
            </div>
            <div className="col-md-4 mt-2">
              <img className=" img-fluid rounded" src={kenya1} />
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{ minHeight: "70vh" }}>
        <div className="container">
          <div className="row mt-5 d-flex justify-content-center align-items-center">
            <div className="col-md-3 text-center">
              <img src={kenyacamp} className="img-fluid border shadow-sm" alt="Kenya-Flyer" />
              <a href={kenyacamp} download="Kenya-Flyer.jpg" className="btn btn-danger mt-3 mb-3 d-sm-block d-md-none">Download Flyer</a>
            </div>

            <div className="col-md-7">
              <h3 className="fw-bolder">Free Cleft Lip & Palate Medical Camp</h3><br />
              <p className="india-text">

                As part of our world-wide Cleft-Free initiative, Love Without Reason will be at the Kenyatta University Teaching Research and Referral Hospital (KUTRRH) in Ruiru, Kenya, from June 16 - 21, 2024,  for a Free Cleft Lip and Palate Medical Camp. Patients with craniofacial anomalies (Cleft lip and Palate) are welcome to register for the camp by calling <b>0746704300 / 0791899855</b>.<br /><br /> Screening will begin at 8am on June 16th and patients will be seen on a first come first serve basis. Surgeries for selected patients will begin from June 17, 2024.
              </p>
              <a href={kenyacamp} download="Kenya-Flyer.jpg" className="btn btn-danger d-none d-lg-block w-25">Download Flyer</a>

            </div>

          </div>
        </div>

      </section> */}

      <section className="kenya-global mt-5" style={{ minHeight: "100vh" }}>
        <div className="container mt-5">
          <div className="row  d-flex align-items-center mt-5 pt-5">
            <div className="col-md-12 text-white text-end mt-5 pt-5">
              <h1 className="fw-bolder mt-5 pt-5">244</h1>
              <h5 className="fw-bolder">NUMBER OF SURGERIES</h5>
            </div>
          </div>
        </div>
      </section>




      <section style={{ minHeight: "100vh" }}>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-md-12 mt-5 text-center">
              <h4 class=" text-dark font-weight-bold mb-0 fw-bolder">
                Kenyan Stories
              </h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div
              class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={kenya2} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">TRAUMA AFTER TRAUMA</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  In a small village in Kenya sixty years ago, little Mary was born with something no one had ever seen, a cleft lip. While her siblings went to school, Mary's parents hid her in their hut. Human trafficking through the form of child marriage.... {" "}
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            <div
              class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
              style={{ backgroundColor: "#134E5E" }}
            >
              <img src={kenya3} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">
                  MORE TIME TO GROW
                </h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Love Without Reason 2020 Kenya medical camp, like all camps, started with a screening process. Typically, surgery for cleft lip is performed between three to six months of age and depends on the child's health and strength to handle surgery....{" "}
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop11"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            <div
              class="col-md-4 text-center rounded shadow-sm border border-0 mt-3"
              style={{ backgroundColor: "#1A1E27" }}
            >
              <img src={kenya4} class="img-fluid rounded" alt="Image" />
              <div class="card-body text-white p-2">
                <h4 class="card-title fw-bolder">CECILIA'S STORY</h4>
                <p
                  class="card-text "
                  style={{ fontSize: "14px" }}
                >
                  Living with craniofacial differences in societies dominated by looks is hard. This is made even more painful for those in countries where lack of awareness of the condition, limited access to healthcare, and poverty become....
                </p>
                <button
                  type="button"
                  class="btn btn-light rounded px-4 mb-3 fw-bolder"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop33"
                  style={{ fontSize: "14px" }}
                >
                  READ FULL STORY
                </button>
              </div>
            </div>

            {/* MOdal1 */}
            <div
              class="modal fade"
              style={{ maxWidth: "100%" }}
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="modal-body ">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded " src={kenya2} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark font-weight-bold fw-bolder mt-2 mb-0">
                          TRAUMA AFTER TRAUMA
                        </h4>

                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className=" india-text">
                          In a small village in Kenya sixty years ago, little Mary was born with something no one had ever seen, a cleft lip. While her siblings went to school, Mary's parents hid her in their hut.<br /><br />

                          Human trafficking through the form of child marriage is prevalent for those born with craniofacial differences. Mary's parents married her to a fifty-four-year-old man by the time she turned fifteen. For years her experience as a wife was to a husband that abused and tormented her. Mary worked as a maid. She gave birth to eight children and buried four of them.<br /><br />

                          Mary's adult children persisted in bringing their mother to various cleft camps in hopes of being selected for surgery. Mary and her children continued to feel disappointment at Mary not being chosen for surgery. Love Without Reason hosted its first 2018 Nakuru medical camp in Kenya. Mary's children were delighted when the surgical team cleared their mother for surgery. Mary had a successful cleft lip repair. Not only does she have a brilliant and genuinely contagious smile, but she has also become an advocate for those struggling with craniofacial differences.<br /><br />

                          In February 2020, Love Without Reason hosted the Nairobi medical camp. Mary joined as a volunteer and supported parents who were anxious and scared to send their children for surgery. She bravely shared her story comforting the mothers and patients. "People have come from all around the neighboring villages to visit me in my home, '' she continued. They all knew me as the woman with a hole in her lip. But now they see me whole and celebrate with me. I am so thankful for the care and support of Love Without Reason. They have helped to remove my shame and have given me joy."



                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia1"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                NO MORE WITHOUT A NAME
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://www.whatsapp.com/">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/Kenya"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/Kenya"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/Kenya"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/Kenya"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto mb-3">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia1"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100 "
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="staticBackdrop11"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={kenya3} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                          MORE TIME TO GROW
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className="text-justify india-text">
                          Love Without Reason 2020 Kenya medical camp, like all camps, started with a screening process. Typically, surgery for cleft lip is performed between three to six months of age and depends on the child's health and strength to handle surgery. Our team had the honor of meeting Peter and his new son Junior, who had been born with a cleft lip a few weeks earlier. Little Junior needed more time to grow before he would be able to undergo surgery.<br /><br />

                          Love Without Reason recorded Peter and Junior’s family contact information for future camps or other medical camps to help children with cleft repairs. The world, suddenly impacted by the pandemic, lockdowns and travel bans, halted all medical camps in Kenya.<br /><br />

                          Like so many in the pandemic, Peter lost his job as he could not farm the land without access to supplies. What meager resources his family had left were dwindling quickly. In the middle of Peter's heartache and worry for his family's health, he received an unexpected call from a Love Without Reason volunteer in Kenya.<br /><br />

                          Thanks to generous donors, the Love Without Reason team reached out....<br /><br />

                          Our donors continue to transform the lives of children and their families even when the times and seasons change.

                          {" "}
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia2"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                SIX MISCARRIAGES IN 20 YEARS
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://www.whatsapp.com/a">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/Kenya"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/Kenya"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >

                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>
                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/Kenya"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/Kenya"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ms-auto ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropindia2"
                        >
                          Share <i class="fa-solid fa-share"></i>
                        </button>
                      </div>
                      <div className="col-md-2 ">
                        <button
                          type="button"
                          class="btn btn-danger w-100"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="staticBackdrop33"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content modelscroll">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-6">
                        <img className="img-fluid rounded" src={kenya4} />
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 text-center">
                        <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                          CECILIA'S STORY
                        </h4>
                        <img className=" img-fluid" src={line} />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-2">
                      <div className="col-md-10 mt-2">
                        <p className="india-text ">
                          Living with craniofacial differences in societies dominated by looks is hard. This is made even more painful for those in countries where lack of awareness of the condition, limited access to healthcare, and poverty become barriers to solutions. These babies become children, teenagers, and adults who feel that living a 'normal' life seems impossible.<br /><br />

                          Love Without Reason is an unusual medical team that evaluates each potential patient with surgical and anesthesia consultants. A thorough exam will help the medical professionals determine each patient's health and determine if they have a final clearance for surgery. Children and adults with clefts are prone to lung infection, feeding issues, and even other medical concerns.<br /><br />

                          We often hear about the power of a mother's love. Cecilia was one of those mothers. Cecilia arrived at the medical camp in Nairobi. This time, Cecilia's grown adult children brought their elderly mother to find out if this medical camp might consider her for a cleft lip repair. She kept her eyes to the ground, even when we approached her to greet her. Her children wanted to give their mother confidence as she had always given them. Her children asked the team, "Can anyone operate on our mother? Even though she is over 70 years of age?"<br /><br />

                          Cecilia was cleared for surgery by the entire medical team. The children's questions were full of excitement, anxiety, and anticipation. "What will she look like? Has she been in pain all her life because of her cleft lip? Cecilia had given a life full of love. It was comforting for her to be accompanied to the hospital by her children, grandchildren, and great-grandchildren. They all wanted to be with their "Go-Go." When Cecilia left for surgery, the family was well aware of the risks of surgery, but they again held on to hope.<br /><br />

                          When their mother was released from recovery and the bandages were removed, the family erupted in shouts of joy. Mama Cecilia had a new smile, and this time the smile reached her eyes. As she was discharged from the hospital, she looked Santhosh Mathews, co-founder of Love Without Reason, straight in the eye. "You come to my home, and I will cook you goat curry from my herd." The entire team laughed at the tremendous physical and emotional transformation in Cecilia.<br /><br />

                          Your donations make a difference. Let's not allow others to live a life where they are asked, "Why hasn't anyone helped her in all these years?"

                          {" "}
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        class="modal fade"
                        id="staticBackdropindia3"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                          <div class="modal-content">
                            <div class="modal-header p-3">
                              <h5
                                class="modal-title fw-bolder"
                                id="staticBackdropLabel"
                              >
                                THE 500TH
                              </h5>
                              <button
                                type="button"
                                class="btn-close border"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                  <a href="https://www.facebook.com/login/">
                                    <FacebookShareButton
                                      url={
                                        "https://lovewithoutreason.org/AntiHumanTrafficking"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                    </FacebookShareButton>
                                  </a>
                                  <a href="https://www.whatsapp.com/">
                                    <WhatsappShareButton
                                      url={
                                        "https://lovewithoutreason.org/Kenya"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                    </WhatsappShareButton>
                                  </a>
                                  <a href="https://twitter.com/">
                                    <TwitterShareButton
                                      url={
                                        "https://lovewithoutreason.org/Kenya"
                                      }
                                      quote={"LWR"}
                                      hashtag="#lovewithoutreason"
                                    >
                                      <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-dark"></i>

                                    </TwitterShareButton>
                                  </a>
                                  <a href="https://www.linkedin.com/">
                                    <LinkedinShareButton
                                      url={"https://lovewithoutreason.org/Kenya"}
                                      title={"LWR"}
                                      summary={"https://lovewithoutreason.org/"}
                                      source={"#lovewithoutreason"}
                                    >

                                      <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                    </LinkedinShareButton>
                                  </a>
                                  <CopyToClipboard
                                    text={"https://lovewithoutreason.org/Kenya"}
                                    onCopy={handleCopy}
                                  >
                                    <i
                                      className="fa fa-clone fa-3x m-1"
                                      style={{ color: "#C3C4C4" }}
                                    ></i>
                                  </CopyToClipboard>
                                  <p
                                    style={{
                                      fontSize: "8px",
                                      textAlign: "end",
                                    }}
                                  >
                                    {urlToCopy !== "" ? urlToCopy : ""}
                                  </p>
                                  {/* <a href="https://www.instagram.com/">
                                    <i
                                      className="fa-brands fa-square-instagram fa-3x m-1"
                                      style={{ color: "#E4405F" }}
                                    ></i>
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-5  text-center">
              <h4 class="text-dark  mb-0 fw-bold">MUMBAI</h4>
              <img className=" img-fluid" src={line} />
            </div>
          </div> */}

        </div>
      </section>


      <Footer />
    </>
  );
}

export { Kenya };
