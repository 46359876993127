import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from "react";

function PersonalProperty() {
  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // .then((res) => {
    //   alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 1000)

    // }).catch((err) => {
    //   alert('Failed to Submit')
    //   console.log(err);
    // })

  };
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section
        className="PersonalProperty "
        style={{ minHeight: "100vh" }}
      ></section>

      <section style={{ minHeight: "70vh" }}>
        <div className="container p-4 ">
          <div className="row  d-flex justify-content-between ">
            <div className="col-md-3 mt-3 text-center">
              <h4 className="border-bottom w-100 border-3 fw-bold border-danger">
                PERSONAL PROPERTY
              </h4>
            </div>

            <div className="col-md-3 mt-3 text-center">
              <button
                className="btn btn-danger fw-bold"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                CONTACT US
              </button>
            </div>
            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg ">
                <div class="modal-content ">
                  <div class="modal-header border-0 ">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h4
                          class="modal-title fw-bolder text-center mb-5 mt-0"
                          id="exampleModalLabel"
                        >
                          Personal Property Donation
                        </h4>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <form onSubmit={sendEmail} className="text-center">
                          <div className="row mb-2 ">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="First Name"
                                name="firstname"
                                type="text"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Last Name"
                                type="text"
                                name="lastname"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row  mb-3">
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Phone number"
                                type="number"
                                name="number"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                class="form-control"
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={(e) => { userDetails(e) }}
                              />
                            </div>
                          </div>
                          <div className="row ">
                            <p className="fs-6"> Can you tell us some details about your desire to donate your property.</p>
                            <div className="col-md-12">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Message"
                                type="text"
                                name="message"
                                onChange={(e) => { userDetails(e) }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-12 text-center">
                              <div className="d-flex">
                                {/* <p className="text-dark m-1 text-start">
                                  Thank you for submitting your application,one
                                  of our Love Without Reason staff will contact
                                  you in the next few days.
                                </p> */}
                              </div>
                              <button
                                className="btn btn-danger mt-3 fw-bold align-items-center px-3"
                                type="submit"
                                data-bs-toggle="modal" data-bs-target="#exampleModalpersonal1"
                              >
                                Submit
                              </button>
                              <div class="modal fade" id="exampleModalpersonal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    {/* <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div> */}
                                    <div class="modal-body">
                                      <h5 class="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                                      <hr />

                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-12 fs-5">
                                          Thank you for contacting Love Without Reason. We appreciate your kindness, generosity and support.  One of our team members will contact you as soon as possible.
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer border-0">
                                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-md-12  mt-5">
              <p style={{ fontSize: "18px" }}>
                Your personal property gift will allow you to deduct the item's
                value. Personal property gifts could range from paintings, land,
                antique cars, and other collectibles. Upon receipt of your
                donation, we will issue you a donation letter for tax purposes.
              </p>

              <p style={{ fontSize: "18px" }}>
                “The federal tax code allows individuals and businesses to make
                non-cash contributions to qualifying charities and to claim
                deductions for these contributions on their tax returns. Gifts
                of donated property, clothing, and other non-cash items have
                long been an essential revenue source for many charitable
                organizations and a popular deduction for taxpayers.
              </p>
              <p style={{ fontSize: "18px" }}>
                The American Jobs Creation Act of 2004 created additional
                reporting requirements for individual taxpayers making non-cash
                charitable contributions. This website provides information for
                contributors, philanthropic organizations, and tax professionals
                who represent them about federal tax requirements for donated
                property.” IRS Personal Property Information{" "}
                <a
                  class=" text-danger"
                  href="https://www.irs.gov/pub/irs-pdf/p561.pdf"
                >
                  <b>Publication 561 (Rev. February 2020) (irs.gov) </b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { PersonalProperty };
