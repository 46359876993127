import React from "react";
import line from "../../assests/Group 1870 line2.png";
import Run_1 from "../../assests/5kpress_11.png";
import Run_2 from "../../assests/event5knew.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from "react";
import logo2 from "../../assests/LWRblue.png";
import philipimg from "../../assests/5kphilip.png";
import image_11 from "../../assests/event5kimg2.png";

function Press5k() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="mt-5" style={{ minHeight: "70vh" }}>
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-md-12 mt-5">
              <h4 class="text-center text-dark fw-bold text-uppercase">
                Love Without Reason Hosts its Annual
                “Race to the Dream” <br />Fundraiser This Saturday, September 9!
              </h4>
            </div>
          </div>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-6 px-0">
              <img className="img-fluid" src={Run_1} />
            </div>
            <div className="col-md-6" style={{ background: "#00294E" }} >
              <p className="text-white px-5 py-5">
                “It is our mission to carry out our son Philip’s dream to
                work ‘Until the World is Cleft Free!’ All proceeds from this
                annual fundraiser will help provide all our needs to
                continue craniofacial surgeries and Human Trafficking
                Awareness Ministry here in the United States, in India,
                Kenya, Uganda, Zimbabwe, and allow us to expand into
                Tanzania,” states Chief Executive Officer and President of
                Love Without Reason, Santhosh Mathews.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-6" style={{ background: "#00294E" }} >
              <p className="text-white px-5 py-5">
                September 5, 2023 – Chattanooga,
                Tenn- Registration is still open to run in the annual
                Race to the Dream 5K, 10K, and Big Wheel Race. The run will take
                place this Saturday, September 9, at the Tennessee River Park,
                near the Frye Center, beginning at 9:30 AM. Registration will be
                open the day of the race at 8:00AM - 9:00 AM, or folks can
                register online at LoveWithoutReason.org/Events.
              </p>
            </div>
            <div className="col-md-6 px-0">
              <img className="img-fluid" style={{ width: '100%' }} src={Run_2} />
            </div>
          </div>
        </div>
      </section>

      <section className="" style={{ minHeight: "40vh" }}>
        <div className="container mt-5">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-2 text-center">
              <img className="img-fluid  " src={logo2} alt="Navbar Logo" />
            </div>
            <div className="row d-flex justify-content-center ">
              <div className="col-md-12 text-center mt-2">
                <p className="text-dark">
                  For more information on this event or Love Without Reason
                  visit LoveWithoutReason.org
                </p>
                <p className="text-dark">
                  About Love Without Reason Founded in 2007 by Santhosh and
                  Susan Mathews in Chattanooga,Tennessee. Love Without Reason is
                  a nonprofit organization serving in the United States and
                  sixcountries transforming faces of those born with cleft and
                  craniofacial differences andtransforming hearts of those
                  rescued from human trafficking. The late Philip Mathews’
                  dreamwas to work “Until the World is Cleft Free and Until
                  there are No More Sold.” The Mathews are committed to seeing
                  Philip’s Dream fulfilled.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pressurl" style={{ minHeight: "100vh" }}></section>
      <section className="" style={{ background: "#fff" }}>
        <div className="container ">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-12 text-center p-4">
              <p className="text-dark mt-2 ">
                Also, during the event on Saturday, a special recognition
                honoring internationally renowned surgeon, Dr. Larry Sargent
                will take place. Dr. Sargent founded and served as director of
                the Craniofacial Center at Children’s Hospital at Erlanger in
                Chattanooga. The Craniofacial Center has served patients with
                craniofacial differences in over 45 states and several
                countries. When Susan and Santhosh Mathews, the founders of Love
                Without Reason, had their child Philip Mathews, Dr. Sargent was
                among the first to meet Philip in Erlanger’s NICU in July of
                2000.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export { Press5k };
