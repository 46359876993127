import React from "react";
import line from "../../assests/Group 1870 line2.png";
import susan from "../../assests/susanfounder.png";
import img1 from "../../assests/Pramod Mathews.png";
import img2 from "../../assests/santhosh.png";
import img3 from "../../assests/KrishnaRaj.png";
import img4 from "../../assests/Dr. Linda Kurian, M.D.2.png";
import img5 from "../../assests/Pastor Lisa Bourland.png";
import { useEffect } from "react";

import { Footer } from "../Footer";
import { Header } from "../Header";

function Director() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="Director" style={{ minHeight: "100vh" }}>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12 text-center mt-5">
              <h3 className="text-white fw-bold mt-5 mb-0">EXECUTIVE BOARD OF DIRECTORS</h3>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>
        </div>
      </section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="text-dark fw-bold mt-2 mb-0">EXECUTIVE BOARD OF DIRECTORS</h2>
              <img className="img-fluid" src={line} alt="Line" />
            </div>
          </div>
          <div className="row d-flex justify-content-center text-center">
            <div className="col-md-12 p-2">
              <p style={{ fontSize: "20px" }}>
                Each member of our Board of Directors is invaluable in
                establishing our organization's short-term and long-term
                objectives and essential operational decisions.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-4">
              <img className="img-fluid" src={susan} alt="Susan" />
              <h5 className="text-center text-danger fw-bold">BOARD OF DIRECTORS, CHAIR CHIEF MEDICAL OFFICER</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-5 mb-5 text-center ">
          <div className="col-md-4">
              <img className="img-fluid mt-2" src={img2} alt="President & CEO" />
              <h5 className="text-center text-danger fw-bold">PRESIDENT & CEO</h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={img5} alt="Board Member 4" />
              <h5 className="text-center text-danger fw-bold">Board Member</h5>
            </div>
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={img1} alt="Board Member 1" />
              <h5 className="text-center text-danger fw-bold">Board Member</h5>
            </div>
           
            
          </div>
          <div className="row d-flex justify-content-center mt-5 mb-5 text-center">
            <div className="col-md-4">
              <img className="img-fluid" src={img4} alt="Board Member 3" />
              <h5 className="text-center text-danger fw-bold">Board Member</h5>
            </div>
          
            <div className="col-md-4">
              <img className="img-fluid mt-2" src={img3} alt="Board Member 2" />
              <h5 className="text-center text-danger fw-bold">Board Member</h5>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export { Director };
