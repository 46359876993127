import React from 'react'
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";

import lwr2 from "../../assests/lwrs4.png";
import { useEffect } from 'react';



function Ourmission() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section id='ourmission' style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row mt-5 ">
            <div className="col-md-12 text-center mt-5">
              <h3 className="fw-bold text-dark mb-0 mt-5">OUR MISSION</h3>
              <img src={line} className="img-fluid " />
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col md-8 ms-5">
              <h4 className="fw-bold text-danger"></h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                {/* We are intentionally respectful of local culture but direct in
                our communication to empower new mindsets and life trajectories. */}
              </p>

              <h4 className="fw-bold text-danger"></h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                Our dedicated mission of love is to build a foundation of self-respect and value for each human life.
              </p>

              <h4 className="fw-bold text-danger"></h4>
              <p className="text-dark" style={{ fontSize: "20px" }}>
                {/* Our organization's delivery design is to be sustainable, bring
                acceptance, and inspire community humanitarianism. */}
              </p>
            </div>
            <div className="col-md-4 text-start">
              <img className=" img-fluid " src={lwr2} />
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default Ourmission
