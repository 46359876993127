import React from 'react'
import { Header } from '../Header'
import line from '../../assests/Group 1870 line2.png';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer'

function SmilewithSanta() {
    useEffect(() => {
        // Set the scroll position when the component mounts
        window.scrollTo(0, 0); // Adjust the '500' value as needed
    }, []);
    return (
        <>
            <Header />
            <section className='SmilewithSanta mt-5' style={{ minHeight: '100vh' }}>
                <div className='container mt-5' style={{ position: "relative" }}>
                    <div className='row d-flex justify-content-center mt-5'>
                        <div className='col-md-3'>
                            <Link to='https://allevents.in/chattanooga/smile-with-santa/80002148929659' target='_blank' className='btn btn-light mt-5 santabutton ms-5 btn-lg' style={{ position: "absolute", left: "-35px", top: '500px' }}>Register</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-3' style={{ minHeight: '90vh' }}>
                <div className='container'>
                    <div className="row d-flex justify-content-center align-items-center ">
                        <div className="col-md-12 text-center">
                            <h2 class=' text-dark fw-bold mb-0' >Smile With Santa</h2>
                            <img className=" img-fluid" src={line} />
                        </div>
                        <div className='col-md-12'>
                            <p className='india-text text-center mt-3'>The laughter of children is a beautiful part of the Christmas spirit.  Bring your child to Smile with Santa  with Chattanooga’s very own Santa Miles.  Join with the Love Without Reason family on Saturday, December 9, 2023, from 12 -2pm at the Eastgate Town Center, Entrance B.  Children may take their picture with Santa, decorate their own cookie, write a letter to Santa with the entry fee of $15.  Proceeds from this event bring a new smile to a child waiting for their cleft lip surgery! </p>
                            <p className='india-text text-center mt-5 rounded bg-dark p-5 text-white '>While you are at the event, be sure to get your meaningful Christmas gifts from The Butterfly Shop.  Check out the artisan crafted jewelry from Kenya, the comfortable dresses and scarves from Zimbabwe and the totes and makeup bags that were stitched at Love Without Reason’s Project Butterfly Center in India.  Young women who come to the Project Butterfly Center are either at-risk to human trafficking or are survivors of human trafficking.  Your purchase supports their efforts in sustainable business and education and breaks the cycle of slavery!  <br />
                                Click here to register for the event today! <br />
                                <Link to='https://allevents.in/chattanooga/smile-with-santa/80002148929659' target='_blank' className='btn btn-danger px-5 mx-auto mt-3'>Register</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />

        </>
    )
}

export default SmilewithSanta
