import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import line from "../../assests/Group 1870 line2.png";
import lwr1 from "../../assests/lwrstorysan1.png";
import lwr2 from "../../assests/lwrstoryphilip1.png";
import lwrlogo from "../../assests/lwror.jpeg";
import aboutus1 from "../../assests/cranio3.png";
import aboutus2 from "../../assests/cranio2.png";

import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from 'axios';
import { useState } from "react";
// import {
//   FacebookShareButton,
//   WhatsappShareButton,
//   TwitterShareButton,
// } from "react-share";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import CopyToClipboard from "react-copy-to-clipboard";


function LWRstory() {
  const [urlToCopy, setURLToCopy] = useState("");
  const shareOnWhatsApp = () => {
    const text = "Check out this amazing story: LWR";
    const url = "https://lovewithoutreason.org/LWRstory";
    const image = "https://lovewithoutreason.org/static/media/founder1.62ee5d556b3401b75be9.png"; // Replace with the URL of your image

    const shareLink = `whatsapp://send?text=${encodeURIComponent(`${text}\n${url}`)}`;

    // Open the WhatsApp share dialog
    window.location.href = shareLink;
  };
  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  console.log("urlToCopy", urlToCopy);
  // useEffect(() => {
  //   const hash = window.location.hash;

  //   if (hash) {
  //     const targetId = hash.slice(1);

  //     const targetElement = document.getElementById(targetId);

  //     if (targetElement) {
  //       targetElement.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, []);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  const [mailData, setMaildata] = useState({
    firstname: '',
    lastname: '',
    number: '',
    email: '',
    message: ''
  });

  const userDetails = (e) => {
    const { name, value } = e.target;
    setMaildata({ ...mailData, [name]: value });
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    const payload = {
      service_id: 'service_tt2kz7w',
      template_id: 'template_d0upv0l',
      user_id: 'ppekGscEBiOpBosKz',
      template_params: {
        'firstname': mailData.firstname,
        'lastname': mailData.lastname,
        'number': mailData.number,
        'email': mailData.email,
        'message': mailData.message
      }
    }
    axios.post('https://api.emailjs.com/api/v1.0/email/send', JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      alert(res.data === 'OK' ? 'Thank you for contacting Love Without Reason. We appreciate your kindness generosity and support.  One of our team members will contact you as soon as possible.' : '');
      setTimeout(() => {
        window.location.reload()
      }, 1000)

    }).catch((err) => {
      alert('Failed to Submit')
      console.log(err);
    })

  };
  return (
    <>
      <Header />
      <section className="aboutus">

      </section>

      <section className="mt-5" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
           

            <div className="col-md-6 mt-5">
              <div className="row mt-5">
                <div className="col-md-4">
                  <h3 className="fw-bolder border-bottom  border-4 border-danger">About Us</h3>
                </div>
              </div>
              <p className="india-text mt-2">
                Love Without Reason was founded on December 11, 2007, in Chattanooga, Tennessee as a United States – based 501(c)3 nonprofit organization by Santhosh and Susan Mathews.  Their hope was to help children who were born like their son, Philip, and provide life-altering facial surgery to those living in countries with limited access to healthcare resources.  After understanding the link between craniofacial differences and human trafficking, they began working with survivors of sex trafficking and learning ways to impact and transform their lives.
              </p>
            </div>
            < div className="col-md-4 mt-5 text-center ">
              <img src={lwrlogo} className="img-fluid" />
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center mt-4">
          <div className="col-md-4">
              <img src={aboutus1} className="img-fluid mt-3" />
            </div>
            <div className="col-md-6">
              <p className="india-text">
                As part of a global initiative, Love Without Reason started providing free cleft surgeries from 2012, in India with partnering hospitals. The doors opened into the continent of Africa in 2017, beginning in Zimbabwe first, then into Kenya and Uganda.<br /><br />
                In 2015, the <b>Project Butterfly Center</b> was born in Mumbai, India, in one of the red-light districts in the city.  The greatest need identified at that time was for organizations to support survivors of sex trafficking in their reintegration into society.  However, during the global pandemic, minors were brought into red-light areas to be sold.  Our team continues to rescue boys and girls and taking them to safe homes, where are they kept safe and given the best environment to grow up without fear of being sold again.
              </p>
            </div>
           
          </div>

          <div className="row d-flex justify-content-center mt-3">
            <div className="col-md-10">
              <p className="india-text">During the pandemic, all surgical camps were placed on hold.  Our Board and leadership researched ways to continue to make an impact on families and termed them <b>Innovative Solutions.</b>  Villages in rural areas of many developing nations live without clean drinking water, and diseases are rampant because of contaminated water.  By digging <b>water wells</b>, the communities have been impacted, both physically and spiritually.  </p>
            </div>
          </div>

          <div className="row d-flex justify-content-center align-items-center mt-3">
            <div className="col-md-4 mt-3">
              <img src={aboutus2} className="img-fluid" />

            </div>
            <div className="col-md-7">
              <p className="india-text">

                Secondly, the ladies in Project Butterfly began to fine-tune the stitching of the reusable sanitary pads called Set Free Pads.  These were created and distributed in the red-light areas by our own team members.  Product sales go back to provide steady income for the ladies in Project Butterfly and provide business skills for them.  The health of these ladies is improved which will ultimately impact the children that they are raising as well.<br/><br/>
                Finally, education is critical to reducing the crime of human trafficking.  Providing training seminars and conferences on human trafficking and leadership on a global scale is making a global difference.
              </p>
            </div>
          </div>

          


          <div className='row  d-flex justify-content-center mb-5 mt-5'>
            <div class='col-md-6'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/SJEVCz7Jd-o?si=BWDc2l_L8z4b_Jk3" title="PHILIP MATHEWS - LIFESTORY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Envisioning a World Cleft-Free</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-md-2 mb-3">
              <button
                type="button"
                class="btn btn-danger w-75 "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropindia16"
              >
                Share <i class="fa-solid fa-share"></i>
              </button>
            </div>
            <div
              class="modal fade "
              id="staticBackdropindia16"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                  <div class="modal-header p-3">
                    <h5
                      class="modal-title fw-bolder text-dark"
                      id="staticBackdropLabel"
                    >
                      LWR STORY
                    </h5>
                    <button
                      type="button"
                      class="btn-close border"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12">
                        <a href="https://www.facebook.com/login/">
                          <FacebookShareButton
                            url={
                              "https://lovewithoutreason.org/LWRstory"
                            }
                            quote={"LWR"}
                            hashtag="#lovewithoutreason"
                            image={"https://lovewithoutreason.org/static/media/founder1.62ee5d556b3401b75be9.png"}
                          >
                            <i class="fa-brands fa-square-facebook fa-2x m-1 text-primary"></i>
                          </FacebookShareButton>
                        </a>
                        <a href="https://www.whatsapp.com/">
                          <WhatsappShareButton
                            url={
                              "https://lovewithoutreason.org/LWRstory"
                            }
                            quote={"LWR"}
                            hashtag="#lovewithoutreason"
                            image={"https://lovewithoutreason.org/static/media/founder1.62ee5d556b3401b75be9.png"}
                          >
                            <i class="fa-brands fa-square-whatsapp fa-2x m-1 text-success"></i>
                          </WhatsappShareButton>
                        </a>

                        <a href="https://twitter.com/">
                          <TwitterShareButton
                            url={
                              "https://lovewithoutreason.org/LWRstory"
                            }
                            quote={"LWR"}
                            hashtag="#lovewithoutreason"
                          >
                            <i class="fa-brands fa-square-x-twitter  fa-2x m-1 text-dark"></i>

                          </TwitterShareButton>
                        </a>

                        {/* <i className="fa fa-clone fa-3x m-1" style={{ color: "#C3C4C4" }}></i> */}
                        <a href="https://www.linkedin.com/">
                          <LinkedinShareButton
                            url={"https://lovewithoutreason.org/LWRstory"}
                            title={"LWR"}
                            summary={"https://lovewithoutreason.org/"}
                            source={"#lovewithoutreason"}
                          >

                            <i className="fa-brands fa-linkedin fa-2x m-1 text-info"></i>
                          </LinkedinShareButton>
                        </a>
                        <CopyToClipboard
                          text={
                            "https://lovewithoutreason.org/LWRstory"
                          }
                          onCopy={handleCopy}
                        >
                          <i
                            className="fa fa-clone fa-2x m-1"
                            style={{ color: "#C3C4C4" }}
                          ></i>
                        </CopyToClipboard>
                        <p style={{ fontSize: "8px", textAlign: "end" }}>
                          {urlToCopy !== "" ? urlToCopy : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 mb-3">
              <Link to="/DonateIndia" target="_top">
                <button class="btn btn-danger w-75">
                  Donate <i class="fas fa-duotone fa-hand-holding-heart"></i>

                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default LWRstory;
