import React from "react";
import line from "../../assests/Group 1870 line2.png";
import image from "../../assests/zimbabweimg.png";
import image_1 from "../../assests/Zim_2.png";
import image_2 from "../../assests/Zim_3.png";
import image_4 from "../../assests/Zim_4.png";
import zim1 from "../../assests/zimbabwe image 1(reworked).png";
import zim2 from "../../assests/Nikitha.jpg";
import zim3 from "../../assests/More.jpg";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

function Zimbabwe() {
  const [urlToCopy, setURLToCopy] = useState("");

  const handleCopy = () => {
    setURLToCopy("Copied");
  };
  console.log("urlToCopy", urlToCopy);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <section
        className="ZIMBABWE mt-5"
        style={{ minHeight: "100vh" }}>
      </section>

      <section style={{ minHeight: "100vh" }}>
        <div className="container  ">

          <div className="row d-flex justify-content-center p-3 align-item-center mt-5">
            <div className="col-md-4">
              <img className="img-fluid rounded " src={image} />
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder">LOVE WITHOUT REASON <span className="text-danger">ZIMBABWE</span></h3>
              <p className="india-text mt-3">
                “There is a five year backlog of children waiting on surgeries
                in Zimbabwe.” This came from the primary craniofacial surgeon in
                Zimbabwe in 2017. When a child’s cleft repairs are performed,
                their ability to eat, fight off infection, and speak can all
                improve. More Zimbabwean medical professionals are receiving
                training in this specialty field, and with every medical camp
                the amount of children waiting for surgery will decrease. Until
                the World is Cleft free means exactly what it says.
              </p>
            </div>
          </div>
        </div>

        <section style={{ minHeight: "100vh" }} className="zimbabwe-global">
          <div className="container mt-5">
            <div className="row  d-flex align-items-center mt-5 pt-5">
              <div className="col-md-12 text-white text-end mt-5 pt-5">
                <h1 className="fw-bolder mt-5 pt-5">209</h1>
                <h5 className="fw-bolder">NUMBER OF SURGERIES</h5>
              </div>
            </div>
          </div>
        </section>


        <section className="mb-5 mt-5" style={{ minHeight: "100vh" }}>
          <div className="container  ">
            <div className="row d-flex justify-content-center p-3">
              <div
                class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
                style={{ backgroundColor: "#1A1E27" }}
              >
                <img src={image_1} class="img-fluid rounded" alt="Image" />
                <div class="card-body text-white p-2">
                  <h4 class="card-title fw-bolder">WHY MENTAL HEALTH?</h4>
                  <p
                    class="card-text "
                    style={{ fontSize: "14px" }}
                  >
                    During the medical screening day, every patient visits the counseling station. Counseling is central to Love Without Reason's pre-surgical process. Each of us is valuable, and our overall health includes more than just our physical health and appearance...
                  </p>
                  <button
                    className="btn btn-light rounded px-4 mb-3 fw-bolder"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Read Full Story
                  </button>
                  {/* Model-1 */}
                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content modelscroll">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div class="modal-body">
                          <div className="row d-flex justify-content-center ">
                            <div className="col-md-6">
                              <img className="img-fluid rounded" src={zim1} />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 text-center">
                              <h4 class="text-center text-dark fw-bolder mb-0 mt-2">
                                WHY MENTAL HEALTH
                              </h4>

                              <img className=" img-fluid" src={line} />
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-10">
                              <p className="india-text text-dark">
                                During the medical screening day, every patient
                                visits the counseling station. Counseling is
                                central to Love Without Reason's pre-surgical
                                process. Each of us is valuable, and our overall
                                health includes more than just our physical health
                                and appearance.
                                <br />
                                <br />
                                Harare Medical Camp reminded us all that "because
                                every child matters" is a message, not just a
                                slogan. A young man, Ernest, had spent 14-years
                                with a cleft lip. At first sight, he seemed like a
                                typical teenager. However, as he tried to
                                communicate, he exhibited a universal behavior
                                seen across the globe. Years of humiliation and
                                ridicule had taken a toll on Ernest. He struggled
                                to make eye contact and would tentatively glance
                                at faces before hurriedly looking away.
                                <br />
                                <br />
                                For Ernest, it took a lot of effort to open up at
                                the counseling station as he told his story. In
                                his village, he didn't go by Ernest. The only
                                identity he had was the name the people had given
                                him - "split lip."
                                <br />
                                <br />
                                Ernest visited with former patients who let him
                                know he is loved and valued. For the first time,
                                he could speak with peers to ask questions and
                                express his thoughts, fears, and feelings. At Love
                                Without Reason holistic care is key in igniting
                                Ernest's mental and emotional health recovery. The
                                devoted team will spend each opportunity with
                                Ernest to identify dreams and challenge him to see
                                that his precious life has a great purpose and
                                future.
                                <br />
                                <br />
                                In 2021, Ernest will be able to return to complete
                                surgery. Ernest will continue his journey through
                                his recovery process—reassured of his identity as
                                a human worthy of love, respect, and dignity. Our
                                experience has shown each patient's mindset shifts
                                as they understand their unique identity and
                                purpose that they can only achieve.
                              </p>
                            </div>
                          </div>

                          <div className="row d-flex justify-content-between mt-2">
                            <div
                              class="modal fade"
                              id="staticBackdropzim1"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered modal-sm">
                                <div class="modal-content">
                                  <div class="modal-header p-3">
                                    <h5
                                      class="modal-title fw-bolder"
                                      id="staticBackdropLabel"
                                    >
                                      WHY MENTAL HEALTH?
                                    </h5>
                                    <button
                                      type="button"
                                      class="btn-close border"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div class="modal-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-md-12">
                                        <a href="https://www.facebook.com/login/">
                                          <FacebookShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                          </FacebookShareButton>
                                        </a>
                                        <a href="https://www.whatsapp.com/">
                                          <WhatsappShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                          </WhatsappShareButton>
                                        </a>
                                        <a href="https://twitter.com/">
                                          <TwitterShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-info"></i>

                                          </TwitterShareButton>
                                        </a>
                                        <a href="https://www.linkedin.com/">
                                          <LinkedinShareButton
                                            url={"https://lovewithoutreason.org/Zimbabwe"}
                                            title={"LWR"}
                                            summary={"https://lovewithoutreason.org/"}
                                            source={"#lovewithoutreason"}
                                          >

                                            <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                          </LinkedinShareButton>
                                        </a>
                                        <CopyToClipboard
                                          text={
                                            "https://lovewithoutreason.org/Zimbabwe"
                                          }
                                          onCopy={handleCopy}
                                        >
                                          <i
                                            className="fa fa-clone fa-3x m-1"
                                            style={{ color: "#C3C4C4" }}
                                          ></i>
                                        </CopyToClipboard>
                                        <p
                                          style={{
                                            fontSize: "8px",
                                            textAlign: "end",
                                          }}
                                        >
                                          {urlToCopy !== "" ? urlToCopy : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 ms-auto ">
                              <button
                                type="button"
                                class="btn btn-danger w-100 mb-2"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdropzim1"
                              >
                                Share <i class="fa-solid fa-share"></i>
                              </button>
                            </div>
                            <div className="col-md-2 ">
                              <button
                                type="button"
                                class="btn btn-danger w-100"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Model-1 */}
                </div>
              </div>


              <div
                class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
                style={{ backgroundColor: "#134E5E" }}
              >
                <img src={image_2} class="img-fluid rounded" alt="Image" />
                <div class="card-body text-white p-2">
                  <h4 class="card-title fw-bolder">
                    HERE'S WHY YOU NEED TO CARE
                  </h4>
                  <p
                    class="card-text "
                    style={{ fontSize: "14px" }}
                  >
                    As the team of volunteers walked through the Harare Hospital's pediatric ward, one of the mothers stopped them. Tears were flowing down her face as her little body shook with emotion. Love Without Reason specialists, staff, and volunteers know the importance of helping...
                  </p>
                  <button
                    type="button"
                    class="btn btn-light rounded px-4 mb-3 fw-bolder"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop1"
                  >
                    Read Full Story
                  </button>
                  {/* Model-2 */}
                  <div
                    class="modal fade"
                    id="staticBackdrop1"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content modelscroll">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div class="modal-body">
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-6">
                              <img className="img-fluid rounded" src={zim2} />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 text-center">
                              <h4 class="text-center text-dark fw-bolder  mt-2 text-uppercase">
                                Here's Why You Need to Care
                              </h4>

                              <img className=" img-fluid" src={line} />
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-10">
                              <p className="india-text text-dark">
                                As the team of volunteers walked through the
                                Harare Hospital's pediatric ward, one of the
                                mothers stopped them. Tears were flowing down her
                                face as her little body shook with emotion.
                                <br />
                                <br />
                                Love Without Reason specialists, staff, and
                                volunteers know the importance of helping each
                                person bring a voice to their stories, consider
                                their feelings, and provide a safe space.
                                <br />
                                <br />
                                Heartened by the volunteer's encouragement, she
                                began, "Grace was born with a cleft lip seven
                                months before, and she was perfect to me," her
                                mother said. "My husband despised her, so he told
                                me to stay in our home and not interact with the
                                villagers. Everyone soon realized that something
                                was wrong with my baby. And they all talked about
                                her. They called me names. They said God cursed
                                me. The weeks went by, and my baby continued to
                                grow. But the villagers were not happy with me.
                                They complained to the chief all the time about my
                                daughter and me. "<br />
                                <br />
                                Mothers experience unimaginable hurdles in
                                impoverished countries. A baby with cleft adds the
                                challenge of having no way to pay for travel and
                                medical costs, where to access healthcare, how to
                                feed their baby. Many don't know that these babies
                                are born into communities with superstitious
                                beliefs and misconceptions about a cleft
                                lip-palate.
                                <br />
                                <br />
                                For Grace, weeks without rain and the community's
                                dying crops meant a low harvest; everyone was in
                                danger of starvation. "The village chief declared
                                that our village was cursed. And it was cursed
                                because of my baby, Grace. He made plans to murder
                                my baby." Learning of his plans, Grace's mother
                                courageously resolved to leave the village.
                                <br />
                                <br />
                                Love Without Reason partners with phone companies
                                in Africa to reach out via SMS text messages. One
                                of her friends showed her our SMS text that
                                announced free cleft surgeries in Harare's capital
                                city. The capital was a one-day bus ride from her
                                village.
                                <br />
                                <br />
                                She did not know what would happen once in the
                                city, but she knew that this was Grace's only
                                chance. That day she found a way to escape the
                                village. She boarded the bus to Harare and was
                                among the first to arrive at the hospital gates
                                early for the Sunday morning medical camp. She
                                decided that her daughter will experience a good
                                life.
                                <br />
                                <br />
                                She wept with gratitude as she expressed her joy,
                                "I was so glad that Grace was healthy and strong
                                enough for surgery. I just wanted to tell you all
                                how much I thank you for saving our lives. We will
                                go back home, and I know my family will receive us
                                and celebrate Grace."
                              </p>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-between mt-2">
                            <div
                              class="modal fade"
                              id="staticBackdropzim2"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered modal-sm">
                                <div class="modal-content">
                                  <div class="modal-header p-3">
                                    <h5
                                      class="modal-title fw-bolder text-dark"
                                      id="staticBackdropLabel"
                                    >
                                      HERE'S WHY YOU NEED TO CARE
                                    </h5>
                                    <button
                                      type="button"
                                      class="btn-close border"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div class="modal-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-md-12">
                                        <a href="https://www.facebook.com/login/">
                                          <FacebookShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                          </FacebookShareButton>
                                        </a>
                                        <a href="https://www.whatsapp.com/">
                                          <WhatsappShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                          </WhatsappShareButton>
                                        </a>
                                        <a href="https://twitter.com/">
                                          <TwitterShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-info"></i>

                                          </TwitterShareButton>
                                        </a>
                                        <a href="https://www.linkedin.com/">
                                          <LinkedinShareButton
                                            url={"https://lovewithoutreason.org/Zimbabwe"}
                                            title={"LWR"}
                                            summary={"https://lovewithoutreason.org/"}
                                            source={"#lovewithoutreason"}
                                          >

                                            <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                          </LinkedinShareButton>
                                        </a>
                                        <CopyToClipboard
                                          text={
                                            "https://lovewithoutreason.org/Zimbabwe"
                                          }
                                          onCopy={handleCopy}
                                        >
                                          <i
                                            className="fa fa-clone fa-3x m-1"
                                            style={{ color: "#C3C4C4" }}
                                          ></i>
                                        </CopyToClipboard>
                                        <p
                                          style={{
                                            fontSize: "8px",
                                            textAlign: "end",
                                          }}
                                        >
                                          {urlToCopy !== "" ? urlToCopy : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 ms-auto  ">
                              <button
                                type="button"
                                class="btn btn-danger w-100 mb-2"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdropzim2"
                              >
                                Share <i class="fa-solid fa-share"></i>
                              </button>
                            </div>
                            <div className="col-md-2">
                              <button
                                type="button"
                                class="btn btn-danger w-100"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Model-2 */}
                </div>
              </div>




              <div
                class="col-md-4 text-center shadow-sm border border-0 rounded mt-3"
                style={{ backgroundColor: "#1A1E27" }}
              >
                <img src={image_4} class="img-fluid rounded" alt="Image" />
                <div class="card-body text-white p-2">
                  <h4 class="card-title fw-bolder">NOT GOOD ENOUGH? NOT TRUE.</h4>
                  <p
                    class="card-text "
                    style={{ fontSize: "14px" }}
                  >
                    In a tiny home on the outskirts of Harare, the village children were running and playing in their bare feet. Polite was one of the tallest in the group of boys, and with his cleft lip, he definitely stood out. Polite's mother lived in the tiny home with her sister. He introduced us to his mother...
                  </p>
                  <button
                    type="button"
                    class="btn btn-light rounded px-4 mb-3 fw-bolder"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop3"
                  >
                    Read Full Story
                  </button>
                  {/* Model-3 */}
                  <div
                    class="modal fade"
                    id="staticBackdrop3"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content modelscroll">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div class="modal-body">
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-6">
                              <img className="img-fluid rounded" src={zim3} />
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-12 text-center">
                              <h4 class="text-center text-dark font-weight-bold mt-2 mb-0">
                                NOT GOOD ENOUGH? NOT TRUE.
                              </h4>

                              <img className=" img-fluid" src={line} />
                            </div>
                          </div>
                          <div className="row d-flex justify-content-center mt-2">
                            <div className="col-md-10">
                              <p className="india-text text-dark">
                                In a tiny home on the outskirts of Harare, the
                                village children were running and playing in their
                                bare feet. Polite was one of the tallest in the
                                group of boys, and with his cleft lip, he
                                definitely stood out. Polite's mother lived in the
                                tiny home with her sister. He introduced us to his
                                mother, her sister, his siblings, and his extended
                                family.
                                <br />
                                <br />
                                As we talked, Polite's mother mentioned that life
                                had been hard for Polite. Polite was the youngest
                                of her four children. "He was our first son, after
                                having three girls. His father was so proud to
                                have a son – until he saw Polite's face." He was
                                so angry that he left, and to this day, the family
                                doesn't know where he is.
                                <br />
                                <br />
                                Love Without Reason provides educational workshops
                                about what causes cleft lip and palate—developing
                                awareness of how surgeries can correct congenital
                                craniofacial differences. We hope to change the
                                deceptive stigma that caused Polite to live with
                                rejection and ridicule for many years.
                                <br />
                                <br />
                                Polite smiles today because of our incredible
                                donors. Polite's cleft lip repaired, his heart and
                                mind began to imagine achieving his dream of
                                becoming a pilot. He expects great things out of
                                his life.
                                <br />
                                <br />
                                "Please don't stop doing surgery with just Polite.
                                Find all the children and help them just like you
                                helped us. Thank you for helping Polite and
                                changing his smile." His mother insisted as we
                                said our goodbyes. Each donor's contribution, no
                                matter the amount, helps us do just that.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-between mt-2">
                            <div
                              class="modal fade"
                              id="staticBackdropzim3"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdropLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered modal-sm">
                                <div class="modal-content">
                                  <div class="modal-header p-3">
                                    <h5
                                      class="modal-title fw-bolder"
                                      id="staticBackdropLabel"
                                    >
                                      NOT GOOD ENOUGH? NOT TRUE.
                                    </h5>
                                    <button
                                      type="button"
                                      class="btn-close border"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div class="modal-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-md-12">
                                        <a href="https://www.facebook.com/login/">
                                          <FacebookShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i>
                                          </FacebookShareButton>
                                        </a>
                                        <a href="https://www.whatsapp.com/">
                                          <WhatsappShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >
                                            <i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i>
                                          </WhatsappShareButton>
                                        </a>
                                        <a href="https://twitter.com/">
                                          <TwitterShareButton
                                            url={
                                              "https://lovewithoutreason.org/Zimbabwe"
                                            }
                                            quote={"LWR"}
                                            hashtag="#lovewithoutreason"
                                          >

                                            <i class="fa-brands fa-square-x-twitter  fa-3x m-1 text-info"></i>

                                          </TwitterShareButton>
                                        </a>
                                        <a href="https://www.linkedin.com/">
                                          <LinkedinShareButton
                                            url={"https://lovewithoutreason.org/Zimbabwe"}
                                            title={"LWR"}
                                            summary={"https://lovewithoutreason.org/"}
                                            source={"#lovewithoutreason"}
                                          >

                                            <i className="fa-brands fa-linkedin fa-3x m-1 text-info"></i>
                                          </LinkedinShareButton>
                                        </a>
                                        <CopyToClipboard
                                          text={
                                            "https://lovewithoutreason.org/Zimbabwe"
                                          }
                                          onCopy={handleCopy}
                                        >
                                          <i
                                            className="fa fa-clone fa-3x m-1"
                                            style={{ color: "#C3C4C4" }}
                                          ></i>
                                        </CopyToClipboard>
                                        <p
                                          style={{
                                            fontSize: "8px",
                                            textAlign: "end",
                                          }}
                                        >
                                          {urlToCopy !== "" ? urlToCopy : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 ms-auto ">
                              <button
                                type="button"
                                class="btn btn-danger w-100 mb-2"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdropzim3"
                              >
                                Share <i class="fa-solid fa-share"></i>
                              </button>
                            </div>
                            <div className="col-md-2 ">
                              <button
                                type="button"
                                class="btn btn-danger w-100"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Model-3 */}
                </div>
              </div>
            </div>
          </div>
        </section>


      </section>
      <Header />
      <Footer />
    </>
  );
}

export { Zimbabwe };
