import React from 'react';
import logo2 from "../../assests/LWRblue.png";
import { Link } from 'react-router-dom';


function Header() {
  
  const handleClick = async () => {
    try {
      const response = await fetch('https://ipinfo.io?token=f667c2c73c3553');
      const data = await response.json();

      if (data.country === 'IN') {
        window.location.href = '/DonateIndia'; // Redirect to Donations component for users from India
      } else {
        window.location.href = '/Donate'; // Redirect to Donations component for users from India

      }
    } catch (error) {
      console.error('Error fetching IP information:', error);
      // Handle error, you may want to redirect to a default link or display an error message
    }
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light ms-center">
          <div className="container px-4">
            <a className="navbar-brand" href="#">
              <Link to="/Home"> <img src={logo2} alt="Navbar Logo" height="40" /></Link>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse ps-5" id="navbarSupportedContent">
              <ul className="navbar-nav mb-2 mb-lg-0 px-1">
                <li className="nav-item dropdown m-1">
                  <a className="nav-link dropdown-toggle text-dark ms-4 fw-bolder" href="#" id="navbarDropdownMenuLink" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    ABOUT
                  </a>
                  <ul id='dropdown1' className="dropdown-menu  p-3 mt-0" aria-labelledby="navbarDropdownMenuLink">
                    <div className='d-md-flex'>
                      <li>
                        <Link to="/About Us" className="dropdown-item m-1" >About Us</Link>
                        <Link to="/Ourvalues" className="dropdown-item m-1" >Our Values</Link>
                        {/* <Link to="/Ourmission" className="dropdown-item m-1" >Our Mission</Link> */}
                        <Link to="/Ourstrategy" className="dropdown-item m-1" >Our Strategy</Link>
                        <Link to="/contactus" className="dropdown-item m-1">Contact Us </Link>
                      </li>
                      <li>
                        <Link to="/Founders" className="dropdown-item m-1" >Founders</Link>
                        <Link to="/ExecutiveBoardOfDirectors" className="dropdown-item m-1" >Board of Directors</Link>
                        <Link to="/USALeadershipCommittee" className="dropdown-item m-1" >USA Leadership Committee</Link>
                        <Link to="/GlobalTeams" className="dropdown-item m-1" >Global Teams</Link>
                      </li>
                    </div>
                  </ul>
                </li>
              </ul>
              <ul className="navbar-nav mb-2 mb-lg-0 px-1">
                <li className="nav-item dropdown m-1">
                  <a className="nav-link dropdown-toggle  text-dark ms-4 fw-bolder" id="navbarDropdownMenuLink" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    INITIATIVES
                  </a>
                  {/* <ul className="dropdown-menu p-3" aria-labelledby="navbarDropdownMenuLink">

<Link to='/CraniofacialSurgeries' className="dropdown-item m-1"></Link>
                    <Link to='/AntiHumanTrafficking' className="dropdown-item m-1"></Link>
                    <Link to='/InnovativeSolutions' className="dropdown-item m-1" ></Link>
                  </ul> */}
                  <ul id='dropdown4' className="dropdown-menu  p-3 mt-0 " aria-labelledby="navbarDropdownMenuLink">
                    <div className='d-md-flex '>
                      <li className=''>
                        <Link to="/CraniofacialSurgeries" className="dropdown-item m-1 fw-bolder" >&nbsp;Craniofacial Surgery</Link>
                        <Link to="/MedicalCamps" className="dropdown-item m-1" >&nbsp;Medical Camps</Link>
                        <Link to="/Upcomingcamps" className="dropdown-item m-1" >&nbsp;Upcoming Camps</Link>
                      </li>

                      <li>
                        <Link to="/AntiHumanTrafficking" className="dropdown-item m-1 fw-bolder">Anti Human Trafficking</Link>
                        <Link to="/AntiHumanTrafficking" className="dropdown-item m-1" >&nbsp;Project Butterfly Center</Link>

                      </li>

                      <li>
                        <Link to="/InnovativeSolutions" className="dropdown-item m-1 fw-bolder" href="#">Innovative Solutions</Link>
                        <Link to="/InnovativeSolutions" className="dropdown-item m-1" >&nbsp;Set Free Pads</Link>
                        <Link to="/InnovativeSolutions" className="dropdown-item m-1" >&nbsp;Water Wells</Link>
                        <Link to="/InnovativeSolutions" className="dropdown-item m-1" >&nbsp;Leadership Training</Link>
                      </li>
                    </div>
                  </ul>
                </li>
              </ul>

              <ul className="navbar-nav mb-2 mb-lg-0 px-1">
                <li className="nav-item dropdown m-1">
                  <a className="nav-link dropdown-toggle text-dark ms-4 fw-bolder" href="#" id="navbarDropdownMenuLink" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    COUNTRIES
                  </a>
                  <ul className="dropdown-menu p-3" aria-labelledby="navbarDropdownMenuLink">
                    <Link to="/USA" className="dropdown-item m-1" >USA</Link>
                    <Link to="/India" className="dropdown-item m-1" >India</Link>
                    {/* <li><a className="dropdown-item ms-5" href="#">kolkata</a></li>
                    <li><a className="dropdown-item ms-5" href="#">Assam</a></li>
                    <li><a className="dropdown-item ms-5" href="#">Bihar</a></li> */}
                    <Link to="/Kenya" className="dropdown-item m-1" >Kenya</Link>
                    {/* <li><a className="dropdown-item ms-5" href="#">Nairobi</a></li>
                    <li><a className="dropdown-item ms-5" href="#">Kisumu</a></li> */}
                    <Link to="/Uganda" className="dropdown-item m-1" >Uganda</Link>
                    <Link to="/Zimbabwe" className="dropdown-item m-1" >Zimbabwe</Link>
                    <Link to="/SouthAfrica" className="dropdown-item m-1" >South Africa</Link>

                  </ul>
                </li>
              </ul>

              <ul className="navbar-nav mb-2 mb-lg-0 px-1">
                <li className="nav-item dropdown m-1">
                  <a className="nav-link dropdown-toggle text-dark ms-4 fw-bolder" href="#" id="navbarDropdownMenuLink" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    MEDIA CENTER
                  </a>
                  <ul className="dropdown-menu p-3" aria-labelledby="navbarDropdownMenuLink">
                    <Link to="/Videos" className="dropdown-item m-1" href="#">LWR Videos</Link>
                    <Link to="/PhilipVideos" className="dropdown-item m-1" href="#">Philip's Videos</Link>

                    <Link to="/PressRelease" className="dropdown-item m-1" href="#">Press Release</Link>
                    <Link to="/NewsLetters" className="dropdown-item m-1">News Letters</Link>

                    <Link to="/contactus" className="dropdown-item m-1">Contact Us </Link>

                  </ul>
                </li>
              </ul>

              <ul className="navbar-nav mb-2 mb-lg-0 px-1">
                <li className="nav-item dropdown m-1">
                  <a className="nav-link dropdown-toggle text-dark ms-4 fw-bolder" href="#" id="navbarDropdownMenuLink" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    JOIN US
                  </a>
                  <ul id='dropdown4' className="dropdown-menu  p-3 mt-0" aria-labelledby="navbarDropdownMenuLink">
                    <div className='d-md-flex'>
                      <li>
                        <Link to="/Events" className="dropdown-item m-1 fw-bolder" >&nbsp;Events</Link>
                        {/* <Link to="/SmilewithSanta" className="dropdown-item m-1" href="#">&nbsp;Smile With Santa</Link> */}
                        <Link to="/5kRun4Love" className="dropdown-item m-1" href="#">&nbsp;5kRun4Love</Link>

                        <Link to="/PeopleNotProducts" className="dropdown-item m-1" href="#">&nbsp;People Not Products</Link>
                        <Link to="/FatherDaughterDance" className="dropdown-item m-1" href="#">&nbsp;Father Daughter Dance</Link>

                      </li>

                      <li>
                        <Link className="dropdown-item m-1 fw-bolder">Volunteer</Link>
                        <Link to="/EventVolunteers" className="dropdown-item m-1">&nbsp;Event Volunteer</Link>
                        <Link to="/StudentIntern" className="dropdown-item m-1" >&nbsp;Student Intern</Link>


                      </li>
                      <li>
                        <Link to="#" className="dropdown-item m-1 fw-bolder" href="#">Other</Link>
                        <Link to="/Inkind" className="dropdown-item m-1 " href="#">InKind Donations</Link>
                        <Link to="/CorporateSponsors" className="dropdown-item m-1" href="#">Corporate Sponsors</Link>
                        <Link to="/VehicleDonation" className="dropdown-item m-1" href="#">Vehicle Donation</Link>
                        <Link to="/PersonalProperty" className="dropdown-item m-1" href="#">Personal Property</Link>
                        <Link to="/LegacyPlannedGifts" className="dropdown-item m-1" href="#">Legacy/Planned Gifts</Link>
                      </li>
                    </div>
                  </ul>
                </li>
              </ul>
              <ul className="navbar-nav mb-2 mb-lg-0 px-1">
                <li className="nav-item m-1">
                  <Link to="/philip" className="btn btn-outline-light text-dark dropdown-item  m-1 fw-bolder">
                    PHILIP'S STORY</Link>
                </li>
                <li className="nav-item m-1">
                  <button className="btn btn-danger ms-5" onClick={handleClick}>Donate&nbsp;<i className="fas fa-duotone fa-hand-holding-heart"></i></button>
                </li>
              </ul>

            </div>
          </div>
        </nav >
      </header >

    </>
  )
}

export { Header }













