import React from "react";
import line from "../../assests/Group 1870 line2.png";
import image1 from "../../assests/susanblogsimg.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { useEffect } from "react";
function Susan() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <section className="blogs1bg" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center mt-5 ">
            <div className="col-md-7">
              <h4 class="text-center text-danger font-weight-bold mb-3 mt-5">
                What is Man’s Chief End?
              </h4>
              <img className="img-fluid mb-3" src={image1} />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <p className="text-justify">
                To the students of the 2019-2020 school year, you may have heard
                the Westminster Catechism before, which is also the motto of The
                McCallie School – “Man’s chief end is to glorify God and enjoy
                Him forever.”
              </p>
              <p className="text-justify">
                My firstborn son, Philip, graduated from the school on May 19,
                2019. He spent seven years on this campus and was greatly
                influenced by faculty and peers alike.
              </p>
              <p className="text-justify">
                He told us that as he walked across the stage,when his name was
                called to received his high school diploma, a question came to
                his mind.
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="susanblogbg" style={{ minHeight: "80vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-11 text-start">
              <p className="text-justify">
                In September, Philip worked to prepare for the annual 5KRun4Love
                by spreading the word, speaking on local news channels to
                promote the event and “Craniofacial Acceptance Month.” He
                cheered on his brother and all the runners. He celebrated the
                Open House event for LWR’s first office. He became a working
                man, making phone calls, writing emails, and visiting potential
                clients. He met the craniofacial team at Vanderbilt, and boldly
                shared his story with them. His planned facial reconstruction
                surgery was set for October 24, 2019. But instead, Philip had to
                have emergency surgery on his belly on October 9, 2019. He went
                home to be with his Lord on October 22, 2019.
              </p>

              <p className="text-justify">
                What is man’s chief end? Philip read it all over his school
                campus for seven years. He spent 19 years living it out,
                glorifying his God in the smallest of opportunities. And now, he
                is enjoying him for eternity.
              </p>

              <p className="text-justify">
                I challenge you, class of 2020. Know your chief end. Know your
                purpose. While the acceptance letter to your college of choice
                is wonderful, and the making of money and saving of the 401k is
                great, and the pleasures of life are essential, there is one
                chief end. Let us get to that place to enjoy Him forever.
              </p>
              <h4 className=" text-danger font-weight-bold">
                Congratulations to the class of 2020!
              </h4>
              <p className="">With Love,</p>

              <h4 className=" text-dark font-weight-bold">
                Susan filed under: graduation, hope, Philip
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5" style={{ minHeight: "100vh", backgroundColor: "#white" }}>
        <div className="container text-white">
          <div className="row">
            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                <div className="card-header p-1 text-end">
                  <button className="btn btn-outline-light text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="fa fa-share-alt"></i></button>
                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel">SUPERBOWL 2010</h5>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/"><i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i></a>
                            <a href="https://www.whatsapp.com/"><i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i></a>
                            <a href="https://twitter.com/"><i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i></a>
                            <a href="https://www.instagram.com/"><i className="fa-brands fa-square-instagram fa-3x m-1" style={{ color: '#E4405F' }}></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="..." class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    SUPERBOWL 2010
                  </h5>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Superbowl 2010 What everyone really will be watching on
                    Superbowl Sunday, is the Tim Tebow commercial sponsored by
                    Focus on the Family! The ad has drawn a lot of concern and
                    anger from the pro-choice and gay and lesbian groups, since
                    CBS has decided to air the...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Wednesday
                    <span className="p-3 text-danger"> 01 April 2020</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                <div className="card-header p-1 text-end">
                  <button className="btn btn-outline-light text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"><i class="fa fa-share-alt"></i></button>
                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel">PHILIP'S FIRST (KNOWN) CONCERT</h5>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/"><i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i></a>
                            <a href="https://www.whatsapp.com/"><i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i></a>
                            <a href="https://twitter.com/"><i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i></a>
                            <a href="https://www.instagram.com/"><i className="fa-brands fa-square-instagram fa-3x m-1" style={{ color: '#E4405F' }}></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="..." class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    PHILIP'S FIRST (KNOWN) CONCERT
                  </h5>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Philip’s First (known) Convert Two Wednesday’s back as we
                    were driving back from church, I overheard a conversation
                    between Philip and his six year old sister, Sara. He
                    questioned her about her own salvation. The conversation
                    went something like this: P:...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Wednesday
                    <span className="p-3 text-danger"> 01 April 2020</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                <div className="card-header p-1 text-end">
                  <button className="btn btn-outline-light text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop2"><i class="fa fa-share-alt"></i></button>
                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel"> GOD STILL MOVES!</h5>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/"><i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i></a>
                            <a href="https://www.whatsapp.com/"><i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i></a>
                            <a href="https://twitter.com/"><i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i></a>
                            <a href="https://www.instagram.com/"><i className="fa-brands fa-square-instagram fa-3x m-1" style={{ color: '#E4405F' }}></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="..." class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    GOD STILL MOVES!
                  </h5>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    God still moves! God still moves, God still movesIn the
                    heart of His people, God still moves!He does not sleep, nor
                    does he slumberGod still moves, God still moves. Philip has
                    recovered well over the past week from the eye and ear
                    surgery. He will be meeting up with...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Wednesday
                    <span className="p-3 text-danger"> 30 March 2020</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                <div className="card-header p-1 text-end">
                  <button className="btn btn-outline-light text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop4"><i class="fa fa-share-alt"></i></button>
                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel">JEREMIAH 32:27</h5>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/"><i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i></a>
                            <a href="https://www.whatsapp.com/"><i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i></a>
                            <a href="https://twitter.com/"><i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i></a>
                            <a href="https://www.instagram.com/"><i className="fa-brands fa-square-instagram fa-3x m-1" style={{ color: '#E4405F' }}></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="..." class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    JEREMIAH 32:27
                  </h5>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Jeremiah 32:27 Philip’s promise verse for the new year was
                    Jeremiah 32:27 Behold, I am the LORD, the God of all flesh:
                    is there any thing too hard for me? definitely that time of year where people smile, We asked him, well, what
                    is the answer to this question? His response “Absolutely
                    nothing!” ...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Thursday
                    <span className="p-3 text-danger"> 21 March 2019</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                <div className="card-header p-1 text-end">
                  <button className="btn btn-outline-light text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop5"><i class="fa fa-share-alt"></i></button>
                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="staticBackdrop5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel">REVELATION OUT OF THE MOUTHS OF BABES</h5>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/"><i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i></a>
                            <a href="https://www.whatsapp.com/"><i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i></a>
                            <a href="https://twitter.com/"><i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i></a>
                            <a href="https://www.instagram.com/"><i className="fa-brands fa-square-instagram fa-3x m-1" style={{ color: '#E4405F' }}></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="..." class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    REVELATION OUT OF THE MOUTHS OF BABES
                  </h5>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Revelation..out of the mouths of babes Well, you’ve read
                    some of the silly and humorous comments from Philip. To be
                    just, I should let you know of the serious, thoughtful ones
                    as well. 🙂 My son is so sweet. Last Sunday in church after
                    our Pastor had spoken...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Thursday
                    <span className="p-3 text-danger"> 10 September 2019</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-5 text-dark">
              <div class="card shadow-sm">
                <div className="card-header p-1 text-end">
                  <button className="btn btn-outline-light text-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop6"><i class="fa fa-share-alt"></i></button>
                </div>
                {/* <!-- Modal --> */}
                <div class="modal fade" id="staticBackdrop6" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-sm">
                    <div class="modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="staticBackdropLabel">PHILIP'S & HIS ANCESTORS</h5>
                        <button type="button" class="btn-close border" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body p-5">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-12">
                            <a href="https://www.facebook.com/login/"><i class="fa-brands fa-square-facebook fa-3x m-1 text-primary"></i></a>
                            <a href="https://www.whatsapp.com/"><i class="fa-brands fa-square-whatsapp fa-3x m-1 text-success"></i></a>
                            <a href="https://twitter.com/"><i class="fa-brands fa-square-twitter fa-3x m-1 text-info"></i></a>
                            <a href="https://www.instagram.com/"><i className="fa-brands fa-square-instagram fa-3x m-1" style={{ color: '#E4405F' }}></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img src="..." class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    PHILIP'S & HIS ANCESTORS
                  </h5>
                  <p class="card-text " style={{ textAlign: "justify" }}>
                    Philip and his ancestors I thought you all might laugh at
                    this event that happened a few weeks back. Philip and I work
                    very diligently on homework once I return home from work.
                    Every week he receives 5-7 new vocabulary words that he must
                    know and understand the...
                  </p>
                  <hr />
                  <p className="text-center  ">
                    <i
                      class="fa-solid fa-calendar-days "
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Saturday
                    <span className="p-3 text-danger">15 June 2019</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Header />
      <Footer />
    </>
  );
}

export { Susan };
